import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords, emailValidator } from 'src/app/theme/utils/app-validators';
import { LoginComponent } from '../login/login.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { CreateRequestModel } from 'src/app/shared/models/login-model';
import { ApiResponse } from 'src/app/shared/models/response-model';
import {VerifyIdmeComponent} from "./verify-idme/verify-idme.component";
import { environment } from '../../../environments/environment';
import {HeaderService} from "../../services/header.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  environment: any = environment;
  public registerForm: UntypedFormGroup;
  CreateRequestModel: CreateRequestModel;
  public hide = true;
  public auth_token: string;
  payGrade: any;
  paygradeList: any;
  agencyAndDepartmentList: any;
  branchList: {
    label: string;
    id: number;
  }[] = [
    {
      id: 500,
      label: "Air Force"
    },
    {
      id: 501,
      label: "Army"
    },
    {
      id: 502,
      label: "Coast Guard"
    },
    {
      id: 503,
      label: "Marine Corps"
    },
    {
      id: 504,
      label: "Navy"
    },
    {
      id: 505,
      label: "Space Force"
    },
  ];

  constructor(
      public fb: UntypedFormBuilder,
      public router:Router,
      public snackBar: MatSnackBar,
      public dialog: MatDialog,
      private storage: StorageService,
      private route: ActivatedRoute,
      public api: ApiService,
      private headerService: HeaderService
  ) {
    this.headerService.setCurrentPage('registration');
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      password: [''],
      confirmPassword: [''],
      department: [0],
      branch: [''],
      postal_code: [''],
      paygrade: [0],
      idmeUuid: "",
      group: "",
      isVerified: false
    },{validator: matchingPasswords('password', 'confirmPassword')});

    this.route.queryParams.subscribe((params: any) => {
      let callbackUrl = this.router.url.includes('code');
      if (params.code && callbackUrl) return this.setupIdmeVerification(params.code);
    });
    this.getPayGradeList();
    this.getDepartmentAndAgencyList();
  }

  public onRegisterFormSubmit(values:Object):void {
    console.log("Registration Form Valid? ", this.registerForm.valid, this.registerForm);
    if (this.registerForm.valid) {
      this.CreateRequestModel = <CreateRequestModel>this.registerForm.value;
      console.log("Call Passes: ", this.CreateRequestModel);
      this.api
          .postCall('/auth/register', this.CreateRequestModel)
          .subscribe((res: any) => {
            console.log("Use Status Code", res);
            if (res.statusCode === 201) {
              this.snackBar.open(
              'Registration Was Successful!',
              '×',
              { panelClass: 'success', verticalPosition: 'top', duration: 3000 }
              );
              this.router.navigate(["/"])
                .then(() => {
                  this.openLogin();
                })
                .catch(e => console.error(e));
        } else {
          this.snackBar
              .open(
                  `Error: ` + res.message.error,
                  '×',
                  { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
              );
        }
      })
    }
  }

  public getPayGradeList(){
    this.api.getCall('/ref/fetchPaygrades').subscribe((res: ApiResponse<any>)=>{
      console.log("Res for getPaygrade: ", res);
      if(res && res.data){
        this.payGrade = res.data;
        this.paygradeList = res.data;
      }
    })
  }

  public getDepartmentAndAgencyList(){
    this.api.getCall('/ref/fetchAgenciesAndDepartments').subscribe((res: ApiResponse<any>)=>{
      console.log("Res for getDepartmentList: ", res);
      if(res && res.data){
        this.agencyAndDepartmentList = res.data.slice(1);
      }
    })
  }

  // Opens the Login Modal Post IDME Passthrough.
  public openLogin() {
    return this.dialog.open(LoginComponent, {
      maxWidth: "400px",
    });
  }

  public onBranchListChange(evt: any) {
    let transitiveFormObject = {
      branch: evt.source.value.id
    };
    this.registerForm.patchValue(transitiveFormObject);
  }

  private setupIdmeVerification(token:string) {
    const authToken = { auth_token: token };
    this.api.postCall('/auth/authenticateIDMEToken', authToken).subscribe((res: any) => {
      console.log('authentication IDME response', res)
      const
          successfulIDMETokenAuthentication = res.authenticateIDMETokenResults.statusCode === 200,
          successfulGetUserAttributes = res.getUserAttributesResults.statusCode === 200,
          continueWithPrefillingRegistrantForm = successfulIDMETokenAuthentication && successfulGetUserAttributes;

      // Continue with Form Prefill?
      if (continueWithPrefillingRegistrantForm){
        this.snackBar.open('IDME Token verified successfully!', '×', {
          panelClass: 'success',
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 3000
        });

        let transitiveFormObject = {
          firstName: "",
          lastName: "",
          email: "",
          idmeUuid: "",
          department: 0,
          group: res.getUserAttributesResults.data.status[0].group,
          isVerified: "",
          postal_code: ""
        };

        res.getUserAttributesResults.data.attributes.map(item => {
          if(item.handle.includes("fname")) {
            transitiveFormObject.firstName = item.value;
          } else if (item.handle.includes("lname")) {
            transitiveFormObject.lastName = item.value;
          } else if (item.handle.includes("uuid")) {
            transitiveFormObject.idmeUuid = item.value;
          } else if (item.handle.includes("email")) {
            transitiveFormObject.email = item.value;
          } else if (item.handle.includes("zip")) {
            transitiveFormObject.postal_code = item.value;
          }
        });

        // Instantiate Proper Form
        if(res.getUserAttributesResults.data.status[0].group === 'military') {
          this.registerForm = this.fb.group({
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            department: [90, Validators.required],
            branch: ['', Validators.required],
            postal_code: [''],
            paygrade: [0, Validators.required],
            idmeUuid: "",
            group: "",
            isVerified: false
          },{validator: matchingPasswords('password', 'confirmPassword')});
          transitiveFormObject.department = 90;
        } else if (res.getUserAttributesResults.data.status[0].group === 'government') {
          this.registerForm = this.fb.group({
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            department: [0, Validators.required],
            postal_code: [''],
            idmeUuid: "",
            group: "",
            isVerified: false
          },{validator: matchingPasswords('password', 'confirmPassword')});
        } else if (res.getUserAttributesResults.data.status[0].group === 'nurse') {
          this.registerForm = this.fb.group({
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            postal_code: [''],
            idmeUuid: "",
            group: "",
            isVerified: false
          },{validator: matchingPasswords('password', 'confirmPassword')});
        } else if (res.getUserAttributesResults.data.status[0].group === 'responder') {
          this.registerForm = this.fb.group({
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            postal_code: [''],
            idmeUuid: "",
            group: "",
            isVerified: false
          },{validator: matchingPasswords('password', 'confirmPassword')});
        }

        console.log("Transitive Form Object: ", transitiveFormObject);

        this.registerForm.patchValue(transitiveFormObject);
      } else {
        this.snackBar.open(`IDME Token verification failed `, '×', { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 });
      }
    });
    this.api.setAccessToken(token);
  }
}
