import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-property-search-icons",
  templateUrl: "./property-search-icons.component.html",
  styleUrls: ["./property-search-icons.component.scss"],
})
export class PropertySearchIconsComponent implements OnInit {
  @Input() size: number = 24;
  @Input() guestsSize: number = this.size + 4;
  @Input() color: string = "black";
  @Input() color2: string | null = null;
  @Input() icon: string = "";

  ngOnInit(): void {}
}
