import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { GPSCoords } from "src/app/shared/models/property-model";

interface CityState {
  city: string;
  state: string;
}

type LocationInput = GPSCoords | CityState;

interface GeoCodingResponse {
  gps: { lat: number; lng: number } | null;
  address: string | null;
}

@Injectable({
  providedIn: "root",
})
export class MapsApiService {
  private googleApiKey: string | null = null;
  private placeSelectedSubject: Subject<string> = new Subject<string>();

  private readonly GOOGLE_MAPS_API_URL: string = "https://maps.googleapis.com/maps/api";
  private readonly MAP_SIZE: number = 400;
  private readonly GOOGLE_ZOOM: number = 8; // 14 = street level, 8 = zoomed out / city level

  // Google Static Maps API
  private readonly GOOGLE_STATIC_MAPS_URL: string = "https://maps.googleapis.com/maps/api/staticmap";
  private readonly MARKER_SIZE: string = "mid"; // "tiny"
  private readonly MARKER_COLOR: string = "red";
  private readonly SCALE: number = 2;

  constructor(private http: HttpClient) {}

  loadGoogleMapsScript(apiKey: string): void {
    if (!apiKey || !apiKey.length) {
      throw new Error("Cannot load Google Maps API without an API key");
    }

    // Use the Dynamic Library Import script provided by Google
    ((g) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,drawing&callback=Function.prototype&loading=async`;
      script.async = true;
      script.defer = true;
      script.onerror = (error) => {
        console.error(`Failed to load Google Maps script: ${error}`);
      };
      document.head.appendChild(script);
    })(window);
  }

  setGoogleApiKey(apiKey: string): void {
    this.googleApiKey = apiKey;
  }

  getGoogleApiKey(): string | null {
    return this.googleApiKey;
  }

  private buildMarkersQueryString(markers: any[], defaultSize: string): string {
    if (!Array.isArray(markers)) return "";

    let markerStr = "";
    markers.forEach((marker) => {
      if (marker.lat && marker.lng) {
        let markerColor = marker.color && marker.color.length ? marker.color : this.MARKER_COLOR;
        markerStr += `&markers=size:${defaultSize}%7Ccolor:${markerColor}%7C${marker.lat},${marker.lng}`;
      }
    });
    return markerStr;
  }

  /**
   * Concats a Google Static Maps URL, using API key, from GPS coords or city/state
   * [Static Maps documentation](https://developers.google.com/maps/documentation/maps-static/overview)
   * @param {GpsCoordinates | CityState} location
   * @param {any} options
   * @returns {string | null}
   */
  googleStaticMapsURL(location: LocationInput, options: any = {}): string | null {
    try {
      if (!this.googleApiKey) {
        throw new Error("Map API key not available");
      }

      let center: string;
      if ("lat" in location && "lng" in location) {
        center = `${location.lat}%2C${location.lng}`;
      } else if ("city" in location && "state" in location) {
        center = `${encodeURIComponent(location.city)},${encodeURIComponent(location.state)}`;
      } else {
        throw new Error("Invalid location input");
      }

      const markerSize = options.size && options.size.length ? options.size : this.MARKER_SIZE;
      const width = +options.width ? +options.width : 250;
      const height = +options.height ? +options.height : 250;
      const zoom = !isNaN(options.zoom) ? +options.zoom : this.GOOGLE_ZOOM;
      let url = `${this.GOOGLE_STATIC_MAPS_URL}?center=${center}&size=${width}x${height}&zoom=${zoom}&scale=${options.scale ?? this.SCALE}`;
      if (zoom) {
        url += `&zoom=${zoom}`;
      }

      let markersStr = this.buildMarkersQueryString(options.markers, markerSize);
      if (markersStr) {
        url += markersStr;
      }

      return `${url}&key=${this.googleApiKey}`;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  /**
   * 🍕 Basic fields incur no additional cost, while contact and contextual data are charged extras.
   * Adding the "fields" parameter in autocompletion requests specifies the desired details, akin to ordering specific toppings on a pizza, thereby reducing costs.
   *
   * - [API Documentation](https://developers.google.com/maps/documentation/javascript/place-autocomplete#typescript)
   * - [Reduce API Costs Video From Google](https://youtu.be/VOP8cvCLGac?si=FDCx0A3WrrfXMf8O)
   * - [Pricing for the Places API](https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#about-autocomplete-sessions)
   */
  public getAddressPredictions(input: HTMLInputElement): Observable<string> {
    if (!google || !google.maps || !google.maps.places) {
      throw new Error("Google Maps API library is not loaded");
    }

    const options = {
      types: ["(cities)"],
      componentRestrictions: { country: "us" },
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    // Subscribe to the 'place_changed' event of the Autocomplete instance
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place && place.formatted_address) {
        this.placeSelectedSubject.next(place.formatted_address);
      }
    });

    // Return an Observable to emit the selected address
    return this.placeSelectedSubject.asObservable();
  }

  /**
   * Generates an interactive [Google JS Map](https://developers.google.com/maps/documentation/javascript/overview)
   * @param {HTMLElement} element
   * @param {GPSCoords} gps
   * @param options
   */
  public async getInteractiveMap(element: HTMLElement, gps: GPSCoords, options: any = {}): Promise<void> {
    if (!google || !google.maps) {
      throw new Error("Google Maps API library is not loaded");
    }

    console.log("google library loaded");

    // Request needed libraries.
    const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

    // The map, centered at Uluru
    const map = new Map(element, {
      zoom: 10,
      center: gps,
      mapId: "fbc87857bd049759", // https://console.cloud.google.com/google/maps-apis/studio/maps/fbc87857bd049759?project=outstanding-map-285816
    });

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
      map,
      position: gps,
      title: "Atease Properties Map",
    });
  }
}
