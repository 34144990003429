import {Component, inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiResponse} from 'src/app/shared/models/response-model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApiService} from 'src/app/services/api.service';
import {StorageService} from 'src/app/services/storage.service';
import {LoginRequestModel} from 'src/app/shared/models/login-model';
import {VerifyIdmeComponent} from '../register/verify-idme/verify-idme.component';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public hide = true;
  loginModel: LoginRequestModel;

  constructor(
      public fb: UntypedFormBuilder,
      public router: Router,
      private storage: StorageService,
      public snackBar: MatSnackBar,
      public api: ApiService,
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<LoginComponent>,
      private authService: AuthService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
    });
  }

  public onLoginFormSubmit(values: Object) {
    if (this.loginForm.valid) {
      this.loginModel = <LoginRequestModel>this.loginForm.value;
      this.api.postCall('/auth/login', this.loginModel).subscribe((res: ApiResponse<any>) => {
        if(res.error) {
          // @ts-ignore
          const {error: errorMessage} = res.message;
          this.snackBar
              .open(
                  errorMessage,
                  '×',
                  { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
              );
        } else {
          // Track Login Info
          this.authService.loginSuccess();
          this.storage.addLogin(res);

          // Set Profile Info
          this.storage.userProfile.next(res);

          // Show Success
          this.snackBar
              .open(
                  'Logged In Successfully!',
                  '×',
                  { panelClass: 'success',horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
              );

          // Redirect & Close Modal
          this.dialogRef.close();
          this.router.navigate(['/account/profile']);
        }
      });
    }
  }

  public openSignIn() {
    // Close the current register dialog
    this.dialog.closeAll();

    // Open the login dialog
    return this.dialog.open(LoginComponent, {
      maxWidth: "400px",
    });
  }

  public openSignUp() {
    // Close the current register dialog
    this.dialog.closeAll();

    // Open the Verify Component Dialog dialog
    return this.dialog.open(VerifyIdmeComponent, {
      maxWidth: "400px",
    });
  }

  public navigateToForgotPassword() {
      return this.router.navigate(['/forgot-password']);
  }
}
