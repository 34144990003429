export interface GPSCoords {
  lat: number;
  lng: number;
}
export interface PropertyAddress {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  stateCode?: string | null;
  postalCode?: string | null;
  country?: string | null;
  latitude?: number;
  longitude?: number;
  mapSrc?: string; // Google or Apple static maps API src="" URL goes here
}

export enum PropertyAmenityQuickList {
  AirConditioning = "airConditioning",
  WiFi = "wifi",
  Parking = "parking",
  Bar = "bar",
  FullKitchen = "fullKitchen"
}

// TODO Post-MVP: Add TDY, PCS, Gov, Vacay, etc.. colored checkmarks
export interface PropertyItemData {
  title: string;
  quickList: PropertyAmenityQuickList[];
  bedCount: number;
  bathroomCount: number;
  description: string;
  image: string;
  price: number;
  listingRoute: string;
  femaApproved: boolean;
}
