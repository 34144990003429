import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import {ProfileComponent} from "./profile/profile.component";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit {
  profile:any= null;
  @ViewChild('sidenav') sidenav: any;
  public sidenavOpen:boolean = true;
  public imagePreview: string | null = null;
  public links = [
    { name: 'Reservations', href: 'my-reservation', icon: 'account_circle' },
    { name: 'Profile', href: 'profile', icon: 'account_circle' },
    // { name: 'Inbox', href: 'profile', icon: 'account_circle' },
    // { name: 'Payment', href: 'profile', icon: 'account_circle' },
    // { name: 'My Properties', href: 'my-properties', icon: 'view_list' },
    // { name: 'Favorites', href: 'favorites', icon: 'favorite' },
  ];
  constructor(
      public router: Router,
      private storage: StorageService,
      private apiService: ApiService
  ) {
    this.profile = this.storage.getData();
    this.imagePreview = 'assets/images/atease-blue-generic-profile-512px-v2.jpg';
   }

  ngOnInit() {
    if(window.innerWidth < 960){
      this.sidenavOpen = false;
    };

    this.apiService.getCall('/profile').subscribe({
      next: (resp: any) => {
        if (resp.error) {
          console.error(resp.error);
        }

        if (resp?.data) {
          // console.log(resp.data);
          const profileImageURL = resp.data?.profile_image_url?.url;
          if (profileImageURL) {
            this.imagePreview = profileImageURL;
          }
        }

      },
      error: (e) => {
        console.error(e);
      }
    });
  }

  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true;
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(window.innerWidth < 960){
          this.sidenav.close();
        }
      }
    });
  }
}
