import { Component, Input } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-search-results-header",
  templateUrl: "./search-results-header.component.html",
  styleUrls: ["./search-results-header.component.scss"],
})
export class SearchResultsHeaderComponent {
  @Input() city: string = "";
  @Input() stateCode: string = "";
  @Input() headerMessage: string = "";

  homeLinkSpanContent: string = "";

  constructor() {}
}
