import { Component, AfterViewInit, Input, Output, EventEmitter, ViewChild, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgZone } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { DateTime } from "luxon";
import { style, animate, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";

import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { ROUTES } from "src/app/constants/routes.constants";

// Services
import { MapsApiService } from "src/app/services/maps-api.service";
import { ApiService } from "src/app/services/api.service";
import { AddressParserService } from "src/app/services/address-parser.service";
import { SearchService } from "../../services/search.service";

import {
  SearchModel,
  SearchParams,
  SearchFilters,
  BlindSearchSettings,
  FormItem,
  TooltipMessages,
  StepperButtons,
  Allowances,
  TravelWithChoices,
  TDYFormData,
  TravelGuests
} from "../models/search-model";

@Component({
  selector: "app-properties-search",
  templateUrl: "./properties-search.component.html",
  styleUrls: ["./properties-search.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(300, style({ opacity: 0.2 })),
      ]),
    ]),
  ],
})

export class PropertiesSearchComponent implements AfterViewInit {
  @ViewChild("pickerInInput") pickerInInput!: ElementRef<HTMLInputElement>;
  @ViewChild("pickerOutInput") pickerOutInput!: ElementRef<HTMLInputElement>;
  @ViewChildren(MatTooltip) tooltips!: QueryList<MatTooltip>;
  @ViewChild("addressInput") addressInput!: ElementRef;

  @Input() wideMode: boolean = true;
  @Input() hideBoxShadow: boolean = false;
  @Input() showExpandedSearch: boolean = false;
  @Input() hideSearchForSmallScreens: boolean = false;
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();

  currentRoute: string = "";
  searchModel: SearchModel | null = null;
  defaultLocationPlaceholder = "";
  blindSearchAllowed: boolean = false;
  iconColorDarkBlue: string = "#243845"; // Atease dark blue
  iconColorTeal: string = "#00a6ce"; // Atease teal
  iconSize: number = 26;
  formData: FormGroup;
  totalGuests: number = 1;
  tooltipHoverDelay: number = 500; // in MS
  tooltipsReady: boolean = false;
  userTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  tooltipMessages: any = TooltipMessages;

  // Stepper -/+ buttons for outer/main FormGroup
  stepperButtons: FormItem[] = StepperButtons;

  // 'pcs' nested FormGroup
  pcsFormData: FormItem[] = [
    {
      formControlName: "travelingWith",
      type: "radio",
      radioOptions: TravelWithChoices,
    },
  ];

  // 'pcs' allowances nested FormGroup
  allowanceFormData: FormItem[] = Allowances;

  // 'pcs => travelWith' nested FormGroup
  travelWithFormData: FormItem[] = TravelGuests;

  // 'tdy' nested FormGroup
  tdyFormData: FormItem[] = TDYFormData;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private mapsApiService: MapsApiService,
    private apiService: ApiService,
    private addressParser: AddressParserService,
    private dialog: CustomDialogService,
    private zone: NgZone,
    private searchService: SearchService
  ) {
    this.searchModel = this.apiService.getSearchState();
    if(this.searchModel.defaultMeta.clientIP === null) {
      this.apiService.getClientIP().subscribe((response: any) => {
        this.searchService.setClientIP(this.searchModel, response.ip);
        this.apiService.setSearchState(this.searchModel);
      });
    }
    this.formData = this.initializeForm();
  }

  /**
   * Angular Lifecycle Methods
   * */
  ngOnInit() {
    this.currentRoute = this.router.url;
  }

  ngAfterViewInit(): void {
    // Check for tooltips after a short delay
    setTimeout(() => {
      this.tooltipsReady = !!this.tooltips && this.tooltips.length > 0;

      // Hide all tooltips initially
      if (this.tooltipsReady) {
        this.tooltips.forEach((tooltip: MatTooltip) => {
          tooltip.hide();
        });
      }
    }, 100);

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.setFormDatesFromModel();
        this.setFormValuesFromModel();

        if (this.searchModel) {
          this.apiService.setSearchState(this.searchModel);
        }
      });
    });
  }

  /**
   * Google/Apple Maps API to get address predictions
   */
  onAddressChange() {
    const inputElement: HTMLInputElement = this.addressInput.nativeElement;
    this.mapsApiService.getAddressPredictions(inputElement).subscribe((addressPrediction) => {
      // Update BOTH the form value AND the HTML input value with Maps API results
      inputElement.value = addressPrediction;
      this.formData.get("propertyLocation")?.setValue(addressPrediction);
    });
  }

  toggleExpandedSearch() {
    this.showExpandedSearch = !this.showExpandedSearch;
  }

  hideExpandedSearch() {
    this.showExpandedSearch = false;
  }

  changeStepperValue(formControlName: string, increment: boolean): void {
    const control = this.formData.get(formControlName);
    if (!control || control.disabled || typeof control.value !== "number") {
      return;
    }

    const step = increment ? 1 : -1;
    const newValue = (control.value || 0) + step;
    if (newValue < 0 && !increment) {
      return;
      // Don't allow steppers to go below 0
    }

    if (formControlName === "adults" && newValue < 1 && !increment) {
      return;
      // Don't allow adults to go below 1
    }

    // Mark the form control as dirty to trigger change detection and update the UI
    control.setValue(newValue);
    control.markAsDirty();
    this.calculateTotalGuests();
  }

  calculateTotalGuests(): void {
    let totalGuests = 0;

    // Check if "spouse" checkbox is checked and add one more guest
    const pcsGroup = this.formData.get("pcs") as FormGroup;
    const travelWithGroup = pcsGroup.get("travelWith") as FormGroup;
    const spouseIsChecked = travelWithGroup.get("spouse").value;

    // Loop through StepperButtons and count children, infants, dogs, and cats
    for (const item of StepperButtons) {
      const lowerControlName = item.formControlName.toLowerCase();
      // Only human beings count
      if (lowerControlName.includes("children") || lowerControlName.includes("infants") || lowerControlName.includes("adults")) {
        const value = this.formData.get(item.formControlName).value || 0;
        totalGuests += value;
      }
    }

    if (spouseIsChecked) {
      totalGuests++;
    }

    // Total Guests will always be a min of 1
    this.totalGuests = totalGuests || 1;
  }

  // Sets the date value for a checkin/out timepicker
  setDate(event: any, controlName: string) {
    const selectedDate: Date = event.value;

    // Ensure selectedDate is a Date object before proceeding
    if (!(selectedDate instanceof Date)) {
      console.error(`Invalid date format: ${selectedDate}`);
      console.error(typeof selectedDate);
      return;
    }

    // Convert selectedDate to a string in the format "yyyy-MM-dd"
    const dateString: string = selectedDate.toISOString().slice(0, 10);
    // console.log(`dateString: '${dateString}'`);
    // Update the actual HTML inputs with formatted dates
    if(controlName === "checkInDate") {
      // Update the form data itself with 'yyyy-mm-dd' formatted dates
      this.formData.get("checkInDate")?.setValue(dateString);
      // Also convert default times to MM/DD/YYYY and update the HTML inputs as well
      const formattedCheckin = this.formatDateForPickerInput(dateString);

      // Update the actual HTML inputs with formatted dates
      this.pickerInInput.nativeElement.value = formattedCheckin;
      const
          checkoutDate = new Date(this.formData.get("checkOutDate").value),
          updateCheckoutDate = (selectedDate.getTime() > checkoutDate.getTime());

      if(updateCheckoutDate) {
        let
            revisedCheckout = new Date(this.enforceThreeDayMinCheckoutValidator(dateString)),
            checkoutDateString = revisedCheckout.toISOString().slice(0, 10);
        this.formData.get("checkOutDate")?.setValue(checkoutDateString);
        const formattedCheckOut = this.formatDateForPickerInput(checkoutDateString);
        this.pickerOutInput.nativeElement.value = formattedCheckOut;
      }
    } else if(controlName === "checkOutDate") {
      this.formData.get(controlName)?.setValue(dateString);
      const formattedCheckOut = this.formatDateForPickerInput(dateString);
      this.pickerOutInput.nativeElement.value = formattedCheckOut;
    }
  }

  private setFormDatesFromModel() {
    const
        { dates: userEnteredDates }: SearchParams = this.searchModel.userSearchSettings,
        { dates: blindSearchDates }: BlindSearchSettings = this.searchModel.blindSearchSettings,
        { checkin: QP_CHECK_IN, checkout: QP_CHECK_OUT } = userEnteredDates,
        { checkin: BS_CHECK_IN, checkout: BS_CHECK_OUT } = blindSearchDates;

    let // If QueryParam Dates Exist, Use that, otherwise set blind search setting dates.
        checkinDateToSet = QP_CHECK_IN === '' ? BS_CHECK_IN : QP_CHECK_IN,
        checkoutDateToSet = QP_CHECK_OUT === '' ? BS_CHECK_OUT : QP_CHECK_OUT;

    // Update the form data itself with 'yyyy-mm-dd' formatted dates
    this.formData.get("checkInDate")?.setValue(checkinDateToSet);
    this.formData.get("checkOutDate")?.setValue(checkoutDateToSet);

    // Also convert default times to MM/DD/YYYY and update the HTML inputs as well
    const formattedCheckin = this.formatDateForPickerInput(checkinDateToSet);
    const formattedCheckOut = this.formatDateForPickerInput(checkoutDateToSet);

    // Update the actual HTML inputs with formatted dates
    this.pickerInInput.nativeElement.value = formattedCheckin;
    this.pickerOutInput.nativeElement.value = formattedCheckOut;
  }

  private createFormControls(data: FormItem[], formGroup: FormGroup) {
    data.forEach((control: any) => {
      const formControl = this.fb.control(null);
      if (control.type === "radio") {
        formControl.setValue(control.radioOptions[0].value); // Set default value for radio buttons
      }
      formGroup.addControl(control.formControlName, formControl);
    });
  }

  // Returns date in "MM/dd/yyyy" format
  private formatDateForPickerInput(dateString: string): string {
    // Find the delimiter in the dateString
    const delimiters = ["-", "/"];
    const delimiter = delimiters.find((delimiter) => dateString.includes(delimiter));

    // Parse the date string using Luxon's DateTime
    const parsedDate = delimiter === "/" ? DateTime.fromFormat(dateString, "MM/dd/yyyy") : DateTime.fromFormat(dateString, "yyyy-MM-dd");
    if (!parsedDate.isValid) {
      throw new Error(`Date '${dateString}' is invalid`);
    }
    let test = parsedDate.toFormat("M/d/yyyy");
    return parsedDate.toFormat("M/d/yyyy");
  }

  private setFormValuesFromModel() {
    if (!this.searchModel) return;

    const searchParams: SearchParams = this.searchModel.userSearchSettings;
    const searchFilters: SearchFilters = this.searchModel.filters;

    if (searchParams) {
      const { city, /*state,*/ stateCode, /*postalCode,*/ country } = searchParams.address;

      if (city && stateCode) {
        const addressStr = `${city}, ${stateCode}` + (country ? ` ${country}` : "");
        this.formData.get("propertyLocation")?.setValue(addressStr);
      }
    }

    if (searchFilters) {
      // Set Travel Type
      const travelType = searchFilters.typeOfTravel.vacation ? 'Vacation' : 'Government';
      this.formData.get("travelType").setValue(travelType);

      // Set Government Travel Type If Appropriate As Well as Additional Detail
      if(travelType === 'Government') {
        let typeOfGovernmentTravel = searchFilters.typeOfGovernmentTravel.tdy ? 'tdy' :
            searchFilters.typeOfGovernmentTravel.pcs ? 'pcs' : '';
        this.formData.get("governmentTravelType").setValue(typeOfGovernmentTravel);
        if(typeOfGovernmentTravel === 'pcs') {
          this.formData.get("pcs").get("lodgingAllowance").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.tle);
          this.formData.get("pcs").get("travelingWith").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.travelWithChoices);
          this.formData.get("pcs").get("travelWith").get("family").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.travelingWithFamily);
          this.formData.get("pcs").get("travelWith").get("spouse").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.travelingWithSpouse);
          this.formData.get("pcs").get("travelWith").get("others").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.travelingWithOthers);
          this.formData.get("pcs").get("usingBAH").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.useBAH);
          this.formData.get("pcs").get("mealAllowance").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forPCS.useMealAllowance);
        } else if (typeOfGovernmentTravel === 'tdy') {
          this.formData.get("tdy").get("lodgingAllowance").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forTDY.useLodgingAllowance);
          this.formData.get("tdy").get("mealAllowance").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forTDY.useMIEAllowance);
          this.formData.get("tdy").get("others").setValue(searchFilters.typeOfGovernmentTravel.additionalDetail.forTDY.isGroupTravel);
        }
      }

      // Set Occupancy Counts
      const
          adultCount = searchFilters.occupancy.adults,
          childCount = searchFilters.occupancy.childTwelvePlus,
          infantCount = searchFilters.occupancy.childUnderEleven;
      this.formData.get("adults").setValue(adultCount);
      this.formData.get("children").setValue(childCount);
      this.formData.get("infants").setValue(infantCount);
    }
    this.apiService.setSearchState(this.searchModel);
  }

  private updateSearchModelFilterFromFormValues(searchModel: SearchModel, formGroup: FormGroup): any | null {
    const
        formValues = formGroup?.value,
        { adults, children, infants, travelType, governmentTravelType, pcs, tdy } = formValues;

    // Track The Counts
    this.searchService.setAdultCountOnSearchModel(searchModel, adults);
    this.searchService.setChildCountOnSearchModel(searchModel, children);
    this.searchService.setInfantCountOnSearchModel(searchModel, infants);

    // Track The Travel Type
    this.searchModel = this.searchService.setTypeOfTravelOnSearchModel(searchModel, travelType);
    const
        processPCS = this.searchModel.filters.typeOfTravel.government && governmentTravelType === "pcs",
        processTDY = this.searchModel.filters.typeOfTravel.government && governmentTravelType === "tdy";

    if(processPCS) {
      this.searchModel = this.searchService.setTypeOfGovernmentTravelOnSearchModel(searchModel, governmentTravelType);
      this.searchModel = this.searchService.processFiltersForPCS(searchModel, pcs);
    } else if (processTDY) {
      this.searchModel = this.searchService.setTypeOfGovernmentTravelOnSearchModel(searchModel, governmentTravelType);
      this.searchModel = this.searchService.processFiltersForTDY(searchModel, tdy);
    } else {
      this.searchModel = this.searchService.setTypeOfGovernmentTravelOnSearchModel(searchModel, "none");
    }

    // Stringify Filter Options For Pass-through
    this.searchModel = this.searchService.convertFiltersToJSONString(searchModel);
    this.apiService.setSearchState(this.searchModel);

    return searchModel;
  }

  /**
   * Form Functions
   * */
  initializeForm(): FormGroup {
    this.searchModel = this.apiService.getSearchState();
    // Build and Set the Placeholder Text of the Input Field => Round Rock, TX USA
    this.defaultLocationPlaceholder = `${this.searchModel.blindSearchSettings.location.city}, `;
    this.defaultLocationPlaceholder += `${this.searchModel.blindSearchSettings.location.stateCode} `;
    this.defaultLocationPlaceholder += `${this.searchModel.blindSearchSettings.location.country}`;

    // Set Form Data Defaults
    this.formData = this.fb.group({
      propertyLocation: [''],
      checkInDate: [this.searchModel.blindSearchSettings.dates.checkin],
      checkOutDate: [this.searchModel.blindSearchSettings.dates.checkout],
      travelType: ['Vacation', Validators.required],
      governmentTravelType: [''],
      pcs: this.fb.group({
        travelWith: this.fb.group({}),
      }),
      tdy: this.fb.group({}),
    });

    // Default stepper values
    this.stepperButtons.forEach((item) => {
      const value = item.formControlName === "adults" ? 1 : 0;
      const control = this.fb.control(value);
      this.formData.addControl(item.formControlName, control);
    });

    const pcsFormGroup = this.formData.get("pcs") as FormGroup;
    this.createFormControls(this.pcsFormData, pcsFormGroup);
    this.createFormControls(this.allowanceFormData, pcsFormGroup);

    const tdyFormGroup = this.formData.get("tdy") as FormGroup;
    this.createFormControls(this.tdyFormData, tdyFormGroup);

    const travelWithFormGroup = pcsFormGroup.get("travelWith") as FormGroup;
    this.createFormControls(this.travelWithFormData, travelWithFormGroup);

    return this.formData;
  }

  submitSearchForm() {
    /**
     * UPDATE THE SEARCH MODEL :: LOCATION
     */
    const
        formValues = this.formData.value,
        addressInputProvided = formValues.propertyLocation !== '';

    let addressComponents: any = addressInputProvided ?
        this.addressParser.parseAddress(formValues.propertyLocation) : {};

    // Update The Search Model Data
    if(addressInputProvided) { // Then Execute A Targeted Search
      this.searchModel = this.searchService.setLocationOnSearchModel(this.searchModel, addressComponents);
      this.apiService.setSearchState(this.searchModel);
    } else {
      this.searchModel = this.searchService.resetLocationOnSearchModel(this.searchModel);
      this.apiService.setSearchState(this.searchModel);
    }

    /**
     * UPDATE THE SEARCH MODEL :: DATES
     */
    this.searchModel = this.searchService.setCheckInDateOnSearchModel(this.searchModel, this.formData.value.checkInDate);
    this.searchModel = this.searchService.setCheckOutDateOnSearchModel(this.searchModel, this.formData.value.checkOutDate);
    this.apiService.setSearchState(this.searchModel);

    /**
     * UPDATE THE SEARCH MODEL :: Filters
     */
    this.searchModel = this.updateSearchModelFilterFromFormValues(this.searchModel, this.formData);
    this.apiService.setSearchState(this.searchModel);

    /**
     * HANDLE REQUEST TO FETCH PROPERTIES
     */
    this.searchService.setOriginatingRoute(this.searchModel, this.router.url);
    if(this.currentRoute === "/") {
      this.router.navigate(['/results']);
    }

    this.apiService.getProperties().subscribe((response: any) => {
      this.searchService.setCurrentPageResults(response);
    });
  }

  clearForm() {
    this.formData = this.initializeForm();
    this.setFormDatesFromModel();
    this.apiService.resetSearchState();
    this.totalGuests = 1;
  }

  /**
   * Validation Helper Functions
   * */
  public noPastDatesOrCurrentDay() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(today.getDate() + 2).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
  public noPastDatesForCheckout() {
    const checkin = new Date(this.formData.get("checkInDate").value);
    const yyyy = checkin.getFullYear();
    const mm = String(checkin.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(checkin.getDate() + 5).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
  public enforceThreeDayMinCheckout() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(today.getDate() + 5).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
  public enforceThreeDayMinCheckoutValidator(checkin: string) {
    const checkinDate = new Date(checkin);
    const yyyy = checkinDate.getFullYear();
    const mm = String(checkinDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(checkinDate.getDate() + 4).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
}
