// TODO: @Anthony @Dustin, please confirm and finish these
export const ROUTES = {
  /**
   * Auth Routes
   * */
  SIGN_UP: "signup",
  SIGN_IN: "signin",
  FORGOT_PASSWORD: "forgot-password",
  UPDATE_PASSWORD: "update-password",
  REGISTER: "registration/callback",
  LOGOUT: "logout",
  /**
   * Authenticated Routes
   * */
  ACCOUNT: "account",
  PROFILE: "profile",
  PREP_BOOKING: "booking",
  BOOKINGS: "bookings",
  /**
   * Search Routes
   * */
  PROPERTY_FILTER_RESULTS: "property-filter", //? What should we do with this?
  PROPERTY_RESULTS: "results",
  PROPERTY_BY_ID: "property/:id",
  /**
   * Static Page Routes
   * */
  OUR_STORY: "our-story",
  CONTACT: "contact",
  PRIVACY: "privacy",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  INVESTOR_RELATIONS: "investor-relations",
  PRESS: "press",
  FAQ: "faq",
  HOW_IT_WORKS: "how-it-works",
};
