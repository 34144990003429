import { Component, Input, OnInit } from "@angular/core";
import { LabelledRoute } from "src/app/shared/models/menu-model";
import {VerifyIdmeComponent} from "../../pages/register/verify-idme/verify-idme.component";
import {LoginComponent} from "../../pages/login/login.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {StorageService} from "../../services/storage.service";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: "app-toggle-switch",
  templateUrl: "./toggle-switch.component.html",
  styleUrls: ["./toggle-switch.component.scss"],
})

export class ToggleSwitchComponent implements OnInit {
  @Input() menuItems: LabelledRoute[] = [];
  @Input() submenuItems: LabelledRoute[] = [];
  constructor(
      public dialog: MatDialog,
      public snackBar: MatSnackBar,
      public storageService: StorageService,
      public router: Router,
      public authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.loginSuccess$.subscribe(() => {
      this.menuItems = this.storageService.setStateBasedMainMenuRoutes([], true);
      this.submenuItems = this.storageService.setStateBasedSubMenuRoutes([], true);
    });

  }

  public handleAuthRequest(route: string): any {
    let userProfileExists = !!this.storageService.getUserData();
    this.menuItems = this.storageService.setStateBasedMainMenuRoutes([], userProfileExists);
    this.submenuItems = this.storageService.setStateBasedSubMenuRoutes([], userProfileExists);

    if(route.includes("signin")) {
      return this.openSignIn("Test");
    } else if (route.includes("signup")) {
      return this.openSignup("Test");
    } else if (route.includes("logout")) {
      return this.initiateLogout("Test");
    } else if (route.includes("profile")) {
      return this.navigateToProfile();
    }
  }

  public openSignup(message: string) {
    return this.dialog.open(VerifyIdmeComponent, {
      maxWidth: "400px",
      data: message
    });
  }

  public openSignIn(message: string) {
    return this.dialog.open(LoginComponent, {
      maxWidth: "400px",
      data: message
    });
  }

  public initiateLogout(message: string) {
    let userProfileExists = !!this.storageService.getUserData();
    if(userProfileExists) {
      this.storageService.removeLogin();
      let removedData = this.storageService.getUserData() === null;
      if(removedData) {
        this.snackBar
            .open(
                "Logged Out Successfully",
                '×',
                { panelClass: 'success', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
            );
        this.menuItems = this.storageService.setStateBasedMainMenuRoutes([], false);
        this.submenuItems = this.storageService.setStateBasedSubMenuRoutes([], false);
        return this.router.navigate(["/"]);
      } else {
        this.snackBar
            .open(
                "Failure To Logout. Something Went Wrong",
                '×',
                { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
            );
        this.menuItems = this.storageService.setStateBasedMainMenuRoutes([], false);
        this.submenuItems = this.storageService.setStateBasedSubMenuRoutes([], false);
        return null;
      }
    }
    // Show Logout Even If Profile DNE
    this.snackBar
        .open(
            "Logged Out Successfully",
            '×',
            { panelClass: 'success', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
        );
    this.menuItems = this.storageService.setStateBasedMainMenuRoutes([], false);
    this.submenuItems = this.storageService.setStateBasedSubMenuRoutes([], false);
    return this.router.navigate(["/"]);
  }

  public navigateToProfile() {
    return this.router.navigate(['/account/profile']);
  }
}
