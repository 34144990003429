<div class="container">
  <div class="row">
    <a routerLink="/">
      Home
      <span *ngIf="city?.length && stateCode?.length">- {{ city }}, {{ stateCode }}</span>
    </a>
  </div>

  <div class="row">
    <h1>{{ headerMessage }}</h1>
  </div>
</div>
