<form class="searchBar__form" [formGroup]="formData">
  <section class="main-form-icons searchBar__section"
           [class.wide-mode]="wideMode"
           [class.hide-boxshadow]="hideBoxShadow"
  >
    <div class="input-container location_input__container">
      <div class="location__input">
        <app-property-search-icons
                class="custom-icon"
                [size]="iconSize"
                [icon]="'location'"
                [color]="iconColorDarkBlue"
        ></app-property-search-icons>
        <input
                #addressInput
                formControlName="propertyLocation"
                (blur)="onAddressChange()"
                (keydown)="onAddressChange()"
                [placeholder]="defaultLocationPlaceholder"
                class="overridePlaceholderColor"
        />
      </div>
    </div>

    <!-- ************************************************************************ -->
    <!-- *************************** DEFAULT ICON FORM ************************** -->
    <!-- ************************************************************************ -->
    <div class="calendar-container">
      <div class="check-in-container">
        <app-property-search-icons
                class="custom-icon"
                [size]="iconSize"
                [icon]="'calendar'"
                [color]="iconColorDarkBlue"
        ></app-property-search-icons>
        <mat-datepicker-toggle matIconSuffix [for]="pickerIn" class="datePickerToggle"></mat-datepicker-toggle>
        <input
                #pickerInInput
                class="picker-input"
                matInput
                [matDatepicker]="pickerIn"
                formControlName="checkInDate"
                (dateChange)="setDate($event, 'checkInDate')"
                min="{{noPastDatesOrCurrentDay()}}"
        />
        <mat-datepicker #pickerIn></mat-datepicker>
      </div>
      <div class="check-out-container">
        <app-property-search-icons [size]="iconSize" class="custom-icon" [icon]="'calendar'" [color]="iconColorDarkBlue"></app-property-search-icons>
        <mat-datepicker-toggle matIconSuffix [for]="pickerOut" class="datePickerToggle"></mat-datepicker-toggle>
        <input
                #pickerOutInput
                class="picker-input"
                matInput
                [matDatepicker]="pickerOut"
                formControlName="checkOutDate"
                (dateChange)="setDate($event, 'checkOutDate')"
                min="{{noPastDatesForCheckout()}}"
        />
        <mat-datepicker #pickerOut></mat-datepicker>
      </div>
    </div>

    <div class="guests-container" (click)="toggleExpandedSearch()">
      <div class="additionalInfo__input">
        <app-property-search-icons
                class="custom-icon"
                [size]="iconSize"
                [icon]="'guests'"
                [color]="iconColorDarkBlue">
        </app-property-search-icons>
        <span class="totalGuestCounter">
          {{ totalGuests }}
        </span>
      </div>
    </div>
    <div class="guests-search-container">
      <div class="search-button-container">
        <button type="button" (click)="submitSearchForm()">
          <app-property-search-icons class="custom-icon" [icon]="'search'" color="white"></app-property-search-icons>
        </button>
      </div>
    </div>
  </section>

  <!-- ************************************************************************ -->
  <!-- *************************** EXPANDED FORM ****************************** -->
  <!-- ************************************************************************ -->
  <div class="expanded-form" [ngClass]="{ show: showExpandedSearch }">
    <section [class.wide-mode]="wideMode">
      <h2>Total Guests</h2>
      <div class="stepper-container">
        <ng-container *ngFor="let item of stepperButtons">
          <!-- -/+ Steppers -->
          <div class="stepper-item" appearance="fill">
            <span class="counter-label">{{ item.label }}</span>

            <!-- "-" BUTTON -->
            <div class="stepper-button-container">
              <button class="stepper-button button-minus" (click)="changeStepperValue(item.formControlName, false)">
                <mat-icon>remove</mat-icon>
              </button>

              <span class="counter-value">{{ formData.get(item.formControlName)?.value || 0 }}</span>

              <!-- "+" BUTTON -->
              <button class="stepper-button button-plus" (click)="changeStepperValue(item.formControlName, true)">
                <!-- Teal "+" button with white background -->
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </section>

    <section [class.wide-mode]="wideMode">
      <!-- ********* GOV OR VACATION ********* -->
      <h2>Type of Travel</h2>

      <div class="radio-buttons-container">
        <app-custom-radio
          formControlName="travelType"
          [label]="'Government'"
          [value]="'Government'"
          [fontSize]="16"
          name="travelType"
        ></app-custom-radio>
        <app-custom-radio formControlName="travelType" [label]="'Vacation'" [value]="'Vacation'" [fontSize]="16" name="travelType">
        </app-custom-radio>
      </div>

      <!-- IF "Government" ==> Render PCS and TDY radio buttons -->
      <ng-container *ngIf="formData.get('travelType')?.value === 'Government'">
        <div class="government-container">
          <app-custom-radio
            formControlName="governmentTravelType"
            [label]="'PCS'"
            [value]="'pcs'"
            [fontSize]="16"
            name="governmentTravelType"
          ></app-custom-radio>
          <button class="mat-tooltip" mat-icon-button matTooltip="{{ tooltipMessages.pcs }}" [matTooltipShowDelay]="tooltipHoverDelay">
            <app-property-search-icons [size]="20" class="custom-icon" [icon]="'info'" [color]="iconColorTeal"></app-property-search-icons>
          </button>

          <app-custom-radio
            class="tdy-radio"
            formControlName="governmentTravelType"
            [label]="'TDY'"
            [value]="'tdy'"
            [fontSize]="16"
            name="governmentTravelType"
          ></app-custom-radio>

          <button class="mat-tooltip" mat-icon-button matTooltip="{{ tooltipMessages.tdy }}" [matTooltipShowDelay]="tooltipHoverDelay">
            <app-property-search-icons [size]="20" class="custom-icon" [icon]="'info'" [color]="iconColorTeal"></app-property-search-icons>
          </button>

          <!-- ************************************************************************ -->
          <!-- *************************** PCS TRAVEL ********************************* -->
          <!-- ************************************************************************ -->
          <div *ngIf="formData.get('governmentTravelType')?.value === 'pcs'" [@fadeInOut]>
            <div class="gov-type-options" formGroupName="pcs" *ngFor="let item of pcsFormData">
              <div *ngIf="item.type === 'radio'">
                <ng-container *ngFor="let option of item.radioOptions">
                  <app-custom-radio
                    [formControlName]="item.formControlName"
                    [label]="option.label"
                    [value]="option.value"
                    [fontSize]="16"
                    [name]="item.formControlName"
                  ></app-custom-radio>
                </ng-container>
              </div>
            </div>

            <div class="gov-type-container">
              <div class="checklist-col">
                <h3>Allowances</h3>
                <div formGroupName="pcs" *ngFor="let item of allowanceFormData">
                  <!-- *** CHECKBOX *** -->
                  <div class="pcs-checkbox searchBarCheckboxes" *ngIf="item.type === 'checkbox'">
                    <mat-checkbox [formControlName]="item.formControlName">
                      {{ item.label }}
                    </mat-checkbox>
                    <ng-container *ngIf="item.tooltip">
                      <button class="mat-tooltip mat-tooltip__override" mat-icon-button matTooltip="{{ item.tooltip }}" [matTooltipShowDelay]="tooltipHoverDelay">
                        <app-property-search-icons
                          [size]="20"
                          class="custom-icon"
                          [icon]="'info'"
                          [color]="iconColorTeal"
                        ></app-property-search-icons>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div formGroupName="pcs" class="checklist-col">
                <h3>Travel Guests</h3>
                <div *ngFor="let item of travelWithFormData" formGroupName="travelWith">
                  <div class="pcs-checkbox searchBarCheckboxes" *ngIf="item.type === 'checkbox'">
                    <mat-checkbox [formControlName]="item.formControlName" (click)="calculateTotalGuests()">
                      {{ item.label }}
                    </mat-checkbox>
                    <ng-container *ngIf="item.tooltip">
                      <button class="mat-tooltip mat-tooltip__override" mat-icon-button matTooltip="{{ item.tooltip }}" [matTooltipShowDelay]="tooltipHoverDelay">
                        <app-property-search-icons
                          [size]="20"
                          class="custom-icon"
                          [icon]="'info'"
                          [color]="iconColorTeal"
                        ></app-property-search-icons>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ************************************************************************ -->
          <!-- *************************** TDY TRAVEL ********************************* -->
          <!-- ************************************************************************ -->
          <div class="tdy-container" *ngIf="formData.get('governmentTravelType')?.value === 'tdy'" [@fadeInOut]>
            <div class="gov-type-options" formGroupName="tdy" *ngFor="let item of tdyFormData">
              <div class="tdy-item">
                <div class="tdy-radio-container" *ngIf="item.type === 'radio'">
                  <ng-container *ngFor="let option of item.radioOptions">
                    <app-custom-radio
                      [formControlName]="item.formControlName"
                      [label]="option.label"
                      [value]="option.value"
                      [fontSize]="16"
                      [name]="item.formControlName"
                    ></app-custom-radio>
                  </ng-container>
                </div>

                <!-- *** CHECKBOX *** -->
                <div class="tdy-checkbox" *ngIf="item.type === 'checkbox'">
                  <mat-checkbox [formControlName]="item.formControlName">
                    {{ item.label }}
                  </mat-checkbox>
                  <ng-container *ngIf="item.tooltip">
                    <button class="mat-tooltip" mat-icon-button matTooltip="{{ item.tooltip }}" [matTooltipShowDelay]="tooltipHoverDelay">
                      <app-property-search-icons [size]="20" class="custom-icon" [icon]="'info'" [color]="iconColorTeal"></app-property-search-icons>
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </section>

    <section class="buttons-section" [class.wide-mode]="wideMode">
      <button class="clear-btn" (click)="clearForm()">Clear</button>
      <button class="hide-btn" (click)="hideExpandedSearch()">Hide</button>
    </section>
  </div>
</form>
