import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Property } from 'src/app/app.models';
import { AppSettings, Settings } from 'src/app/app.settings';

import { ApiService } from 'src/app/services/api.service';
import { reservation } from 'src/app/shared/models/reservation';
import { StorageService } from 'src/app/services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CheckoutModel } from "../../shared/models/checkout-model";
import {LoggerService} from "../../services/logger.service";

import {SwiperContainer} from "swiper/element";
import {SwiperOptions} from "swiper/types";
import {HeaderService} from "../../services/header.service";

/*import { StripeService } from 'src/app/services/stripe.service';*/
/*import { register as registerSwiperElements } from 'swiper/element/bundle';
registerSwiperElements();*/

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})

export class PropertyComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  //@ViewChild('swiperThumbs') swiperThumbs!: ElementRef<SwiperContainer>;

  imageGalleryIndex: number = 0;
  imageGallery: any[] = [];

  swiperConfig: SwiperOptions = {
    spaceBetween: 10,
    loop: true,
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  }

/*  swiperThumbsConfig: SwiperOptions = {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }*/

  public sidenavOpen:boolean = true;
  private sub: any;
  public property:Property;
  public settings: Settings;
  public embedVideo: any;
  public agent:any;
  public ReservationForm: UntypedFormGroup;

  public pageLoading = false;

  Guests = new FormControl('');
  perDayCost: number = 0; // Replace with your actual nightly rate
  taxRate: number = 0.1; // Replace with your actual tax rate
  adultPrice: number = 100; // Replace with your actual adult price
  childPrice: number = 50;
  governmentRate: number = 0.05;
  totalPrice: number = 0;
  subtotal: number =0;
  totalDays: number = 0;
  profile: any;
  constructor(
      public appSettings:AppSettings,
      public appService:AppService,
      private activatedRoute: ActivatedRoute,
      /*private embedService: EmbedVideoService,*/
      public fb: UntypedFormBuilder,
      public router: Router,
      public apiService: ApiService,
      private storage: StorageService,
      public snackBar: MatSnackBar,
      public loggerService: LoggerService,
      private headerService: HeaderService
  ) {
    this.pageLoading = true;
    this.headerService.setCurrentPage('property');
    this.settings = this.appSettings.settings;
    this.storage.userProfile.subscribe(profile => {
      this.profile = profile;
    });
  }


  los: string = "";
  guestDescription: string = "";
  softQuoteForVacation: string = "";
  softQuoteForGov: string = "";
  softQuoteForPCS: string = "";
  propertyId: number = 0;
  ngOnInit() {
    /**
     * Fetch Property By Id
     * */
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.propertyId = params['id'];
      this.getPropertyById(params['id']);
    });

    /**
     * Setup Reservation Form
     * */
    this.ReservationForm = this.fb.group({
      id: [this.propertyId],
      travelType: [''],
      checkInDate: [''],
      checkOutDate: [''],
      guests: [''],
      PropertyBookingTitle: [this.property?.title],
      Amount: [this.property?.price['priceScale']['baseRate'] || null, []],
      perDayCost: [this.perDayCost],
      taxRate: [this.taxRate],
      adultPrice: [this.adultPrice],
      childPrice: [this.childPrice],
      governmentRate: [this.governmentRate],
      totalPrice: [this.totalPrice],
      subtotal: [this.subtotal],
      totalDays: [this.totalDays],
    });

    if(window.innerWidth < 960){
      this.sidenavOpen = false;
    }
  }

  ngAfterViewInit(){
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateMainPhoto(id: any) {
    this.imageGalleryIndex = id;
    if(this.swiper.nativeElement.swiper) {
      this.swiper.nativeElement.swiper.activeIndex = this.imageGalleryIndex;
      this.swiper.nativeElement.swiper.update();
    }
  }

  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true;
  }

  nearbyPlaces: any;
  baseRate: number = 0.0;
  federalRate: number = 0.0;
  travelDescriptor: string = "";
  searchState: any;
  images: any = [];
  public getPropertyById(id) {
    const savedSearchState = this.apiService.getSearchState();
    this.searchState = savedSearchState;
    this.loggerService.logMsg("Make call to fetch property", this.searchState);

    // Fetch Property By Id and CheckIn/CheckOut Dates
    this.apiService
        .getPropertyByIdv2('/search/property/'+ id, savedSearchState)
        .subscribe(data => {
          this.loggerService.logMsg("Call Was Made, Data Returned: ", data);
          this.property = data['content'];
          this.baseRate = parseFloat(data['content']['price']['priceScale']['baseRate']);
          this.federalRate = parseFloat(data['content']['price']['priceScale']['federalRate']);

          this.nearbyPlaces = this.property.nearbyPlaces;
          delete this.nearbyPlaces.id;
          delete this.nearbyPlaces.createdAt;
          delete this.nearbyPlaces.updatedAt;
          delete this.nearbyPlaces.fk_property_id;
          this.nearbyPlaces = [...Object.values(this.nearbyPlaces)];

          // Process Property Photos
          this.imageGallery = this.processPropertyPhotos(this.property.gallery);

          this.loggerService.logMsg("Image gallery was: ", this.imageGallery);

          if (savedSearchState) {
            let
                nightCount = this.calculateNights(savedSearchState?.userSearchSettings?.dates.checkin, savedSearchState?.userSearchSettings?.dates.checkout),
                guestDescription = "",
                travelType = savedSearchState.filters.typeOfTravel.vacation ? "Vacation" : "Government Travel";

            // Build Guest Description
            if(savedSearchState.filters.occupancy.adults > 0) {
              guestDescription += savedSearchState.filters.occupancy.adults === 1 ?
                  `${savedSearchState.filters.occupancy.adults} Adult` :
                  `${savedSearchState.filters.occupancy.adults} Adults`;
            }
            if(savedSearchState.filters.occupancy.childTwelvePlus > 0) {
              guestDescription += savedSearchState.filters.occupancy.childTwelvePlus === 1 ?
                  `, ${savedSearchState.filters.occupancy.childTwelvePlus} Child Over 12` :
                  `, ${savedSearchState.filters.occupancy.childTwelvePlus} Children Over 12`;
            }
            if(savedSearchState.filters.occupancy.childUnderEleven > 0) {
              guestDescription += savedSearchState.filters.occupancy.childUnderEleven === 1 ?
                  `, ${savedSearchState.filters.occupancy.childUnderEleven} Child Under 11` :
                  `, ${savedSearchState.filters.occupancy.childUnderEleven} Children Under 11`;
            }

            this.softQuoteForVacation = data['content']['price']['priceScale']['calculatedRatesForStay']['calculatedBaseRate'];
            this.softQuoteForGov = data['content']['price']['priceScale']['calculatedRatesForStay']['calculatedFederalRate'];
            this.softQuoteForPCS = data['content']['price']['priceScale']['calculatedRatesForStay']['calculatedPCSRate'];

            this.travelDescriptor = travelType === "Government Travel" ?
                savedSearchState.filters.typeOfGovernmentTravel.pcs ? `${travelType}: PCS` :
                    savedSearchState.filters.typeOfGovernmentTravel.tdy ? `${travelType}: TDY` :
                        travelType : travelType;
            this.guestDescription = guestDescription;
            this.los = nightCount === 1 ? `${nightCount} Night` : `${nightCount} Nights`;
            this.ReservationForm.get('travelType').setValue(travelType);
            this.pageLoading = false;
          }
        });
  }

  public onBookReservationSubmit(values:reservation) {
    if(!this.profile) { // Is User Logged In?
      return this.snackBar.open(
          `Please login or Signup to make a booking!`,
          '×',
          { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
      );
    }

    if(!this.storage.getUserProfileStatus()) { // Did User Complete Profile?
      return this.snackBar.open(
          `Please Complete Your Profile Prior To Making a Booking!`,
          '×',
          { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 }
      );
    }

    // Set Reservation Form
    if (this.ReservationForm.valid) {
      const savedSearchState = this.apiService.getSearchState();
      const reservationData: reservation = {
        ...values,
        perDayCost: this.perDayCost,
        taxRate: this.taxRate,
        adultPrice: this.adultPrice,
        childPrice: this.childPrice,
        governmentRate: this.governmentRate,
        totalPrice: this.totalPrice,
        subtotal: this.subtotal,
        totalDays: this.totalDays,
      };
      const propertyData = {
        perDayCost:this.property?.price['priceScale']['baseRate'],
        taxRate: this.taxRate,
        propertyName: this.property?.title,
        governmentRate: this.governmentRate,
        totalPrice: this.totalPrice,
        subtotal: this.subtotal,
        totalDays: this.totalDays,
      }
      const checkoutModel = new CheckoutModel();
      checkoutModel.user = this.profile;
      checkoutModel.initialQuery = savedSearchState;
      checkoutModel.property = this.property;
      this.apiService.setCheckoutState(checkoutModel);
      return this.router.navigate(['booking/', this.propertyId]);
    }
    return null;
  }

  calculateNights(checkInStr: string, checkOutStr: string): number {
    const
        checkIn = new Date(checkInStr),
        checkOut = new Date(checkOutStr),
        timeDiff = checkOut.getTime() - checkIn.getTime();
    const totalDays = timeDiff / (1000 * 3600 * 24);
    return totalDays;
  } // TODO: Make Global Function

  processPropertyPhotos(gallery: any): any {
    let processedGallery = [];
    gallery.forEach(item => {
      processedGallery.push({
        title: "",
        description: "",
        url: item.big,
        alt: item.alt ? item.alt : "No Alt Provided For Screen Readers",
      });
    });

    return processedGallery;
  }
}


/*  public reviews = [
    {
      author: 'Bruno Vespa',
      avatar: 'assets/images/avatars/avatar-1.png',
      tooltip: 'Dissatisfied',
      icon: 'sentiment_dissatisfied',
      date: '13 January, 2018 at 7:09',
      text: 'Integer id eros et mi fringilla imperdiet. In dictum turpis eget magna viverra condimentum. Ut malesuada interdum ultrices. Proin tristique sem pellentesque, posuere dui in, maximus magna. Aenean vehicula, tortor gravida elementum tincidunt, justo lorem vestibulum ex, eget egestas arcu tellus in magna.'
    },
    {
      author: 'Julia Aniston',
      avatar: 'assets/images/avatars/avatar-2.png',
      tooltip: 'Very Satisfied',
      icon: 'sentiment_very_satisfied',
      date: '04 February, 2018 at 10:22',
      text: 'Nulla accumsan, lacus sed suscipit rutrum, turpis augue accumsan metus, in accumsan urna mi vehicula lorem. Pellentesque semper nibh vitae augue placerat finibus. Nulla sed porttitor nunc, quis tristique sem. Quisque in varius nisl. Integer turpis lorem, ultricies sed sem nec, commodo molestie arcu. Nulla finibus ex tortor, et suscipit magna semper consectetur. Cras sit amet metus dui. Maecenas eget dui at ex varius malesuada vel non felis.'
    },
    {
      author: 'Andy Warhol',
      avatar: 'assets/images/avatars/avatar-3.png',
      tooltip: 'Neutral',
      icon: 'sentiment_neutral',
      date: '14 February, 2018 at 11:10',
      text: 'Pellentesque hendrerit vel turpis aliquam placerat. Suspendisse ullamcorper congue feugiat. Etiam gravida metus ac massa posuere venenatis. Pellentesque vehicula lobortis dolor, ac pretium dolor maximus quis. Fusce vitae iaculis mauris, quis posuere ex. Mauris vitae convallis nibh. Etiam eget enim at orci interdum maximus nec in ante.'
    }
  ];
  {
      title: "Test 1",
      description: "",
      url: "https://atease.nyc3.cdn.digitaloceanspaces.com/dev/properties/8/raspberry.jpg",
      alt: "Alt Text For Screen Readers"
    },
    {
      title: "Test 2",
      description: "",
      url: "https://atease.nyc3.cdn.digitaloceanspaces.com/dev/properties/16/lychee.jpg",
      alt: "Alt Text For Screen Readers"
    },
    {
      title: "Test 3",
      description: "",
      url: "https://atease.nyc3.cdn.digitaloceanspaces.com/dev/properties/8/raspberry.jpg",
      alt: "Alt Text For Screen Readers"
    }




  */
