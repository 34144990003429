import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-embedded-video",
  templateUrl: "./embedded-video.component.html",
  styleUrls: ["./embedded-video.component.scss"],
})
export class EmbeddedVideoComponent implements OnInit, AfterViewInit {
  @ViewChild("embeddedVideo", { static: false }) embeddedVideo!: ElementRef;

  @Input() videoURL: string = "";
  @Input() width: string = "90%";
  @Input() height: number = 315;
  @Input() cssFrameWidth: string | null = null;
  @Input() allowfullscreen: boolean = false;
  @Input() roundedCorners: boolean = false;

  safeVideoUrl: SafeResourceUrl = "";

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    // Sanitize the videoURL to prevent security risks
    if (this.videoURL.includes("youtube.com") || this.videoURL.includes("youtu.be")) {
      const videoId = this.extractYouTubeVideoId(this.videoURL);
      if (videoId) {
        this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube-nocookie.com/embed/${videoId}`);
      }
    } else {
      // If the URL is not from YouTube, sanitize it directly
      this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
    }
  }

  ngAfterViewInit() {
    // Access the native element of app-embedded-video
    const embeddedVideoElement: HTMLElement = this.embeddedVideo.nativeElement;

    if (this.roundedCorners) {
      embeddedVideoElement.style.borderRadius = "1.6rem";
    }

    if (this.cssFrameWidth) {
      // embeddedVideoElement.style.width = this.cssFrameWidth;
    }
  }

  // Function to extract YouTube video ID from URL
  private extractYouTubeVideoId(url: string): string | null {
    const youtubeRegex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(youtubeRegex);
    return match ? match[1] : null;
  }
}
