import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { passwordMatchValidator, passwordValidator } from "../../theme/utils/app-validators";
import { MatSnackBar } from "@angular/material/snack-bar";
import {HeaderService} from "../../services/header.service";

@Component({
    selector: 'app-changePassword',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [AuthService]
})

export class ChangePasswordComponent implements OnInit {
    resetToken: string = "";
    resetFor: string = "mail@gmail.com";
    changePasswordForm: FormGroup;
    errorMessage = "";
    isPasswordVisible: boolean = false;
    isRePasswordVisible: boolean = false;
    passwordMismatch: boolean = false;
    invalidPassword: boolean = false;
    invalidPwdLength: boolean = false;
    invalidAlphaNumChar: boolean = false;
    invalidCombo: boolean = false;
    invalidFullNumeric: boolean = false;
    invalidPwdEmail: boolean = false;

    constructor(
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private headerService: HeaderService
    ) {
        this.headerService.setCurrentPage('change-password');
        this.changePasswordForm = this.fb.group(
            {
                password: ['', [Validators.required]],
                repassword: ['', [Validators.required]]
            },
            { validators: [passwordMatchValidator, passwordValidator] }
        );
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.resetToken = params['resetToken'];
            this.authService.verifyResetToken(this.resetToken).subscribe((response: any) => {
                this.resetFor = response.data.resetFor;
            });
        });

        this.changePasswordForm.valueChanges.subscribe(() => {
            this.invalidPwdLength = this.changePasswordForm.hasError('length');
            if(this.invalidPwdLength) this.errorMessage = this.changePasswordForm.getError("length");
            this.invalidAlphaNumChar = this.changePasswordForm.hasError('alphaNumericSafeSpecialChar');
            if(this.invalidAlphaNumChar) this.errorMessage = this.changePasswordForm.getError("alphaNumericSafeSpecialChar");
            this.invalidCombo = this.changePasswordForm.hasError('caseNumeric');
            if(this.invalidCombo) this.errorMessage = this.changePasswordForm.getError("caseNumeric");
            this.invalidFullNumeric = this.changePasswordForm.hasError('nineDigitsRow');
            if(this.invalidFullNumeric) this.errorMessage = this.changePasswordForm.getError("nineDigitsRow");
            this.passwordMismatch = this.changePasswordForm.hasError('passwordMismatch');

            let setPassword: string = this.changePasswordForm.controls['password'].value;
            this.invalidPwdEmail = this.resetFor === setPassword;
            if(this.invalidPwdEmail) this.errorMessage = "You cannot set your password to your email";
        });
    }

    changePassword(event: Event) {
        event.preventDefault();
        if(this.changePasswordForm.valid) {
            return this.authService
                .changePassword(this.resetToken, this.changePasswordForm.controls['password'].value)
                .subscribe((response: any) => {
                    if(response.status === 400) {
                        const snackbarMessage = `Unable To Change Password: ${response.message.data}`;
                        return this.snackBar.open(snackbarMessage, "×", {
                            panelClass: "alert",
                            horizontalPosition: "right",
                            verticalPosition: "top",
                            duration: 8000,
                        });
                    } else if (response) {
                        console.log("Response From Change Password: ", response);
                        const snackbarMessage = "Password Successfully Changed";
                        let dialogRef = this.snackBar.open(snackbarMessage, "×", {
                            panelClass: "alert",
                            horizontalPosition: "right",
                            verticalPosition: "top",
                            duration: 8000,
                        });
                        return dialogRef
                            .afterDismissed()
                            .subscribe(() => this.router.navigate(["/login"]));
                    }
                    return null;
                });
        } else {
            console.log("Not Valid Submission", this.changePasswordForm);
            return this.snackBar.open("Please Correct The Form Data", "×", {
                panelClass: "error",
                horizontalPosition: "right",
                verticalPosition: "top",
                duration: 8000,
            });
        }
    }

    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    toggleRePasswordVisibility() {
        this.isRePasswordVisible = !this.isRePasswordVisible;
    }
}
