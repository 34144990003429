import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-floating-search-button",
  templateUrl: "./floating-search-button.component.html",
  styleUrls: ["./floating-search-button.component.scss"],
})
export class FloatingSearchButtonComponent {
  @Output() showMobileSearch = new EventEmitter<boolean>();
  allowSearchFormToDisplay: boolean = false;

  constructor() {}

  toggleMobileSearchForm() {
    this.allowSearchFormToDisplay = !this.allowSearchFormToDisplay;
    this.showMobileSearch.emit(this.allowSearchFormToDisplay);
  }
}
