import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Dialog, DialogRef, DIALOG_DATA } from "@angular/cdk/dialog";
import { CustomDialogComponent } from "src/app/shared/custom-dialog/custom-dialog.component";

@Injectable({
  providedIn: "root",
})
export class CustomDialogService {
  duration: number = 99000;

  constructor(private dialog: Dialog, private snackBar: MatSnackBar) {}

  show(title: string, message?: string) {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      height: "30vw",
      width: "40vw",
      data: { title, message },
    });

    setTimeout(() => {
      dialogRef.close();
    }, this.duration);
  }

  snackBarMessage(message: string) {
    this.snackBar.open(message, "×", {
      panelClass: "success",
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  snackBarError(message: string) {
    this.snackBar.open(message, "×", {
      panelClass: "error",
      horizontalPosition: "right",
      verticalPosition: "top",
      duration: 3000,
    });
  }
}
