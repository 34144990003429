import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ApiService } from "src/app/services/api.service";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root",
})
export class ApiKeysService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  public fetchApiKeysTest(): any {
    //! Simulate an API call to get the Google Maps API key
    return {
      googleApiKey: "AI......40U", //? For testing only
    };
  }

  async fetchApiKeys(): Promise<any | Error> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.getKeyCall<any>("/auth/keys").subscribe({
        next: (resp: any) => {
          //console.log("Resp was: ", resp);
          if (resp.data) {
            //console.log("Inside fetchAPIKeys: ", resp);
            const
                baseDecryption = CryptoJS.AES.decrypt(resp.data, environment.apikDecryptionSecret).toString(CryptoJS.enc.Utf8),
                decryptedKeys = JSON.parse(baseDecryption);
            return resolve(decryptedKeys);
          } else {
            //console.log("Inside Else: ", resp);
            return reject(new Error("Failed to fetch keys"));
          }
        },
        error: (err) => {
          //console.error("Inside Error, About to Reject: ", err);
          return reject(new Error(err));
        },
      });
    });
  }
}
