import {Injectable} from "@angular/core";
import { StorageService } from "./storage.service";
import { LoggerService } from "./logger.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "./api.service";
import { SearchModel } from "../shared/models/search-model";

@Injectable({
    providedIn: 'root'
})

export class CookieManagementService {
    constructor(
        public snackBar: MatSnackBar,
        private storage: StorageService,
        private logger: LoggerService,
        private apiService: ApiService,
    ) {}

    onLoad_ValidateBrowserSetCookies(cookieVersions: { searchQueryCookieVersion: number }) {
        this.logger.logMsg("Let's Validate Some Cookies Using the Wrapper", null);
        this.validateSearchQueryCookie(cookieVersions.searchQueryCookieVersion);
    }

    validateSearchQueryCookie(searchQueryCookieVersion: number) {
        const searchQueryModel = this.apiService.getSearchState();
        try {
            this.logger.logMsg("SearchQueryCookie? ", searchQueryModel);
            this.logger.logMsg("Cookie Version? ", searchQueryModel.defaultMeta.cookieVersion);
            let cookieDNE = searchQueryModel.defaultMeta.cookieVersion === undefined,
                wrongCookieVersion = searchQueryModel.defaultMeta.cookieVersion !== searchQueryCookieVersion;
            this.logger.logMsg("Check? ", { cookieDNE, wrongCookieVersion });

            if(cookieDNE || wrongCookieVersion) {
                let newSearchModel = new SearchModel();
                this.logger.logMsg("To set: ", newSearchModel);
                this.apiService.setSearchState(newSearchModel);
            }
        } catch(e) {
            let newSearchModel = new SearchModel();
            this.logger.logMsg("To set: ", newSearchModel);
            this.apiService.setSearchState(newSearchModel);
        }
    }
}
