import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../../login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-verify-idme',
  templateUrl: './verify-idme.component.html',
  styleUrls: ['./verify-idme.component.scss']
})
export class VerifyIdmeComponent implements OnInit {
  idmeRegistrationLink: string | null = null;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    // Set the a tag href to the constructed idmeRegistrationLink
    this.idmeRegistrationLink = environment.idmeSettings.baseURL + "?";
    this.idmeRegistrationLink += `client_id=${environment.idmeSettings.clientId}&`;
    this.idmeRegistrationLink += `redirect_uri=${environment.idmeSettings.redirectURL}&`;
    this.idmeRegistrationLink += `response_type=${environment.idmeSettings.responseType}&`;
    this.idmeRegistrationLink += `scopes=${environment.idmeSettings.scopes}`
  }

  public openSignIn() {
    // Close the currently opened dialog
    this.dialog.closeAll();

    // Open Login Modal
    return this.dialog.open(LoginComponent, {
      maxWidth: "400px",
    });
  }
}
