export class CheckoutModel {
    user: any;
    initialQuery: any;
    property: any;
    los: number;

    constructor() {
        this.user = {};
        this.initialQuery = {};
        this.property = {};
        this.los = 0;
    }
}
