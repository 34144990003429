import { Injectable } from "@angular/core";
import { PropertyAddress } from "src/app/shared/models/property-model";
import { State, countriesList, usaStateAndTerritoryList, canadianProvinceList, ukTerritoryList } from "src/app/shared/models/stateList.model";

// https://stackoverflow.com/a/46761018/2251559
// https://stackoverflow.com/a/164994/2251559
const CANADA_POSTAL_REGEX = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const UK_POSTAL_REGEX =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
const USA_POSTAL_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;

@Injectable({
  providedIn: "root",
})
export class AddressParserService {
  constructor() {}

  private stripNumbers(inputString: string): string | null {
    if (!inputString || !inputString.length) return null;
    // Use regular expression to replace all numbers with an empty string
    return inputString.replace(/[0-9]/g, "");
  }

  private cleanAddressString(addressString: string): string {
    if (!addressString) return "";
    // Remove leading/trailing/double commas
    addressString = addressString.replace(/,,/g, "");
    addressString = addressString.replace(/, ,/g, "");
    addressString = addressString.replace(/^,*/, "");
    addressString = addressString.replace(/,\s*$/, "").trim();
    addressString = addressString.replace(/\s{2,}/g, " "); // remove double spaces
    return addressString;
  }

  private removeFromString(str: string, target: string): string {
    if (!str || !str.length) return "";
    if (!target || !target.length) return str;
    const regex = new RegExp(`\\b${target}\\b`, "i");
    if (regex.test(str)) {
      str = str.replace(regex, "");
    }

    str = this.cleanAddressString(str);
    return str;
  }

  private extractNumbers(str: string): string {
    if (!str || !str.length) return "";
    const numbers = str.match(/[\d,]+/g);
    return numbers ? numbers.join("").replace(/,/g, "") : "";
  }

  private toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, (t) => {
      return t.charAt(0).toUpperCase() + t.substring(1).toLowerCase();
    });
  };

  private findCountryInAddress(addressString: string): string | null {
    for (let i = countriesList.length - 1; i >= 0; i--) {
      const country = countriesList[i];
      // Don't confuse Ukraine with UK and vice versa
      if (addressString.toLowerCase().endsWith(country.toLowerCase())) {
        return country;
      }
    }
    return null;
  }

  /**
   * Extracts and returns a postal code from the given address string.
   * The method iterates through the parts of the address string, checking for potential postal codes.
   *
   * @param {string} addressString
   * @returns {string | null}
   */
  private getPostalCodeFromString(addressString: string): string | null {
    const parts = addressString.split(" ");

    // Loop through the parts array and examine multiple parts at once
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      let onlyNumbers = this.extractNumbers(part);
      let isUSAZipcode = USA_POSTAL_REGEX.test(onlyNumbers);
      if (isUSAZipcode) {
        return onlyNumbers;
      }

      const multipleParts = parts.slice(i, i + 2).join(" ");
      onlyNumbers = this.extractNumbers(multipleParts);
      isUSAZipcode = USA_POSTAL_REGEX.test(onlyNumbers);
      if (isUSAZipcode) {
        return onlyNumbers;
      }

      const isCanadaPostCode = CANADA_POSTAL_REGEX.test(multipleParts);
      if (isCanadaPostCode) {
        return multipleParts;
      }

      const isUKPostCode = UK_POSTAL_REGEX.test(multipleParts);
      if (isUKPostCode) {
        return multipleParts;
      }
    }
    return null;
  }

  private getStateNameFromCode(code: string): string | null {
    const state = usaStateAndTerritoryList.find((state) => state.code === code);
    return state ? state.name : null;
  }

  private getStateCodeFromName(name: string): string | null {
    const state = usaStateAndTerritoryList.find((state) => state.name === name);
    return state ? state.code : null;
  }

  /**
   * Extracts and returns the state or province from the given address string.
   * The method checks for USA states and territories, Canadian provinces, and UK territories.
   *
   * @param {string} addressString
   * @returns {{ state: string; country: "USA" | "UK" | "Canada" | null } | null}
   */
  private getStateFromString(addressString: string): { state: string; country: "USA" | "UK" | "Canada" | null } | null {
    // Check USA states and territories
    const usaState = usaStateAndTerritoryList.find(
      (state) => state.name.toLowerCase() === addressString.toLowerCase() || state.code.toLowerCase() === addressString.toLowerCase()
    );
    if (usaState) {
      return { state: usaState.code, country: "USA" };
    }

    // Check Canadian provinces
    const canadianProvince = canadianProvinceList.find(
      (state) => state.name.toLowerCase() === addressString.toLowerCase() || state.code.toLowerCase() === addressString.toLowerCase()
    );
    if (canadianProvince) {
      return { state: canadianProvince.code, country: "Canada" };
    }

    // Check UK territories
    const ukTerritory = ukTerritoryList.find(
      (state) => state.name.toLowerCase() === addressString.toLowerCase() || state.code.toLowerCase() === addressString.toLowerCase()
    );

    if (ukTerritory) {
      return { state: ukTerritory.code, country: "UK" };
    }

    return null;
  }

  /**
   * Parses the provided address string and attempts to extract city/state/country
   *
   * @param {string} addressString
   * @returns {PropertyAddress | null}
   */
  public parseAddress(addressString: string): PropertyAddress | null {
    if (!addressString || !addressString.length) {
      console.error("Invalid Address");
      return null;
    }

    let addressLine1 = null,
      city = null,
      state = null,
      stateCode = null,
      postalCode = null,
      country = null;

    try {
      addressString = this.cleanAddressString(addressString);

      const countryInAddress = this.findCountryInAddress(addressString);
      if (countryInAddress) {
        country = countryInAddress;
        addressString = this.removeFromString(addressString, country);
      }

      const zipInAddress = this.getPostalCodeFromString(addressString);
      if (zipInAddress) {
        postalCode = zipInAddress;
        addressString = this.removeFromString(addressString, postalCode);
      }

      const delimiter = addressString.includes(",") ? "," : " ";
      const parts = addressString.split(delimiter);
      for (const part of parts) {
        const noNumbersPart = this.stripNumbers(part);
        const cleanPart = this.cleanAddressString(noNumbersPart);
        const stateMatch = this.getStateFromString(cleanPart);

        if (stateMatch) {
          const lowerAddress = addressString.toLowerCase();

          // Don't assuming "Washington" state if "DC" is a part of the address
          if ((lowerAddress.includes(" dc") || lowerAddress.endsWith(", dc")) && stateMatch.state === "WA") {
            state = "DC";
            country = "USA";
          } else {
            const { state: matchingState, country: matchingCountry } = stateMatch;
            state = matchingState;
            country = matchingCountry;
          }

          addressString = this.removeFromString(addressString, state);
          addressString = this.removeFromString(addressString, country);
          break;
        }
      }

      if (state) {
        const stateName = this.getStateNameFromCode(state);
        const stateCodeInName = this.getStateCodeFromName(stateName);
        if (stateName) {
          stateCode = state;
          state = stateName;
        } else {
          stateCode = stateCodeInName;
        }
      }

      city = addressString;
      if (state) {
        city = this.removeFromString(city, state);
      }
      if (country) {
        city = this.removeFromString(city, country);
      }

      // Remove street address from city (if applicable)
      if (city.includes(",")) {
        const commaParts = city.split(",");
        city = commaParts[commaParts.length - 1].trim();
      }

      // Assume it's some other foreign city/country string if this address did not match USA/UK/Canada
      if (!state && parts.length >= 2) {
        city = parts[0];
        state = null;
        country = parts[1];
      }

      if (city) {
        city = this.stripNumbers(city);
      }
      city = this.cleanAddressString(city);

      if (city.toLowerCase() === city) {
        city = this.toTitleCase(city);
      }

      if (state) {
        state = this.stripNumbers(state);
      }
      state = this.cleanAddressString(state);
    } catch (err) {
      console.error(err);
    }

    return { addressLine1, city, state, stateCode, postalCode, country };
  }
}
