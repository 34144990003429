import { Component, Input } from "@angular/core";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent {
  @Input() customWidth: number = 256;

  getAspectRatioHeight(): number {
    // W:H aspect ratio of the SVG is 512/177
    const aspectRatio = 2.89;
    return this.customWidth / aspectRatio;
  }
}
