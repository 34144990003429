<div fxFlex="100" fxFlex.gt-sm="100" class="p-3" ngClass.sm="mt-2" ngClass.xs="mt-2" style="padding: 3rem;">
    <h2 class="text-muted text-center fw-600 mb-3">Password change</h2>
    <form [formGroup]="passwordForm" (ngSubmit)="onPasswordFormSubmit(passwordForm.value)">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Current Password</mat-label>
            <input matInput placeholder="Current Password" formControlName="currentPassword" type="password"
                   minlength="6" required autocomplete="off">
            <mat-error *ngIf="passwordForm.controls['currentPassword'].errors?.['required']">Current Password is required
            </mat-error>
            <mat-error *ngIf="passwordForm.controls['currentPassword'].hasError('minlength')">Current Password isn't
                long enough, minimum of 6 characters</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>New Password</mat-label>
            <input matInput placeholder="New Password" formControlName="newPassword" type="password" minlength="6"
                   required autocomplete="off">
            <mat-error *ngIf="passwordForm.controls['newPassword'].errors?.['required']">New Password is required
            </mat-error>
            <mat-error *ngIf="passwordForm.controls['newPassword'].hasError('minlength')">New Password isn't long
                enough, minimum of 6 characters</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Confirm New Password</mat-label>
            <input matInput placeholder="Confirm New Password" formControlName="confirmNewPassword" type="password"
                   required autocomplete="off">
            <mat-error *ngIf="passwordForm.controls['confirmNewPassword'].errors?.['required']">Confirm New Password is
                required</mat-error>
            <mat-error *ngIf="passwordForm.controls['confirmNewPassword'].hasError('mismatchedPasswords')">Passwords do
                not match</mat-error>
        </mat-form-field>
        <div class="text-center mt-2">
            <button mat-raised-button color="primary" type="submit" class="uppercase">Change password</button>
        </div>
    </form>
</div>
