import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {HeaderService} from "../../services/header.service";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  logoWidth: number = 256;

  constructor(
      private router: Router,
      private headerService: HeaderService
  ) {
    this.headerService.setCurrentPage('not-found');
  }

  navigateToHomePage(): void {
    this.router.navigateByUrl("/");
  }
}
