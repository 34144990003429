<section class="sign sign--forgot" id="sign">
    <div class="sign__container">
        <div class="sign__wrapper sign__wrapper--forgot">
            <div class="sign__wrapper-head">
                <div class="logo logo--sign"><a class="logo__btn" href="#"><img src="../../../../assets/images/logo.svg" alt=""></a></div>
                <h2 class="sign__title">Change Your Password</h2>
            </div>
            <div class="sign__wrapper-body">
                <div>
                    <form class="sign__form sign__form--login c-form" action="" method="post" [formGroup]="changePasswordForm" autocomplete="off">
                        <div>
                            <div class="c-form__field">
                                <input type="email" id="forgot-recovery-name" name="email" formControlName="email" value="{{this.resetFor}}" [readOnly]="true"
                                    style="padding: 0.75rem 0 0.75rem 3rem; font-size: 16px; background-color: rgba(0,0,0,0.1);"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                    <path
                                            d="M17.9167 15H2.08333C0.935 15 0 14.065 0 12.9167V2.08333C0 0.935 0.935 0 2.08333 0H17.9167C19.065 0 20 0.935 20 2.08333V12.9167C20 14.065 19.065 15 17.9167 15ZM2.08333 0.833333C1.39417 0.833333 0.833333 1.39417 0.833333 2.08333V12.9167C0.833333 13.6058 1.39417 14.1667 2.08333 14.1667H17.9167C18.6058 14.1667 19.1667 13.6058 19.1667 12.9167V2.08333C19.1667 1.39417 18.6058 0.833333 17.9167 0.833333H2.08333Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M10.0001 10.0244C9.15505 10.0244 8.36505 9.67107 7.77505 9.03107L0.645052 1.23524C0.490052 1.06524 0.501719 0.801908 0.671719 0.646075C0.840886 0.491075 1.10505 0.502741 1.26089 0.672741L8.39005 8.46774C9.24839 9.40108 10.7517 9.40191 11.6117 8.46691L18.7051 0.706075C18.8617 0.535241 19.1251 0.525241 19.2942 0.679408C19.4651 0.835241 19.4776 1.09774 19.3217 1.26857L12.2267 9.02941C11.6351 9.67107 10.8451 10.0244 10.0001 10.0244Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M0.931734 14.4855C0.819234 14.4855 0.7059 14.4397 0.624234 14.3505C0.4684 14.1797 0.4809 13.9163 0.6509 13.7613L7.17757 7.8005C7.34757 7.6455 7.61173 7.65633 7.76673 7.82717C7.92257 7.998 7.91007 8.26133 7.74007 8.41633L1.2134 14.3755C1.13257 14.4488 1.03173 14.4855 0.931734 14.4855Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M19.035 14.4516C18.9341 14.4516 18.835 14.4158 18.7541 14.3425L12.2616 8.41665C12.0916 8.26165 12.0791 7.99831 12.235 7.82831C12.3916 7.65748 12.655 7.64581 12.8241 7.80165L19.3166 13.7275C19.4858 13.8841 19.4991 14.1475 19.3425 14.3166C19.2591 14.4058 19.1474 14.4516 19.035 14.4516Z"
                                            fill="#243746"></path>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <label class="c-form__label" for="forgot-recovery-pass">
                                <h3 style="margin-bottom: .3rem;">New Password</h3>
                            </label>
                            <div class="c-form__field">
                                <input
                                        [attr.type]="isPasswordVisible ? 'text' : 'password'"
                                        type="password" id="forgot-recovery-pass" name="password" formControlName="password" placeholder="******" required
                                        style="padding: 0.75rem 0 0.75rem 3rem; font-size: 16px;"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="none">
                                    <path
                                            d="M12.8889 21.3333H3.11111C1.39556 21.3333 0 19.9378 0 18.2222V12.8889C0 11.1733 1.39556 9.77778 3.11111 9.77778H12.8889C14.6044 9.77778 16 11.1733 16 12.8889V18.2222C16 19.9378 14.6044 21.3333 12.8889 21.3333ZM3.11111 10.6667C1.88622 10.6667 0.888889 11.6631 0.888889 12.8889V18.2222C0.888889 19.448 1.88622 20.4444 3.11111 20.4444H12.8889C14.1138 20.4444 15.1111 19.448 15.1111 18.2222V12.8889C15.1111 11.6631 14.1138 10.6667 12.8889 10.6667H3.11111Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M12.8889 10.2222C12.6445 10.2222 12.4445 10.0222 12.4445 9.77778V4.13689C12.4445 2.22311 10.2516 0.888889 8.28358 0.888889H7.71558C5.74758 0.888889 3.55469 2.22311 3.55469 4.13689V9.77778C3.55469 10.0222 3.35558 10.2222 3.11025 10.2222C2.86491 10.2222 2.6658 10.0222 2.6658 9.77778V4.13689C2.6658 1.66222 5.27736 0 7.71558 0H8.28358C10.7227 0 13.3334 1.66222 13.3334 4.13689V9.77778C13.3334 10.0222 13.1334 10.2222 12.8889 10.2222Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M8.44444 18.6667H7.55555C6.75377 18.6667 6.22222 17.5964 6.22222 16.8889V15.5431C5.93422 15.1582 5.77777 14.696 5.77777 14.2222C5.77777 12.9964 6.7751 12 7.99999 12C9.22488 12 10.2222 12.9964 10.2222 14.2222C10.2222 14.696 10.0658 15.16 9.77777 15.5431V16.8889C9.77777 17.5964 9.24622 18.6667 8.44444 18.6667ZM7.99999 12.8889C7.26488 12.8889 6.66666 13.4871 6.66666 14.2222C6.66666 14.6276 6.84888 14.92 7.00088 15.0933C7.07199 15.1751 7.1111 15.2791 7.1111 15.3867V16.8889C7.1111 17.2533 7.41333 17.7591 7.55644 17.7778H8.44355C8.58755 17.7591 8.88888 17.2533 8.88888 16.8889V15.3876C8.88888 15.28 8.92799 15.1796 8.9991 15.0978C9.15199 14.9244 9.33333 14.632 9.33333 14.2267C9.33333 13.4871 8.7351 12.8889 7.99999 12.8889Z"
                                            fill="#243746"></path>
                                </svg>
                                <svg
                                        (click)="togglePasswordVisibility()"
                                        [ngClass]="{ active: isPasswordVisible }"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none">
                                    <path
                                            d="M11.9713 15.1992C9.25798 15.1992 7.05554 12.9905 7.05554 10.2695C7.05554 7.54839 9.25798 5.33966 11.9713 5.33966C14.6846 5.33966 16.8871 7.54839 16.8871 10.2695C16.8871 12.9905 14.6846 15.1992 11.9713 15.1992ZM11.9713 7.04744C10.2003 7.04744 8.75846 8.49336 8.75846 10.2695C8.75846 12.0455 10.2003 13.4915 11.9713 13.4915C13.7423 13.4915 15.1841 12.0455 15.1841 10.2695C15.1841 8.49336 13.7423 7.04744 11.9713 7.04744Z"
                                            fill="#757575"></path>
                                    <path
                                            d="M11.9715 20.5389C7.70285 20.5389 3.67262 18.0342 0.902544 13.685C-0.300848 11.8065 -0.300848 8.74383 0.902544 6.85389C3.68397 2.50474 7.7142 0 11.9715 0C16.2288 0 20.259 2.50474 23.0291 6.85389C24.2325 8.73245 24.2325 11.7951 23.0291 13.685C20.259 18.0342 16.2288 20.5389 11.9715 20.5389ZM11.9715 1.70778C8.30454 1.70778 4.79654 3.91651 2.34434 7.77609C1.49289 9.10816 1.49289 11.4307 2.34434 12.7628C4.79654 16.6224 8.30454 18.8311 11.9715 18.8311C15.6384 18.8311 19.1464 16.6224 21.5986 12.7628C22.4501 11.4307 22.4501 9.10816 21.5986 7.77609C19.1464 3.91651 15.6384 1.70778 11.9715 1.70778Z"
                                            fill="#757575"></path>
                                </svg>
                            </div>

                            <div *ngIf="invalidPwdLength" class="error-message">{{errorMessage}}</div>
                            <div *ngIf="invalidAlphaNumChar" class="error-message">{{errorMessage}}</div>
                            <div *ngIf="invalidCombo" class="error-message">{{errorMessage}}</div>
                            <div *ngIf="invalidFullNumeric" class="error-message">{{errorMessage}}</div>
                        </div>
                        <div>
                            <label class="c-form__label" for="forgot-recovery-pass-re">
                                <h3 style="margin-bottom: .3rem;">Confirm New Password</h3>
                            </label>
                            <div class="c-form__field">
                                <input
                                        [attr.type]="isRePasswordVisible ? 'text' : 'password'"
                                        formControlName="repassword"
                                        type="password" id="forgot-recovery-pass-re" name="repassword" placeholder="******" required
                                        style="padding: 0.75rem 0 0.75rem 3rem; font-size: 16px;"
                                >
                                <svg
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="none">
                                    <path
                                            d="M12.8889 21.3333H3.11111C1.39556 21.3333 0 19.9378 0 18.2222V12.8889C0 11.1733 1.39556 9.77778 3.11111 9.77778H12.8889C14.6044 9.77778 16 11.1733 16 12.8889V18.2222C16 19.9378 14.6044 21.3333 12.8889 21.3333ZM3.11111 10.6667C1.88622 10.6667 0.888889 11.6631 0.888889 12.8889V18.2222C0.888889 19.448 1.88622 20.4444 3.11111 20.4444H12.8889C14.1138 20.4444 15.1111 19.448 15.1111 18.2222V12.8889C15.1111 11.6631 14.1138 10.6667 12.8889 10.6667H3.11111Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M12.8889 10.2222C12.6445 10.2222 12.4445 10.0222 12.4445 9.77778V4.13689C12.4445 2.22311 10.2516 0.888889 8.28358 0.888889H7.71558C5.74758 0.888889 3.55469 2.22311 3.55469 4.13689V9.77778C3.55469 10.0222 3.35558 10.2222 3.11025 10.2222C2.86491 10.2222 2.6658 10.0222 2.6658 9.77778V4.13689C2.6658 1.66222 5.27736 0 7.71558 0H8.28358C10.7227 0 13.3334 1.66222 13.3334 4.13689V9.77778C13.3334 10.0222 13.1334 10.2222 12.8889 10.2222Z"
                                            fill="#243746"></path>
                                    <path
                                            d="M8.44444 18.6667H7.55555C6.75377 18.6667 6.22222 17.5964 6.22222 16.8889V15.5431C5.93422 15.1582 5.77777 14.696 5.77777 14.2222C5.77777 12.9964 6.7751 12 7.99999 12C9.22488 12 10.2222 12.9964 10.2222 14.2222C10.2222 14.696 10.0658 15.16 9.77777 15.5431V16.8889C9.77777 17.5964 9.24622 18.6667 8.44444 18.6667ZM7.99999 12.8889C7.26488 12.8889 6.66666 13.4871 6.66666 14.2222C6.66666 14.6276 6.84888 14.92 7.00088 15.0933C7.07199 15.1751 7.1111 15.2791 7.1111 15.3867V16.8889C7.1111 17.2533 7.41333 17.7591 7.55644 17.7778H8.44355C8.58755 17.7591 8.88888 17.2533 8.88888 16.8889V15.3876C8.88888 15.28 8.92799 15.1796 8.9991 15.0978C9.15199 14.9244 9.33333 14.632 9.33333 14.2267C9.33333 13.4871 8.7351 12.8889 7.99999 12.8889Z"
                                            fill="#243746"></path>
                                </svg>
                                <svg
                                        (click)="toggleRePasswordVisibility()"
                                        [ngClass]="{ active: isRePasswordVisible }"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none">
                                    <path
                                            d="M11.9713 15.1992C9.25798 15.1992 7.05554 12.9905 7.05554 10.2695C7.05554 7.54839 9.25798 5.33966 11.9713 5.33966C14.6846 5.33966 16.8871 7.54839 16.8871 10.2695C16.8871 12.9905 14.6846 15.1992 11.9713 15.1992ZM11.9713 7.04744C10.2003 7.04744 8.75846 8.49336 8.75846 10.2695C8.75846 12.0455 10.2003 13.4915 11.9713 13.4915C13.7423 13.4915 15.1841 12.0455 15.1841 10.2695C15.1841 8.49336 13.7423 7.04744 11.9713 7.04744Z"
                                            fill="#757575"></path>
                                    <path
                                            d="M11.9715 20.5389C7.70285 20.5389 3.67262 18.0342 0.902544 13.685C-0.300848 11.8065 -0.300848 8.74383 0.902544 6.85389C3.68397 2.50474 7.7142 0 11.9715 0C16.2288 0 20.259 2.50474 23.0291 6.85389C24.2325 8.73245 24.2325 11.7951 23.0291 13.685C20.259 18.0342 16.2288 20.5389 11.9715 20.5389ZM11.9715 1.70778C8.30454 1.70778 4.79654 3.91651 2.34434 7.77609C1.49289 9.10816 1.49289 11.4307 2.34434 12.7628C4.79654 16.6224 8.30454 18.8311 11.9715 18.8311C15.6384 18.8311 19.1464 16.6224 21.5986 12.7628C22.4501 11.4307 22.4501 9.10816 21.5986 7.77609C19.1464 3.91651 15.6384 1.70778 11.9715 1.70778Z"
                                            fill="#757575"></path>
                                </svg>
                            </div>
                        </div>

                        <div *ngIf="passwordMismatch">
                            <p class="error-message">Passwords do not match.</p>
                        </div>

                    </form>
                </div>
                <div>
                    <ul class="sign__list">
                        <li>Minimum 6 characters;</li>
                        <li>Maximum 20 characters;</li>
                        <li>Case sensitive;</li>
                        <li>Cannot be the same as the username;</li>
                        <li>Cannot be the same as the current password;</li>
                        <li>Alpha, numeric and special characters required;</li>
                        <li>Minimum of 1 upper case, 1 lower case, 1 numeric;</li>
                        <!-- <li>Special characters allowed are ! # $ % ( * ) + , - / ; = ? [ \ ] ^ _ ' { };</li> -->
                        <li>Cannot be any 9 numeric digits in a row.</li>
                    </ul>
                </div>
            </div>
            <div class="sign__wrapper-footer" style="display: flex; flex-direction: row; justify-content: space-evenly;">
                <button class="c-btn c-btn--big c-btn--transparent" data-btn-transform="upper"
                    style="text-align: center;"
                    type="button" mat-button
                >
                    Cancel
                </button>
                <button class="c-btn c-btn--big Primary-color" (click)="changePassword($event)" data-btn-transform="upper"
                   style="text-align: center; color: #00A6CE; background-color: rgba(0,0,0,0.1);"
                   type="button" mat-button
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</section>
