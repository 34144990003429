export class State {
  constructor(public id: number, public name: string, public code: string) {}
}

export const usaStateAndTerritoryList: State[] = [
  { id: 0, name: "Alabama", code: "AL" },
  { id: 1, name: "Alaska", code: "AK" },
  { id: 2, name: "Arizona", code: "AZ" },
  { id: 3, name: "Arkansas", code: "AR" },
  { id: 4, name: "California", code: "CA" },
  { id: 5, name: "Colorado", code: "CO" },
  { id: 6, name: "Connecticut", code: "CT" },
  { id: 7, name: "Delaware", code: "DE" },
  { id: 8, name: "Florida", code: "FL" },
  { id: 9, name: "Georgia", code: "GA" },
  { id: 10, name: "Hawaii", code: "HI" },
  { id: 11, name: "Idaho", code: "ID" },
  { id: 12, name: "Illinois", code: "IL" },
  { id: 13, name: "Indiana", code: "IN" },
  { id: 14, name: "Iowa", code: "IA" },
  { id: 15, name: "Kansas", code: "KS" },
  { id: 16, name: "Kentucky", code: "KY" },
  { id: 17, name: "Louisiana", code: "LA" },
  { id: 18, name: "Maine", code: "ME" },
  { id: 19, name: "Maryland", code: "MD" },
  { id: 20, name: "Massachusetts", code: "MA" },
  { id: 21, name: "Michigan", code: "MI" },
  { id: 22, name: "Minnesota", code: "MN" },
  { id: 23, name: "Mississippi", code: "MS" },
  { id: 24, name: "Missouri", code: "MO" },
  { id: 25, name: "Montana", code: "MT" },
  { id: 26, name: "Nebraska", code: "NE" },
  { id: 27, name: "Nevada", code: "NV" },
  { id: 28, name: "New Hampshire", code: "NH" },
  { id: 29, name: "New Jersey", code: "NJ" },
  { id: 30, name: "New Mexico", code: "NM" },
  { id: 31, name: "New York", code: "NY" },
  { id: 32, name: "North Carolina", code: "NC" },
  { id: 33, name: "North Dakota", code: "ND" },
  { id: 34, name: "Ohio", code: "OH" },
  { id: 35, name: "Oklahoma", code: "OK" },
  { id: 36, name: "Oregon", code: "OR" },
  { id: 37, name: "Pennsylvania", code: "PA" },
  { id: 38, name: "Rhode Island", code: "RI" },
  { id: 39, name: "South Carolina", code: "SC" },
  { id: 40, name: "South Dakota", code: "SD" },
  { id: 41, name: "Tennessee", code: "TN" },
  { id: 42, name: "Texas", code: "TX" },
  { id: 43, name: "Utah", code: "UT" },
  { id: 44, name: "Vermont", code: "VT" },
  { id: 45, name: "Virginia", code: "VA" },
  { id: 46, name: "Washington", code: "WA" },
  { id: 47, name: "West Virginia", code: "WV" },
  { id: 48, name: "Wisconsin", code: "WI" },
  { id: 49, name: "Wyoming", code: "WY" },
  { id: 50, name: "District of Columbia", code: "DC" },
  { id: 51, name: "American Samoa", code: "AS" },
  { id: 52, name: "Guam", code: "GU" },
  { id: 53, name: "Northern Mariana Islands", code: "MP" },
  { id: 54, name: "Puerto Rico", code: "PR" },
  { id: 55, name: "Virgin Islands", code: "VI" },
];

// Canadian Provinces
export const canadianProvinceList: State[] = [
  { id: 0, name: "Alberta", code: "AB" },
  { id: 1, name: "British Columbia", code: "BC" },
  { id: 2, name: "Manitoba", code: "MB" },
  { id: 3, name: "New Brunswick", code: "NB" },
  { id: 4, name: "Newfoundland and Labrador", code: "NL" },
  { id: 5, name: "Northwest Territories", code: "NT" },
  { id: 6, name: "Nova Scotia", code: "NS" },
  { id: 7, name: "Nunavut", code: "NU" },
  { id: 8, name: "Ontario", code: "ON" },
  { id: 9, name: "Prince Edward Island", code: "PE" },
  { id: 10, name: "Quebec", code: "QC" },
  { id: 11, name: "Saskatchewan", code: "SK" },
  { id: 12, name: "Yukon", code: "YT" },
];

// UK Territories
export const ukTerritoryList: State[] = [
  { id: 0, name: "England", code: "England" }, // or 'ENG'
  { id: 1, name: "Scotland", code: "Scotland" }, // or 'SCO'
  { id: 2, name: "Wales", code: "Wales" }, // or 'WLS'
  { id: 3, name: "Northern Ireland", code: "Northern Ireland" }, // or 'NIR'
];

export const countriesList: string[] = [
  "United States",
  "USA",
  "United States of America",
  "China",
  "Japan",
  "Germany",
  "India",
  "England",
  "United Kingdom",
  "UK",
  "France",
  "Russia",
  "Canada",
  "Italy",
  "Brazil",
  "Australia",
  "South Korea",
  "Mexico",
  "Spain",
  "Indonesia",
  "Saudi Arabia",
  "Netherlands",
  "Turkey",
  "Switzerland",
  "Poland",
  "Argentina",
  "Sweden",
  "Norway",
  "Belgium",
  "Ireland",
  "Israel",
  "United Arab Emirates",
  "UAE",
  "Thailand",
  "Nigeria",
  "Egypt",
  "Austria",
  "Singapore",
  "Bangladesh",
  "Vietnam",
  "Malaysia",
  "South Africa",
  "Philippines",
  "Denmark",
  "Iran",
  "Pakistan",
  "Hong Kong",
  "Colombia",
  "Romania",
  "Chile",
  "Czech Republic",
  "Finland",
  "Iraq",
  "Portugal",
  "New Zealand",
  "Peru",
  "Qatar",
  "Kazakhstan",
  "Greece",
  "Algeria",
  "Kuwait",
  "Hungary",
  "Ukraine",
  "Morocco",
];
