<div class="p-3">
    <div>
        <mat-sidenav-container class="account">
            <mat-sidenav *ngIf="profile" #sidenav [opened]="sidenavOpen" [mode]="sidenavOpen ? 'side' : 'over'" class="account-sidenav">
                <!--[perfectScrollbar]="psConfig"-->
                <mat-card>
                    <!--<button fxHide="false" fxHide.gt-sm mat-mini-fab color="warn" class="close" (click)="sidenav.toggle()">
                        <mat-icon>close</mat-icon>
                    </button>-->
                    <mat-card-header *ngIf="profile" style="display: flex; justify-content: start; align-items: center;">
                        <img mat-card-avatar src="{{this.imagePreview}}" alt="">
                        <mat-card-title class="text-muted mb-0">{{profile?.first_name}} {{profile?.last_name}}</mat-card-title>
                    </mat-card-header>
                    <div class="divider my-3"></div>
                    <div *ngFor="let link of links">
                        <a matLine
                           [routerLink]="link.href"
                           routerLinkActive="horizontal-active-link"
                           [routerLinkActiveOptions]="{exact:true}"
                           style="font-size: 16px; width: 100%; display: flex; text-decoration: none; margin: 10px 0;"
                        >
                            <mat-icon class="text-muted">{{ link.name === 'Reservations' ? 'date_range' : link.icon }}</mat-icon>
                            <span class="mx-2" style="margin-left: 10px;">{{ link.name }}</span>
                        </a>
                    </div>
                </mat-card>
            </mat-sidenav>
            <mat-sidenav-content ngClass.gt-sm="distance">
                <mat-card *ngIf="!sidenavOpen" fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mb-3">
                    <button mat-icon-button (click)="sidenav.toggle()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <h3> My Account</h3>
                </mat-card>
                <mat-card class="account-wrapper">
                    <router-outlet></router-outlet>
                </mat-card>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
