<div *ngIf="pageLoading" style="width: 100%; margin: 10% 50%;">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!pageLoading" style="padding-left: 1rem; margin-bottom: 1rem;">
  <div class="theme-container">
    <mat-sidenav-container class="single-property">
      <mat-sidenav-content  style="overflow-y: hidden;">
        <mat-card style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
          <div>
            <h2 class="uppercase" ngClass.xs="text-center"
              style="margin: 2rem 0 0rem 1rem;"
            >
              {{property?.propertyDetails.title}}
            </h2>
            <div fxLayoutAlign.xs="center" class="address mb-0" style="display: flex; margin-left: 1rem;">
              <mat-icon class="text-muted">location_on</mat-icon>
              <span>{{property?.formattedAddress}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign.xs="center center" style="margin-right: 1rem;">
            <h2 *ngIf="settings.currency == 'USD'" class="primary-color price">
              <span *ngIf="searchState.filters.typeOfTravel.vacation">
                ${{property?.price.priceScale.baseRate}} USD per night + Taxes/Fees
              </span>
              <span *ngIf="searchState.filters.typeOfTravel.government && searchState.filters.typeOfGovernmentTravel.tdy">
                ${{property?.price.priceScale.federalRate}} USD per night + Taxes/Fees
              </span>
              <span *ngIf="searchState.filters.typeOfTravel.government && searchState.filters.typeOfGovernmentTravel.pcs">
                {{property?.price.priceScale.maxTLEForPCS}} USD per night + Taxes/Fees
              </span>
            </h2>
            <!--<app-rating [ratingsCount]="property?.ratingsCount" [ratingsValue]="property?.ratingsValue"></app-rating>-->
          </div>
          <!--(click)="sidenav.toggle()"-->
          <button *ngIf="!sidenavOpen" mat-icon-button >
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-card>
        <!-- Swiper Image GALLERY -->
        <mat-card *ngIf="imageGallery.length > 0">
          <div>
            <div class="galleryRow">
              <main class="igallery-col-8 igallery-px-0">
                <!-- Image -->
                <div style="height: 300px; width: 24%; margin: 0 auto;">
                <swiper-container
                        #swiper
                        auto-height="true"
                        navigation="true"
                        slides-per-view="1"
                        centered-slides="true"
                        style="padding: 2.5rem;"
                >
                    <swiper-slide *ngFor="let item of imageGallery" style="height: 200px;">
                      <img
                              class="main-carousel__img"
                              [ngSrc]="item.url" alt="{{item.alt}}"
                              fill
                              priority
                      />
                    </swiper-slide>
                </swiper-container>
                </div>
                <!-- /image -->
                <!-- thumbnails -->
                <div>
                  <swiper-container
                          pagination="{{imageGallery.length > 4}}" navigation="{{imageGallery.length > 4}}"
                          slides-per-view="{{imageGallery.length > 4 ? '4' : imageGallery.length}}" speed="500"
                  >
                      <swiper-slide *ngFor="let item of imageGallery; index as i">
                        <img
                                [ngSrc]="item.url" alt="{{item.alt}}"
                                width="150" height="200"
                                (click)="updateMainPhoto(i)"
                                priority
                        />
                      </swiper-slide>
                  </swiper-container>
                  <!-- /thumbnails -->
                </div>
              </main>
            </div>
          </div>
        </mat-card>
        <div style="display: flex; flex-direction: row;">
          <div class="property-details">
            <!-- DISTANCE SCORES CARD -->
            <mat-card *ngIf="property">
              <mat-card-header fxLayoutAlign="center center">
                <mat-card-title class="uppercase">WalkScore Ratings</mat-card-title>
              </mat-card-header>
              <div fxLayout="row" fxLayout.xs="column">
                <div class="property-details" style="width: 100%; margin-bottom: 2rem;">
                  <div class="details" style="display: flex; width: 100%; justify-content: space-evenly;">
                    <div style="display: flex; flex-direction: column;">
                      <div fxLayout="row wrap" style="align-items: center;">
                        <span>
                          <mat-icon class="mat-icon" color="primary">directions_walk</mat-icon>
                        </span>
                        <div style="display: flex; flex-direction: column;">
                          <span class="text-size" style="text-align: center;">{{property?.walkscoreRatings.walkingDistance}}</span>
                          <span>Walk Score</span>
                        </div>
                      </div>
                      <div fxLayout="column">
                        <span style="text-align: center;">OUT OF 100</span>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: column;" *ngIf="property?.walkscoreRatings.driveDistance !== null">
                      <div fxLayout="row wrap" style="align-items: center;">
                        <span>
                          <mat-icon class="mat-icon" color="primary">directions_car_filled</mat-icon>
                        </span>
                        <div style="display: flex; flex-direction: column;">
                          <span class="text-size" style="text-align: center;">{{property?.walkscoreRatings.driveDistance}}</span>
                          <span>Driver Score</span>
                        </div>
                      </div>
                      <div fxLayout="column">
                        <span style="text-align: center;">OUT OF 100</span>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: column;" *ngIf="property?.walkscoreRatings.bikeDistance !== null">
                      <div fxLayout="row wrap" style="align-items: center;">
                        <span>
                          <mat-icon class="mat-icon" color="primary">directions_bike</mat-icon>
                        </span>
                        <div style="display: flex; flex-direction: column;">
                          <span class="text-size" style="text-align: center;">{{property?.walkscoreRatings.bikeDistance}}</span>
                          <span>Biking Score</span>
                        </div>
                      </div>
                      <div fxLayout="column">
                        <span style="text-align: center;">OUT OF 100</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
            <!-- DETAILS CARD -->
            <mat-card *ngIf="property" class="mt-3">
              <mat-card-header fxLayoutAlign="center center">
                <mat-card-title class="uppercase">Details</mat-card-title>
              </mat-card-header>
              <div style="display: flex; flex-direction: row; margin: 1rem 0 2rem 1rem;">
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;">
                      <span style="margin-right: 5px;">
                        <mat-icon class="mat-icon" color="primary">house</mat-icon>
                      </span>
                    <span>Type: {{property?.propertyDetails.propertyType}}</span>
                  </div>
                  <div class="item"  style="margin-right: 1rem; display: flex; align-items: center;">
                      <span>
                        <mat-icon class="mat-icon" color="primary">bedtime</mat-icon>
                      </span>
                    <span>Min. {{property?.propertyDetails.minimumNightStay}} night stay</span>
                  </div>
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;"
                       *ngIf="property?.fullKitchen"
                  >
                      <span>
                        <mat-icon class="mat-icon" color="primary">kitchen</mat-icon>
                      </span>
                    <span>Fully equipped kitchen</span>
                  </div>
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;">
                      <span style="margin-right: 5px;">
                        <mat-icon class="mat-icon" color="primary">king_bed</mat-icon>
                      </span>
                    <span>{{property?.propertyDetails.bedrooms}} Bedrooms</span>
                  </div>
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;"
                       *ngIf="property?.parking"
                  >
                      <span>
                        <mat-icon class="mat-icon" color="primary">local_parking</mat-icon>
                      </span>
                    <div fxLayout="row wrap" class="list">
                      <span>Onsite parking</span>
                    </div>
                  </div>
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;">
                      <span style="margin-right: 5px;">
                        <mat-icon class="mat-icon" color="primary">bathtub</mat-icon>
                      </span>
                    <span>{{property?.propertyDetails.bathrooms}} Bathrooms</span>
                  </div>
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;"
                       *ngIf="property?.onsiteLaundry"
                  >
                      <span>
                        <mat-icon class="mat-icon" color="primary">local_laundry_service</mat-icon>
                      </span>
                    <span>Onsite laundry</span>
                  </div>
                  <div class="item" style="margin-right: 1rem; display: flex; align-items: center;"
                       *ngIf="property?.petFriendly"
                  >
                      <span>
                        <mat-icon class="mat-icon" color="primary">pets</mat-icon>
                      </span>
                    <span>Pet-friendly</span>
                  </div>
                  <!--<div fxFlex="100" fxFlex.gt-xs="33" fxLayout="row" class="item">
                    <span>
                      <mat-icon class="mat-icon" color="primary">smoke_free</mat-icon>
                    </span>
                    <span>Smoke-free premises</span>
                  </div>-->
                </div>
              </div>
            </mat-card>
            <!-- DESCRIPTION CARD -->
            <mat-card *ngIf="property" class="mt-3">
              <mat-card-header fxLayoutAlign="center center">
                <mat-card-title class="uppercase">Description</mat-card-title>
              </mat-card-header>
              <div class="details" style="display: flex; flex-wrap: wrap; margin-left: 1rem;">
                <p style="margin-right: 2rem;">{{property?.propertyDetails?.description}}</p>
              </div>
            </mat-card>
            <!-- NEARBY LOCATIONS CARD -->
            <mat-card class="mt-3">
              <mat-card-header fxLayoutAlign="center center">
                <mat-card-title class="uppercase">Nearby Places</mat-card-title>
              </mat-card-header>
              <div style="display: flex; flex-flow: row wrap;">
                <ng-container *ngFor="let amenity of this.nearbyPlaces">
                 <!-- {{printToConsole(amenity)}}-->
                  <div *ngIf="amenity.locations.length > 0"
                       fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="100" class="customSizerLocations"
                  >
                    <mat-card-header>
                      <mat-card-title class="">
                        <img ngOptimizedImage [src]="amenity.icon" class="icon-size" alt="" />
                        {{ amenity.label }}
                      </mat-card-title>
                    </mat-card-header>
                    <div class="h-100"
                         style="display: flex; flex-direction: column; justify-content: start; align-items: stretch;">
                      <mat-card-content class="mb-0">
                        <div
                                *ngFor="let location of amenity.locations"
                                class="mb-3 p-2"
                                style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center;"
                        >
                          <span class="mx-2">{{ location.name }}</span>
                          <span class="mx-2">{{ location.distanceMatrix.distance.distanceFromInMiles }}</span>
                        </div>
                      </mat-card-content>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-card>
          </div>
          <div
               style="padding-left: 10px; width: 125%;"
          >
            <mat-card>
              <div class="widget">
                <mat-card fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" style="padding: 1rem;">
                  <div>
                    <h4>
                      <span class="text-muted">Estimated Travel Cost For</span>
                    </h4>
                    <h5>
                      {{travelDescriptor}}
                    </h5>
                    <h5>
                      Length of Stay: {{los}}
                    </h5>
                    <h5>
                      Guests: {{guestDescription}}
                    </h5>
                    <div style="margin: 10px 0;">
                      <hr/>
                    </div>
                    <h2 style="margin-top: 5px;" class="primary-color price">
                      <span>
                        {{searchState.filters.typeOfTravel.vacation ?
                              softQuoteForVacation :
                              searchState.filters.typeOfTravel.government && searchState.filters.typeOfGovernmentTravel.tdy ?
                                      softQuoteForGov : softQuoteForPCS
                        }} + Taxes / Fees
                      </span>
                    </h2>
                    <b>
                      <small>*Taxes & Fees Calculated At Checkout</small>
                    </b>
                  </div>
                </mat-card>
                <form [formGroup]="ReservationForm" (ngSubmit)="onBookReservationSubmit(ReservationForm.value)">
                  <!--<div fxLayout="row" class="mt-5" fxLayout.xs="column" style="padding: 1rem;">
                    <mat-form-field color="accent" class="w-50">
                      <mat-label>Check in</mat-label>
                      <input
                              #pickerInInput
                              matInput
                              style="width: 100%!important;"
                              [matDatepicker]="checkInDate"
                              formControlName="checkInDate"
                              [disabled]="true"
                      >
                      <mat-datepicker-toggle matIconSuffix [for]="checkInDate"></mat-datepicker-toggle>
                      <mat-datepicker #checkInDate></mat-datepicker>
                    </mat-form-field>
                    <div class="date-gap"></div>
                    <mat-form-field color="accent" class="w-50">
                      <mat-label>Check out</mat-label>
                      <input
                              #pickerOutInput
                              matInput
                              style="width: 100%!important;"
                              [matDatepicker]="checkOutDate"
                              formControlName="checkOutDate"
                              [disabled]="true"
                      >
                      <mat-datepicker-toggle matIconSuffix [for]="checkOutDate"></mat-datepicker-toggle>
                      <mat-datepicker #checkOutDate color="primary"></mat-datepicker>
                    </mat-form-field>
                  </div>-->
                  <!--<mat-form-field appearance="outline" class="w-100">
                    <mat-label>Guests</mat-label>
                    <input matInput type="text" formControlName="guests">
                    &lt;!&ndash; <mat-select [formControl]="guests" multiple formControlName="guests">
                        <mat-option *ngFor="let guests of GuestsList" [value]="guests">{{guests}}</mat-option>
                      </mat-select> &ndash;&gt;
                  </mat-form-field>-->
                  <div class="text-center" style="padding: 1rem;">
                    <button mat-raised-button class="primary-app-color" type="submit">Book Now</button>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<!-- Pagination CARD -->
<!--<mat-card-content>
  <div fxLayout="row wrap" class="mb-3">
    <span>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
    </span>
  </div>
</mat-card-content>-->
<!--<div fxLayout="column" fxLayoutAlign.xs="center center" class="mx-3">
                    <app-rating [ratingsCount]="property?.ratingsCount"
                      [ratingsValue]="property?.ratingsValue"></app-rating>
                  </div>-->
<!--<mat-list class="reviews mt-3">
              <mat-list-item *ngFor="let review of reviews" class="h-100">
                <img matListAvatar [src]="review.avatar" alt="image" class="review-author">
                <p matLine fxLayoutAlign="start center">
                  <span class="fw-500">{{review.author}}</span>
                  <mat-icon class="text-muted px-1" [matTooltip]="review.tooltip"
                    matTooltipPosition="after">{{review.icon}}</mat-icon>
                </p>
                <p matLine class="text-muted">
                  <small>{{review.date}}</small>
                </p>
                <p matLine class="text">{{review.text}}</p>
              </mat-list-item>
            </mat-list>-->
<!-- thumbnails -->
<!--<swiper-container appSwiper #swiperThumbs [config]="swiperThumbsConfig" class="mySwiper2" *ngIf="imageGallery.length > 1" style="height: 50%; width: 100%;">
  <swiper-slide *ngFor="let item of imageGallery" style="width: 25%;">
    <div class="ratio ratio-4x3" role="button" [ngStyle]="{background: 'url(' + item.url +') center / cover'}" style="height: 100%; width: 25%;"></div>
  </swiper-slide>
</swiper-container>-->
<!-- /thumbnails -->
