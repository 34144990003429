import { Component, ElementRef, ViewChild, AfterViewInit, Input, ChangeDetectorRef } from "@angular/core";
import { LabelledRoute } from "src/app/shared/models/menu-model";
import { ROUTES } from "src/app/constants/routes.constants";
interface LinkColumn {
  colHeader: string;
  links: LabelledRoute[];
}

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements AfterViewInit {
  @ViewChild("footer", { static: true }) footer!: ElementRef;
  @Input() whiteTheme: boolean = true;

  customLogoWidth: number = 150;
  logoColor: string = "white";
  backgroundColor: string = "white";
  iconClass: string = "white-icon";

  COLUMN_LINKS: LinkColumn[] = [
    {
      colHeader: "Company",
      links: [
        { label: "Our Story", route: ROUTES.OUR_STORY },
        { label: "Contact Us", route: ROUTES.CONTACT },
        { label: "Investor Relations", route: ROUTES.INVESTOR_RELATIONS },
        { label: "Press", route: ROUTES.PRESS },
      ],
    },
    {
      colHeader: "Policies",
      links: [
        { label: "Privacy Policy", route: ROUTES.PRIVACY },
        { label: "Terms and Conditions", route: ROUTES.TERMS_AND_CONDITIONS },
      ],
    },
    {
      colHeader: "Resources",
      links: [
        // { label: "FAQ", route: ROUTES.FAQ },
        { label: "How it Works", route: ROUTES.HOW_IT_WORKS },
      ],
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.applyStyles();
  }

  ngOnChanges(): void {
    this.applyStyles();
  }

  private applyStyles(): void {
    if (!this.whiteTheme) {
      this.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue("--atease-dark-blue");
      this.logoColor = "white";
    } else {
      this.iconClass = "black-icon";
    }

    if (this.footer?.nativeElement) {
      this.footer.nativeElement.style.backgroundColor = this.backgroundColor;
    }

    // Trigger change detection manually
    this.cdr.detectChanges();
  }
}
