<div class="toggle-menu">
  <div class="svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path
        d="M140.001-254.616v-59.999h679.998v59.999H140.001Zm0-195.385v-59.998h679.998v59.998H140.001Zm0-195.384v-59.999h679.998v59.999H140.001Z"
      />
    </svg>
  </div>

  <div class="svg-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M7.69231 6.99999C9.61897 6.99999 11.1795 5.43948 11.1795 3.51281C11.1795 1.58615 9.61897 0.0256348 7.69231 0.0256348C5.76564 0.0256348 4.20513 1.58615 4.20513 3.51281C4.20513 5.43948 5.76564 6.99999 7.69231 6.99999ZM7.69231 8.74358C5.36462 8.74358 0.717949 9.91179 0.717949 12.2308C0.717949 13.1937 1.49858 13.9744 2.46154 13.9744H12.9231C13.886 13.9744 14.6667 13.1937 14.6667 12.2308C14.6667 9.91179 10.02 8.74358 7.69231 8.74358Z"
      ></path>
    </svg>
  </div>

  <!-- Dropdown menu -->
  <div class="toggle-menu-dropdown">
    <div *ngFor="let item of menuItems">
      <a (click)="handleAuthRequest(item.route)" class="menu-item">{{ item.label }}</a>
    </div>
    <div class="menu-spacer"></div>
    <!-- Submenu -->
    <div *ngIf="submenuItems.length">
      <div class="submenu">
        <div *ngFor="let item of submenuItems">
          <a [routerLink]="[item.route]" class="menu-item">{{ item.label }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
