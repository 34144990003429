import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import {HeaderService} from "../../services/header.service";

@Component({
  selector: 'master-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.scss']
})
export class OurStoryComponent implements OnInit {
  public logoCaptionText: string =
      'exclusive bookings for military and government travelers';
  public logoCaptionTextColor: string = '#fff';
  public headerBgColor: string = 'transparent';

  constructor(
      private meta: Meta,
      private title: Title,
      private headerService: HeaderService
  ) {
    this.headerService.setCurrentPage('our-story');
    this.meta.addTags([
      { name: 'description', content: 'Discover the inspiring story of At Ease Rentals Corporation (Atease) and its founder, a Marine Corps veteran who transformed the landscape of government travel accommodations.' },
      { name: 'keywords', content: 'At Ease Rentals Corporation, Atease, military lodging, government travel, veteran-owned business, corporate housing, vacation rentals' },
      // { property: 'og:title', content: 'About Us | At Ease Rentals Corporation' },
      { property: 'og:description', content: 'Learn about the vision and mission of At Ease Rentals Corporation (Atease), a veteran-owned business dedicated to providing quality accommodations for government travelers.' },
      { property: 'og:image', content: '/assets/images/atease-logo.jpg' },
      { property: 'og:url', content: `${environment.url}/our-story` },
      { property: 'og:type', content: 'website' },
    ], true);
  }

  ngOnInit(): void {
  }
}

