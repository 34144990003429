import { Component, OnInit, Input, ViewChild, SimpleChange } from "@angular/core";
import { PropertyItemData, PropertyAmenityQuickList } from "src/app/shared/models/property-model";
import {Router} from "@angular/router";
import {StorageService} from "../../services/storage.service";
import {ApiService} from "../../services/api.service";

@Component({
  selector: "app-property-item",
  templateUrl: "./property-item.component.html",
  styleUrls: ["./property-item.component.scss"],
})
export class PropertyItemComponent implements OnInit {
  @Input() property: PropertyItemData | undefined;
  // TODO Post-MVP: Add TDY, PCS, Gov, Vacay, etc.. colored checkmarks

  searchModel: any;

  constructor(
      public router: Router,
      public storageService: StorageService,
      public apiService: ApiService
  ) {
  }

  ngOnInit() {
    this.searchModel = this.apiService.getSearchState();
  }

  // SEE the SVG in the property-search-icons component
  getSVGIconTag(icon: PropertyAmenityQuickList): string {
    // console.log(`getSVGIconTag => ${icon}`);
    switch (icon) {
      case PropertyAmenityQuickList.AirConditioning:
        return "quick-list-air-con";
      case PropertyAmenityQuickList.WiFi:
        return "quick-list-wifi";
      case PropertyAmenityQuickList.Parking:
        return "quick-list-parking";
      case PropertyAmenityQuickList.Bar:
        return "quick-list-bar";
      case PropertyAmenityQuickList.FullKitchen:
        return "quick-list-full-kitchen";
      default:
        return "";
    }
  }

  public onViewProperty(route: any) {
    return this.router.navigate([route]);
  }

  /*
  TODO Post-MVP => [icon]="'property-checkmark'"
  TDY light purple (outer): #5342ac
  TDY dark purple (inner): #40358e

  PCS light cyan (outer): #00b9da
  PCS dark cyan (inner): #0098b4

  VACAY light green (outer) #02b396
  VACAY dark dreen (inner) #009880

  GOV??
  */
}
