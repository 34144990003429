import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";

// Material
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

import { HouseSpinnerComponent } from "src/app/theme/components/house-spinner/house-spinner.component"; //? move this to shared?
import { LogoComponent } from "src/app/shared/logo/logo.component";
import { PropertySearchFiltersComponent } from "./property-search-filters/property-search-filters.component";
import { PropertiesSearchComponent } from "./properties-search/properties-search.component";
import { PropertyItemComponent } from "./property-item/property-item.component";
import { ToggleSwitchComponent } from "./toggle-switch/toggle-switch.component";
import { LogoNoSwooshComponent } from "./logo-no-swoosh/logo-no-swoosh.component";
import { PropertySearchIconsComponent } from "./property-search-icons/property-search-icons.component";
import { EmbeddedVideoComponent } from "./embedded-video/embedded-video.component";
import { CustomRadioComponent } from "./custom-radio/custom-radio.component";
import { CustomDialogComponent } from "./custom-dialog/custom-dialog.component";
import { PropertiesToolbarComponent } from "./properties-toolbar/properties-toolbar.component";
import { LoadMoreComponent } from "./load-more/load-more.component";
import { SearchResultsComponent } from "../pages/search-results/search-results.component";
import { SearchResultsHeaderComponent } from "./search-results-header/search-results-header.component";
import { FloatingSearchButtonComponent } from "./floating-search-button/floating-search-button.component";
import {LoginComponent} from "../pages/login/login.component";
import {VerifyIdmeComponent} from "../pages/register/verify-idme/verify-idme.component";
import {ForgetPasswordComponent} from "../pages/forget-password/forget-password/forget-password.component";
import {RegisterComponent} from "../pages/register/register.component";
import {AccountComponent} from "../pages/account/account.component";
import { OurStoryComponent} from "../pages/our-story/our-story.component";
import { GoogleJsMapComponent } from "./google-js-map/google-js-map.component";
import {ChangePasswordComponent} from "../pages/change-password/change-password.component";
import {
  ProfileChangePasswordComponent
} from "../pages/account/profile-change-password/profile-change-password.component";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";
import { PropertyComponent } from '../pages/property/property.component';

@NgModule({
  declarations: [
    HouseSpinnerComponent,
    LogoComponent,
    LogoNoSwooshComponent,
    PropertySearchFiltersComponent,
    PropertiesSearchComponent,
    PropertyItemComponent,
    PropertySearchIconsComponent,
    ToggleSwitchComponent,
    EmbeddedVideoComponent,
    CustomRadioComponent,
    CustomDialogComponent,
    PropertiesToolbarComponent,
    LoadMoreComponent,
    SearchResultsComponent,
    SearchResultsHeaderComponent,
    FloatingSearchButtonComponent,
    LoginComponent,
    VerifyIdmeComponent,
    ForgetPasswordComponent,
    RegisterComponent,
    OurStoryComponent,
    GoogleJsMapComponent,
      ChangePasswordComponent,
      ProfileChangePasswordComponent,
      AccountComponent,
      ConfirmationDialogComponent,
      PropertyComponent,
      //wiperDirective
  ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatNativeDateModule,
        MatRippleModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        NgOptimizedImage,
        RouterModule
    ],
  exports: [
    CustomDialogComponent,
    RouterModule,
    ReactiveFormsModule,
    PropertySearchFiltersComponent,
    PropertiesSearchComponent,
    PropertyItemComponent,
    EmbeddedVideoComponent,
    HouseSpinnerComponent,
    LogoComponent,
    LogoNoSwooshComponent,
    ToggleSwitchComponent,
    LoadMoreComponent,
    FloatingSearchButtonComponent,
    GoogleJsMapComponent,
    OurStoryComponent,

    // Material
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatRippleModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    RouterModule,
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
