import { Component, Input } from "@angular/core";

@Component({
  selector: "app-logo-no-swoosh",
  templateUrl: "./logo-no-swoosh.component.html",
  styleUrls: ["./logo-no-swoosh.component.scss"],
})
export class LogoNoSwooshComponent {
  @Input() customWidth: number = 256;
  @Input() customColor: string = "#01a7cf"; // Atease teal blue as default color

  getAspectRatioHeight(): number {
    // W:H aspect ratio of the SVG is 3:1
    const aspectRatio = 3;
    return this.customWidth / aspectRatio;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
