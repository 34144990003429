import { Component, Inject, HostListener } from "@angular/core";
import { Settings, AppSettings } from "./app.settings";
import { CookieManagementService } from "./services/cookie.management.service";
import { LoggerService } from "./services/logger.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent {
/*  @HostListener('window:beforeunload', ['$event'])
  clearLocalStorage(event) {
    localStorage.clear();
  }*/

  title = "atease-traveler";
  public settings: Settings;

  constructor(
      @Inject(AppSettings) public appSettings: AppSettings,
      private cookieMS: CookieManagementService,
      private logger: LoggerService
  ) {
    this.settings = this.appSettings.settings;

    // Control Cookies at the Topmost level Possible - DKG
    logger.logMsg("Inside App Constructor With: ", cookieMS);
    cookieMS.onLoad_ValidateBrowserSetCookies({ searchQueryCookieVersion: this.appSettings.settings.searchQueryCookieVersion });
  }
}
