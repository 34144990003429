import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private currentPageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public currentPage$: Observable<string> = this.currentPageSubject.asObservable();

    public setCurrentPage(page: string): void {
        this.currentPageSubject.next(page);
    }
}
