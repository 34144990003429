import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-property-search-filters",
  templateUrl: "./property-search-filters.component.html",
  styleUrls: ["./property-search-filters.component.scss"],
})
export class PropertySearchFiltersComponent {
  @Input() searchFields: any;
  @Output() onRemoveSearchField: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  showExpandedSearch: boolean = false;

  ngOnInit() {}
}
