<mat-card class="w-100 p-2 filter-row mat-elevation-z1 text-muted">
  <button *ngIf="showSidenavToggle" mat-icon-button (click)="sidenavToggle()">
    <mat-icon>more_vert</mat-icon>
  </button>
  <div class="sort-menu">
    <a mat-button [matMenuTriggerFor]="sortMenu" #sortMenuTrigger="matMenuTrigger">
      {{ sort }}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #sortMenu="matMenu" xPosition="before" overlapTrigger="true">
      <span (mouseleave)="sortMenuTrigger.closeMenu()">
        <button mat-menu-item *ngFor="let sort of sortings" (click)="changeSorting(sort)">
          <span>{{ sort }}</span>
        </button>
      </span>
    </mat-menu>
  </div>
  <div class="view-options">
    <div *ngIf="!isHomePage" class="view-buttons">
      <button mat-icon-button (click)="changeViewType('list', 100)">
        <mat-icon>view_list</mat-icon>
      </button>
      <button mat-icon-button (click)="changeViewType('grid', 50)">
        <svg class="mat-icon mat-icon-sm" viewBox="0 0 25 25">
          <path d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3" />
        </svg>
      </button>
    </div>
    <div *ngIf="isHomePage" class="view-buttons">
      <button mat-icon-button (click)="changeViewType('grid', 33.3)">
        <mat-icon>view_module</mat-icon>
      </button>
      <button mat-icon-button (click)="changeViewType('grid', 25)">
        <mat-icon>view_comfy</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
