import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords, emailValidator } from 'src/app/theme/utils/app-validators';
import { ApiService } from 'src/app/services/api.service';
import { LoginRequestModel } from 'src/app/shared/models/login-model';
import { ApiResponse } from 'src/app/shared/models/response-model';
import {HeaderService} from "../../../services/header.service";

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.css']
})

export class ForgetPasswordComponent implements OnInit {
    public resetPasswordForm: UntypedFormGroup;
    public formSubmitted: boolean = false;
    LoginRequestModel: LoginRequestModel

    constructor(
        public fb: UntypedFormBuilder,
        public router:Router,
        public snackBar: MatSnackBar,
        public api : ApiService,
        private headerService: HeaderService
    ) {
    this.headerService.setCurrentPage('forget-password');
}

    ngOnInit() {
        this.formSubmitted = false;
        this.resetPasswordForm = this.fb.group({
            email: ['', Validators.compose([Validators.required])],
        })
    }

    public onresetPasswordFormSubmit(values:Object):void {
        if (this.resetPasswordForm.valid) {
            this.formSubmitted = true;
            this.LoginRequestModel = <LoginRequestModel> this.resetPasswordForm.value;
            let data = {
                username: this.resetPasswordForm.value.email
            }
            this.api.putCall('/auth/resetPassword',  data).subscribe((res: ApiResponse<any>)=>{
                console.log("Response to reset password was: ", res);
            })
            this.snackBar.open('Reset password link send successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        }
    }

    public goToHome() {
        return this.router.navigate(['/']);
    }
}
