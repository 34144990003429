<mat-card class="main-content-header">
  <img [src]="'assets/images/others/about.jpg'" [alt]="'About Atease change how you move'"/>
  <div class="section pt-2">
    <div class="px-3">
      <div class="theme-container">
        <h1 class="section-title">our story</h1>
        <p class="px-3">
          Once upon a time, in the heart of a Marine Corps base, a proud Marine embarked on a journey of innovation
          and service. With a steadfast determination to improve the lives of his fellow service members and their families, he set out to address a glaring need in the military community - the challenge of finding suitable lodging during relocations and official travels.
        </p>
        <p>
          Having experienced firsthand the frustrations of navigating government regulations and facing financial
          setbacks due to outdated policies, the Marine knew that something had to change. Drawing upon his own
          experiences and expertise, he envisioned a solution that would revolutionize the way government travelers,
          including Marines, found temporary accommodations.
        </p>
        <p>
          And so, At Ease Rentals Corporation (Atease) was born, a testament to the vision and perseverance of one
          man
          on a mission to make a difference. With unwavering dedication and a clear sense of purpose, he set out to build a platform that would become a lifeline for countless service members and their families.
        </p>
        <p>
          But he did not walk this path alone. Alongside him stood a team of experts, united in their commitment to
          the cause. Together, they navigated the complexities of government policies and regulations with precision and determination, ensuring that Atease emerged as a beacon of hope for federal travelers across the country.
        </p>
        <p>
          Through their combined efforts, Atease became more than just a business venture; it became a testament to
          the power of teamwork and collaboration. Despite facing numerous challenges and obstacles along the way, the Marine and his team remained steadfast in their mission to reduce the financial burden on their comrades and contribute to their overall well-being.
        </p>
        <p>
          As the sun set on another successful day at At Ease Rentals Corporation, the Marine and his team stood
          tall, knowing that they had achieved something truly remarkable - not just for themselves, but for the
          countless service members and government employees who would benefit from their vision and dedication. With
          hearts full of pride and gratitude, they looked toward the future, ready to continue their mission of serving those who serve their country, together as one cohesive unit.
        </p>
        <div class="flex-container">
          <div class="flex-item">
            <div class="flex-item-header">
              <mat-icon class="mat-icon-xlg" color="primary">business</mat-icon>
              <h2 class="capitalize fw-600 mx-2">About company</h2>
            </div>
            <p class="mt-2">
              We've teamed up with hosts, property managers, corporate housing providers, and hoteliers who believe
              military members and their families deserve top-notch accommodations. Government regulations restrict
              using other short-term rental platforms for reimbursable travel, so we offer a solution. Our platform
              connects government travelers with quality vacation rentals, corporate housing, and top-rated hotels that
              meet strict standards, ensuring comfort and compliance. We're dedicated to making government travel hassle-free for our nation's heroes.
            </p>
          </div>
          <div class="flex-item">
            <div class="flex-item-header">
              <mat-icon class="mat-icon-xlg" color="primary">dog</mat-icon>
              <h2 class="capitalize fw-600 mx-2">Vision</h2>
            </div>
            <p class="mt-2">
              We strive to simplify the moving experience for our customers, providing them with unlimited selection, and convenience.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>