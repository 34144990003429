import {Component, ElementRef, OnInit, ViewChild, HostListener} from "@angular/core";
import { LabelledRoute } from "src/app/shared/models/menu-model";
import { ROUTES } from "src/app/constants/routes.constants";
import { environment } from "src/environments/environment";
import {StorageService} from "../../../services/storage.service";
import {Router} from "@angular/router";
import {HeaderService} from "../../../services/header.service";

@Component({
  selector: "app-header-menu",
  templateUrl: "./header-menu.component.html",
  styleUrls: ["./header-menu.component.scss"],
})
export class HeaderMenuComponent implements OnInit {
  @ViewChild("searchBar", { read: ElementRef }) searchBar!: ElementRef;
  hostURL: string = environment.hostURL;
  menuItems: LabelledRoute[] = [];
  submenuItems: LabelledRoute[] = [];

  allowSearchFormToDisplay: boolean = true;
  searchBarWideMode: boolean = false;
  showSearchBoxShadow: boolean = true;
  showSpecialCSSForLanding: boolean = false;
  currentRoute: string = "";

  isScrolled = false;

  constructor(
      public storageService: StorageService,
      private router: Router,
      private headerService: HeaderService
  ) {}

  ngOnInit() {
    let userProfileExists = !!this.storageService.getUserData();
    this.menuItems = this.storageService.setStateBasedMainMenuRoutes(this.menuItems, userProfileExists);
    this.submenuItems = this.storageService.setStateBasedSubMenuRoutes(this.submenuItems, userProfileExists);
    this.headerService.currentPage$.subscribe(page => {
      this.showSpecialCSSForLanding = page === '/';
    });
  }

  public updateNavigationDropdownRoutes() {
    let userProfileExists = !!this.storageService.getUserData();
    this.menuItems = this.storageService.setStateBasedMainMenuRoutes(this.menuItems, userProfileExists);
    this.submenuItems = this.storageService.setStateBasedSubMenuRoutes(this.submenuItems, userProfileExists);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    this.isScrolled = scrollPosition > 0;
    this.applyStyles();
  }

  applyStyles() {
    const element = document.querySelector('.overrideForLanding');
    if (element) {
      element.classList.toggle('scrolled', this.isScrolled);
    }
  }
}
