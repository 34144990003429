<div class="p-3 ">
    <div class="theme-container">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 3rem;">
            <mat-card style="max-width: 500px; padding: 15px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;">
                <div>
                    <app-logo></app-logo>
                </div>
                <div id="step1" *ngIf="!formSubmitted">
                    <div style="margin-top: 1rem;">
                        <h1>Reset Your Password</h1>
                        <h3 class="text-muted ws-nowrap fw-500 p-2">Fill out the information below</h3>
                    </div>
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onresetPasswordFormSubmit(resetPasswordForm.value)" class="py-5">
                        <mat-form-field  appearance="outline" class="w-100 mt-1">
                            <mat-icon matPrefix class="mr-1 text-muted">email</mat-icon>
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" formControlName="email" required>
                            <mat-error *ngIf="resetPasswordForm.controls['email'].errors?.['required']">
                                Email is required
                            </mat-error>
                        </mat-form-field>
                        <div class="text-center mt-2">
                            <button mat-raised-button color="primary" class="uppercase" type="submit">
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
                <div id="step2" *ngIf="formSubmitted">
                    <div style="margin-top: 1rem;">
                        <h1>An Email Has Been Sent!</h1>
                        <h3 class="text-muted fw-500" style="padding: 2rem;">
                            If the account exists, you will receive an email with instructions on how to reset your password.
                        </h3>
                    </div>
                </div>
                <mat-card-actions>
                    <button type="button" mat-button (click)="goToHome()">
                        <span class="mx-1 Primary-color">Back To Home Page</span>
                    </button>
                </mat-card-actions>
            </mat-card>
            <div class="divider mt-4"></div>
        </div>
    </div>
</div>
