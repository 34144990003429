import { Injectable } from '@angular/core';
import {environment} from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class LoggerService {
    private isProd: boolean = environment.production;

    constructor() {}

    // Log Level
    public logMsg(msg: string, data: any, showMessage: boolean = true): void {
        return (!this.isProd && showMessage) ? console.log(msg, data) : null;
    }

    // Dir Level
    public logToDir(msg: string, data: any, showMessage: boolean = true): void {
        return (!this.isProd && showMessage) ? console.dir(msg, data) : null;
    }

    // Error Level
    public logError(msg: string, data: any, showMessage: boolean = true, overridePrintToProd: boolean = false): void {
        if(overridePrintToProd) return console.error(msg, data);
        return (!this.isProd && showMessage) ? console.error(msg, data) : null;
    }

    // Debug Level
    public logDebug(msg: string, data: any, showMessage: boolean = true, overridePrintToProd: boolean = false): void {
        if(overridePrintToProd) return console.debug(msg, data);
        return (!this.isProd && showMessage) ? console.debug(msg, data) : null;
    }
}
