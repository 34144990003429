import { Injectable } from '@angular/core';
import { SearchModel } from "../shared/models/search-model";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class SearchService {
    constructor() {}

    /**
     * Property List For Given Search
     * */
    private currentPageResultsSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public currentPageResults$: Observable<any> = this.currentPageResultsSubject.asObservable();
    public setCurrentPageResults(data: any): void {
        this.currentPageResultsSubject.next(data);
    }

    /**
     * Updates To The SearchModel
     * */
    public resetLocationOnSearchModel = (searchModel: SearchModel): SearchModel => {
        searchModel.userSearchSettings.address.city = "";
        searchModel.userSearchSettings.address.state = "";
        searchModel.userSearchSettings.address.stateCode = "";
        searchModel.userSearchSettings.address.country = "";
        searchModel.userSearchSettings.address.postalCode = "";
        return searchModel;
    }
    public setLocationOnSearchModel = (searchModel: SearchModel, addressData: any): SearchModel => {
        searchModel.userSearchSettings.address.city = addressData.city;
        searchModel.userSearchSettings.address.state = addressData.state;
        searchModel.userSearchSettings.address.stateCode = addressData.stateCode;
        searchModel.userSearchSettings.address.country = addressData.country;
        if(!!addressData.postalCode && addressData.postalCode.length > 0) {
            searchModel.userSearchSettings.address.postalCode = addressData.postalCode;
        }
        return searchModel;
    }
    public setCheckInDateOnSearchModel(searchModel: SearchModel, checkInDateString: string) {
        searchModel.userSearchSettings.dates.checkin = checkInDateString;
        return searchModel;
    }
    public setCheckOutDateOnSearchModel(searchModel: SearchModel, checkOutDateString: string) {
        searchModel.userSearchSettings.dates.checkout = checkOutDateString;
        return searchModel;
    }
    public setAdultCountOnSearchModel(searchModel: SearchModel, adultCount: number) {
        searchModel.filters.occupancy.adults = adultCount;
        return searchModel;
    }
    public setChildCountOnSearchModel(searchModel: SearchModel, childCount: number) {
        searchModel.filters.occupancy.childTwelvePlus = childCount;
        return searchModel;
    }
    public setInfantCountOnSearchModel(searchModel: SearchModel, infantCount: number) {
        searchModel.filters.occupancy.childUnderEleven = infantCount;
        return searchModel;
    }
    public setTypeOfTravelOnSearchModel(searchModel: SearchModel, typeOfTravel: string) {
        searchModel.filters.typeOfTravel.vacation = typeOfTravel === "Vacation";
        searchModel.filters.typeOfTravel.government = typeOfTravel === "Government";
        return searchModel;
    }
    public setTypeOfGovernmentTravelOnSearchModel(searchModel: SearchModel, typeOfGovernmentTravel: string) {
        searchModel.filters.typeOfGovernmentTravel.pcs = typeOfGovernmentTravel === "pcs";
        searchModel.filters.typeOfGovernmentTravel.tdy = typeOfGovernmentTravel === "tdy";
        return searchModel;
    }
    public setClientIP(searchModel: SearchModel, clientIP: string): SearchModel {
        searchModel.defaultMeta.clientIP = clientIP;
        return searchModel;
    }
    public setOriginatingRoute = (searchModel: SearchModel, originalRoute: string): SearchModel => {
        searchModel.defaultMeta.originalRoute = originalRoute;
        return searchModel;
    }
    public processFiltersForPCS(searchModel: SearchModel, data: any) {
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.tle = data.lodgingAllowance;
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.travelingWithFamily = data.travelWith.family;
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.travelingWithSpouse = data.travelWith.spouse;
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.travelingWithOthers = data.travelWith.others;

        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.useBAH = data.usingBAH;
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.useMealAllowance = data.mealAllowance;

        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forPCS.travelWithChoices = data.travelingWith;

        return searchModel;
    }
    public processFiltersForTDY(searchModel: SearchModel, data: any) {
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forTDY.useLodgingAllowance = data.lodgingAllowance;
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forTDY.useMIEAllowance = data.mealAllowance;
        searchModel.filters.typeOfGovernmentTravel.additionalDetail.forTDY.isGroupTravel = data.others;
        return searchModel;
    }
    public convertFiltersToJSONString(searchModel: SearchModel) {
        searchModel.userSearchSettings.filters = JSON.stringify(searchModel.filters);
        return searchModel;
    }
}

