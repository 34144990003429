<div class="footer" #footer>
  <div class="logo">
    <!-- WHITE THEME -->
    <ng-container *ngIf="whiteTheme; else whiteLogo">
      <app-logo-no-swoosh class="footer-item logo" [customWidth]="customLogoWidth"></app-logo-no-swoosh>
    </ng-container>

    <!-- BLUE THEME -->
    <ng-template #whiteLogo>
      <app-logo-no-swoosh class="footer-item logo" [customWidth]="customLogoWidth" [customColor]="logoColor"></app-logo-no-swoosh>
    </ng-template>
  </div>

  <div class="links">
    <div *ngFor="let col of COLUMN_LINKS">
      <!-- WHITE THEME WITH DARK FONTS-->
      <ng-container *ngIf="whiteTheme; else whiteFont">
        <div class="col-container color-black">
          <h3 class="col-header">{{ col.colHeader }}</h3>
          <div *ngFor="let item of col.links">
            <a [routerLink]="[item.route]" class="menu-item">{{ item.label }}</a>

            <!-- ! ADDED THIS BECAUSE I HAD TROUBLE SPACING THESE -->
            <p>Atease Properties</p>
            <!-- ! ADDED THIS BECAUSE I HAD TROUBLE SPACING THESE -->
          </div>
        </div>
      </ng-container>

      <!-- BLUE THEME WITH WHITE FONTS-->
      <ng-template #whiteFont>
        <div class="col-container color-white">
          <h3 class="col-header">{{ col.colHeader }}</h3>
          <div *ngFor="let item of col.links">
            <a [routerLink]="[item.route]" class="menu-item">{{ item.label }}</a>

            <!-- ! ADDED THIS BECAUSE I HAD TROUBLE SPACING THESE -->
            <p>Atease Properties</p>
            <!-- ! ADDED THIS BECAUSE I HAD TROUBLE SPACING THESE -->
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="social">
    <!-- WHITE THEME -->
    <ng-container *ngIf="whiteTheme; else whiteColorIcons">
      <app-social-icons iconSize="28px" iconColor="darkGrey"></app-social-icons>
    </ng-container>

    <!-- BLUE THEME -->
    <ng-template #whiteColorIcons>
      <app-social-icons iconSize="28px" iconColor="white"></app-social-icons>
    </ng-template>
  </div>
</div>
