import { Injectable } from "@angular/core";

interface ILoadMoreSettings {
  start: boolean;
  step: number;
  load: boolean;
  page: number;
  complete: boolean;
  result: number;
}

export class Settings {
  public name: string;
  public theme: string;
  public header: string;
  public rtl: boolean;
  public searchPanelVariant: number;
  public searchOnBtnClick: boolean;
  public currency: string;

  // Additional options
  public contentOffsetToTop: boolean;
  public headerBgImage: boolean;
  public headerBgVideo: boolean;
  public loadMore: ILoadMoreSettings;

  // Begin to Track Cookie Versions Here - dkg
  public searchQueryCookieVersion: number;

  constructor(
    name: string = "Atease",
    theme: string = "blue",
    header: string = "image",
    rtl: boolean = false,
    searchPanelVariant: number = 1,
    searchOnBtnClick: boolean = false,
    currency: string = "USD",
    contentOffsetToTop: boolean = false,
    headerBgImage: boolean = false,
    headerBgVideo: boolean = false,
    loadMore: ILoadMoreSettings = {
      start: false,
      step: 1,
      load: false,
      page: 1,
      complete: false,
      result: 0,
    }
  ) {
    this.name = name;
    this.theme = theme;
    this.header = header;
    this.rtl = rtl;
    this.searchPanelVariant = searchPanelVariant;
    this.searchOnBtnClick = searchOnBtnClick;
    this.currency = currency;
    this.contentOffsetToTop = contentOffsetToTop;
    this.headerBgImage = headerBgImage;
    this.headerBgVideo = headerBgVideo;
    this.loadMore = loadMore;

    // Version The Cookies Here. Update this Value when You Change the Model Structure of the Cookie and
    // the old cookie structure will no longer match the new implementation.
    this.searchQueryCookieVersion = 0;
  }
}

@Injectable({
  providedIn: "root",
})
export class AppSettings {
  public settings: Settings;

  // If you need to override any default setting, do it here
  constructor() {
    this.settings = new Settings();
    // this.settings.theme = 'green'; // example to change theme
  }
}
