<div class="spinner-wrapper" id="preloader" #preloader>
  <div class="spinner-container">
    <div class="spinner-outer">
      <div class="spinner">
        <div class="left mask">
          <div class="plane"></div>
        </div>
        <div class="top mask">
          <div class="plane"></div>
        </div>
        <div class="right mask">
          <div class="plane"></div>
        </div>
        <div class="triangle">
          <div class="triangle-plane"></div>
        </div>
        <div class="top-left mask">
          <div class="plane"></div>
        </div>
        <div class="top-right mask">
          <div class="plane"></div>
        </div>
      </div>
      <p class="spinner-text">Atease</p>
    </div>
  </div>
</div>
