import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiResponse } from 'src/app/shared/models/response-model';

import { matchingPasswords } from 'src/app/theme/utils/app-validators';
import {MatDialog} from "@angular/material/dialog";
import {HeaderService} from "../../../services/header.service";

@Component({
    selector: 'app-profile-change-password',
    templateUrl: './profile-change-password.component.html',
    styleUrls: ['./profile-change-password.component.scss']
})
export class ProfileChangePasswordComponent implements OnInit {
    public passwordForm:UntypedFormGroup;
    public user: any;
    constructor(
        public formBuilder: UntypedFormBuilder,
        public snackBar: MatSnackBar,
        private api: ApiService,
        private storage:StorageService,
        public router: Router,
        public dialog: MatDialog,
        private headerService: HeaderService
    ) {
        this.headerService.setCurrentPage('profile-change-password');
    }

    ngOnInit(): void {
        this.user = this.storage.getData();
        this.passwordForm = this.formBuilder.group({
            currentPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmNewPassword: ['', Validators.required],
            email: this.user.email,
            userId: this.user.id
        },{validator: matchingPasswords('newPassword', 'confirmNewPassword')});
    }
    public onPasswordFormSubmit(values:Object):void {
        if (this.passwordForm.valid) {
            const passwordData = {
                userId: parseInt(this.user.id),
                email: this.user.email,
                oldPassword: this.passwordForm.get('currentPassword').value,
                newPassword: this.passwordForm.get('newPassword').value
            };
            this.api.postCall('/auth/user/changePassword', passwordData).subscribe((res: ApiResponse<any>) => {
                if (res.statusText === "Success") {
                    this.snackBar.open('Your Password updated successfully!', '×', { panelClass: 'success',horizontalPosition:'right', verticalPosition: 'top', duration: 3000 });
                    this.dialog.closeAll();
                } else {
                    this.snackBar.open('Your password changed Failed!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
                }

            })
        }
    }
}
