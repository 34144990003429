<!--<app-properties-search *ngIf="this.allowSearchFormToDisplay" #searchBar [wideMode]="false" [hideBoxShadow]="false"></app-properties-search>
<app-floating-search-button (showMobileSearch)="toggleSearch($event)"></app-floating-search-button>-->

<header>
  <app-search-results-header [city]="city" [stateCode]="stateCode" [headerMessage]="headerMessage"></app-search-results-header>

  <div class="header-buttons">
    <div class="header-left">
      <ng-container *ngFor="let btn of headerButtonsLeft">
        <button *ngIf="btn.type === 'button'" class="btn" (click)="handleSortButtonClick(btn.function, $event)">{{ btn.label }}</button>
        <a href="#" *ngIf="btn.type === 'anchor'" (click)="handleSortButtonClick(btn.function, $event)">{{ btn.label }}</a>
      </ng-container>
    </div>

    <div class="header-right">
      <ng-container *ngFor="let view of headerLinksRight">
        <div class="icon-anchor">
          <a href="#" [ngClass]="[selectedView === view.icon ? '' : 'unselected']" (click)="toggleView(view.icon, $event)">
            {{ view.label }}
          </a>

          <app-property-search-icons
            class="custom-icon"
            [color]="selectedView === view.icon ? '#00a6ce' : '#758492'"
            [size]="view.iconSize"
            [icon]="view.icon"
          ></app-property-search-icons>
        </div>
      </ng-container>
    </div>
  </div>

  <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loadingProperties"></mat-progress-bar>
</header>

<!-- Just Cards Grid Layout (No Map)  -->
<ng-container *ngIf="selectedView === 'grid-view'">
  <!-- <h3>Grid View</h3> -->
  <div class="grid-layout">
    <app-property-item *ngFor="let prop of properties" [property]="prop"></app-property-item>
  </div>
</ng-container>

<!-- Split View With Map -->
<ng-container *ngIf="selectedView === 'map-view'">
  <div class="map-layout">
    <!-- <h3>Split Map View</h3> -->

    <div class="map-view-left">
      <app-property-item *ngFor="let prop of properties" [property]="prop"></app-property-item>
    </div>

    <div class="map-view-right">
      <ng-container *ngIf="loadingProperties || !gps; else mapContainer">
        <img [src]="mapURL" alt="Atease Properties Map" />
      </ng-container>
      <ng-template #mapContainer>
        <app-google-js-map [ngStyle]="{ 'margin-top': '2rem' }" *ngIf="gps" [gps]="gps"></app-google-js-map>

        <!-- ! FAKE MAP TO SAVE ON API CALLS DURING DEV/TESTING -->
        <!-- <div [ngStyle]="{ width: '100%', margin: '2rem', marginRight: 0 }">
          <img [ngStyle]="{ maxWidth: '100%' }" src="assets/images/google-js-interactive-map-screenshot.jpg" />
        </div> -->
      </ng-template>
    </div>
  </div>
</ng-container>
