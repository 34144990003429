<div style="padding: 15px;">
    <div class="theme-container">
        <div fxLayout="row" fxLayoutAlign="center center" class="my-5 ">
            <mat-card style="padding: 30px;">
                <div fxLayout="column" fxLayoutAlign="center center" class="text-center mt-5">
                    <app-logo style="padding-bottom: 1rem;"></app-logo>
                </div>
                <div style="margin-top: 1rem; text-align: center;">
                    <h1 class="primary-app-color">Sign Up</h1>
                </div>
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <a href="{{idmeRegistrationLink}}"
                        class="mat-button-custom">
                        <div data-event-name="clickLogo" class="global-nav__logo-link">
                            <div style="display: flex; align-items: center;">
                                <img src="/assets/svgs/id.me_white.svg" alt="">
                                <strong style="color: white;" class="mt-2"> | Verify with ID.me</strong>
                            </div>
                        </div>
                    </a>
                </div>
                <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center; margin-top: 2rem;">
                    <h3 class="text-muted ws-nowrap fw-500 p-2">Already have an account?
                    </h3>
                    <mat-card-actions style="justify-content: end; align-items: center;">
                        <button mat-button (click)="openSignIn()">
                            <span class="mx-1 Primary-color">Login</span>
                        </button>
                    </mat-card-actions>
                </div>
            </mat-card>
        </div>

    </div>
</div>
