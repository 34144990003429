import { Component, ElementRef } from "@angular/core";

@Component({
  selector: "app-house-spinner",
  templateUrl: "./house-spinner.component.html",
  styleUrls: ["./house-spinner.component.scss"],
})
export class HouseSpinnerComponent {
  constructor(private elementRef: ElementRef) {}

  hideSpinner(): void {
    const spinnerElement = this.elementRef.nativeElement.querySelector("#preloader");
    if (spinnerElement) {
      spinnerElement.classList.add("hide");
    }
  }
}
