import { Injectable } from '@angular/core';
import {DatePipe} from "@angular/common";
import { PropertyAmenityQuickList, PropertyItemData } from "src/app/shared/models/property-model";
@Injectable({
    providedIn: 'root'
})

export class SearchHelpers {
    constructor() {}

    /**
     * Internal Helper Functions
     * */
    private getBrowserLocale(): string {
        // Check if navigator.languages is available and has at least one entry
        if (navigator.languages && navigator.languages.length > 0) {
            return navigator.languages[0];
        } else {
            // Fallback to navigator.language if navigator.languages is not available
            return navigator.language;
        }
    }
    private setDefaultTimezoneByUsersBrowser(): string { return Intl.DateTimeFormat().resolvedOptions().timeZone; }


    /**
     * Parses property data returned via an API search
     * @param {any[]} content
     * @returns {PropertyItemData[]}
     */
    public parsePropertySearchResponse(content: any[]): PropertyItemData[] {
        if (content.length) {
          return content.map((property: any) => {
            const propertyDetail = property?.propertyDetail || {};

            let image = "";
            if (propertyDetail.gallery?.length) {
              // Select the first image in gallary (if exists)
              image = propertyDetail.gallery[0].big || ""; // TODO: add a 'dummy' static asset image here as fallback
            }

            // Filter and extract quick icons matching the enum and having a value of true
            const quickIconsList = Object.entries(property?.quickIcons?.list || {})
              .filter(([icon, value]) => value === true && Object.values(PropertyAmenityQuickList).includes(icon as PropertyAmenityQuickList))
              .map(([icon]) => icon as PropertyAmenityQuickList);

            // Create a PropertyItemData object for each property
            const propertyItem: PropertyItemData = {
              title: propertyDetail?.title || "",
              quickList: quickIconsList as PropertyAmenityQuickList[],
              bedCount: parseInt(propertyDetail.bedCount || 0, 10),
              bathroomCount: parseInt(propertyDetail.bathroomCount || 0, 10),
              description: property?.propertyDetail?.description || "",
              image,
              price: parseFloat(propertyDetail?.priceScale?.baseRate.replace("$", "") || "0"),
              listingRoute: `/property/${property.id}`,
              femaApproved: property.femaApproved
            };

            //console.dir(propertyItem);

            return propertyItem;
          });
        } else {
          return [];
        }
      }

    /**
     * Exposed Helper Functions
     * */
    public setDefaultSearchDateMeta = () => {
        const
            userBrowserLocale = this.getBrowserLocale(),
            userTimezone = this.setDefaultTimezoneByUsersBrowser(),
            datePipeWithDefaultTz = new DatePipe(userBrowserLocale, userTimezone),
            dateSearchFormat = "yyyy-MM-dd";

        console.log(userBrowserLocale, userTimezone);

        let
            currentDate = new Date(),
            defaultCheckin = new Date(currentDate),
            defaultCheckout = new Date(currentDate);

        // Set a Range of Default Data for Blind Search Requests: Round Rock, Tx | 1 Adult | CurrentMonth+1 - 7 days out
        defaultCheckin.setMonth(currentDate.getMonth() + 1);
        defaultCheckout.setMonth(currentDate.getMonth() + 1);
        defaultCheckout.setDate(defaultCheckout.getDate() + 7);

        return {
            user: {
                browserTimezone: userTimezone,
                browserLocale: userBrowserLocale
            },
            currentDate,
            constructedDates: {
                checkin: datePipeWithDefaultTz.transform(defaultCheckin, dateSearchFormat, userTimezone),
                checkout: datePipeWithDefaultTz.transform(defaultCheckout, dateSearchFormat, userTimezone)
            }
        }
    }
    public setDefaultSearchQueryParams = (defaultMeta: any) => {
        return {
            checkin: defaultMeta.constructedDates.checkin,
            checkout: defaultMeta.constructedDates.checkout,
            city: "Round Rock",
            state: "texas",
            stateCode: "tx",
            postalCode: "78681",
            country: "usa",
            totalGuests: 1
        }
    }
    public setDefaultRequestBody = () => {
        return {
            "filters": {
                "occupancy": {
                    "adults": 1,
                    "childTwelvePlus": 0,
                    "childUnderEleven": 0,
                    "pets": {
                        "dogs": 0,
                        "cats": 0,
                    }
                },
                "typeOfTravel": {
                    "government": false,
                    "vacation": true
                },
                "typeOfGovernmentTravel": {
                    "pcs": false,
                    "tdy": false,
                    "additionalDetail": {
                        "travelingWith": ["Service member or dependent only", "Service member with two or more dependents"],
                        "usingBAH": false,
                        "usingLodgingAllowance": false,
                        "usingMealAllowance": false
                    }
                }
            }
        }
    }
}
