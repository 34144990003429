<mat-card *ngIf="property" class="property-card">
  <div class="content-wrapper">
    <div class="image-container">
      <img
              class="property-image"
              [ngSrc]="property.image"
              width="288" height="320"
              alt="{{ property.title }}"
              priority="false"
      />
    </div>

    <div class="text-content">
      <h2 class="property-title">{{ property.title }}</h2>
      <div class="quick-list-icons">
        <app-property-search-icons
          *ngFor="let icon of property.quickList"
          [color]="'#758492'"
          [icon]="getSVGIconTag(icon)"
        ></app-property-search-icons>

        <div class="quick-list-text">
          <p>{{ property.bedCount }} bed {{ property.bathroomCount }} bath{{ property.bathroomCount === 1 ? "" : "s" }}</p>
        </div>
      </div>

      <div class="property-type" style="height: 3rem">
        <div class="property-status-icon" style="display: flex; flex-flow: row wrap;">
          <button
                  *ngIf="searchModel?.filters.typeOfTravel.vacation"
                  class="btn-icon vacationEligible" [disabled]="true" style="display: flex; align-items: center"
          >
            <mat-icon style="padding-right: 5px;">check_circle</mat-icon><span>VACATION</span>
          </button>
          <button
                  *ngIf="searchModel?.filters.typeOfTravel.government && property.femaApproved"
                  class="btn-icon govEligible" [disabled]="true" style="display: flex; align-items: center">
            <mat-icon style="padding-right: 5px;">check_circle</mat-icon>Govt Approved Travel
          </button>
        </div>
        <!-- TODO: POST MVP -->
        <!-- TDY light purple (outer): #5342ac
        TDY dark purple (inner): #40358e

        PCS light cyan (outer): #00b9da
        PCS dark cyan (inner): #0098b4

        VACAY light green (outer) #02b396
        VACAY dark dreen (inner) #009880-->
      </div>

      <p class="description">{{ property.description }}</p>

      <div class="bottom-section">
        <div class="price-container">
          <p class="price">
            <span class="currency-symbol">$</span>{{ property.price.toFixed(2) }}
            <span class="currency-desc">/night + tax</span>
          </p>
        </div>

        <div class="view-listing-container">
          <a (click)="onViewProperty(property.listingRoute)" class="view-listing">View Listing<span></span></a>
        </div>
      </div>
    </div>
  </div>
</mat-card>
