<div class="not-found">
  <app-logo [customWidth]="logoWidth"></app-logo>

  <h1>404</h1>
  <h2>Page Not Found</h2>
  <p>
    You seem to have taken a wrong turn. Navigate <a href="#" (click)="navigateToHomePage()">back to the homepage</a> and start looking for some
    properties!
  </p>

  <div>
    <button routerLink="/">Home</button>
  </div>
</div>
