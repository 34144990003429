import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ValidationErrors, AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export function emailValidator(control: UntypedFormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
    return null;
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
        let password= group.controls[passwordKey];
        let passwordConfirmation= group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({mismatchedPasswords: true})
        }
    }
}

export const passwordMatchValidator = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get("password")?.value;
    const repassword = control.get("repassword")?.value;

    console.log("Inside validator? ", password, repassword);
    if (password !== repassword) {
        return { passwordMismatch: true };
    }

    return null;
};

export const passwordValidator = (control: FormControl): { [key: string]: any } | null => {
    const password = control.get("password")?.value || "";

    // Check minimum and maximum length
    if (password.length < 6 || password.length > 20) {
        return  { length: 'Password must be between 6 and 20 characters long' };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ;
    }

    // Check mixture of alpha, numeric, and safe special chars
    const alphaNumericSafeSpecialCharPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$/;
    if (!alphaNumericSafeSpecialCharPattern.test(password)) {
        return { alphaNumericSafeSpecialChar: 'Password must contain a mix of alphabetic characters, numeric characters, and safe special characters: @$!%*#?&' };
    }

    // Check minimum of 1 uppercase, 1 lowercase, and 1 numeric
    const upperCasePattern = /[A-Z]/;
    const lowerCasePattern = /[a-z]/;
    const numericPattern = /\d/;
    if (!upperCasePattern.test(password) || !lowerCasePattern.test(password) || !numericPattern.test(password)) {
        return { caseNumeric: 'Password must contain at least one uppercase letter, one lowercase letter, and one numeric character' };;
    }

    // Check cannot be 9 numeric digits in a row
    const nineDigitsRowPattern = /(\d)\1{6}/;
    if (nineDigitsRowPattern.test(password)) {
        return { nineDigitsRow: 'Password cannot contain numeric digits in a row' };
    }

    return null;
};
