import { InjectionToken } from '@angular/core';
import { ImageLoaderConfig } from '@angular/common';

// Define an injection token for the image loader
const IMAGE_LOADER = new InjectionToken<any>('IMAGE_LOADER');

// Basic optimization image loader implementation
const basicOptimizationImageLoader = (config: ImageLoaderConfig) => {
  // Append any basic optimization logic here, such as resizing or compressing images
  return `/assets/images/${config.src}.jpg`; // Example: Assuming all images are JPEG format
};

// Lazy loading image loader implementation
const lazyLoadingImageLoader = (config: ImageLoaderConfig) => {
  // Basic implementation for lazy loading, replace with your actual lazy loading logic
  return `/assets/images/${config.src}.jpg`; // Example: Assuming all images are JPEG format
};

// Provide the IMAGE_LOADER token with a factory function
export const imageLoaderProvider = {
  provide: IMAGE_LOADER,
  useFactory: () => {
    // Return the appropriate image loader based on your application requirements
    // For basic optimization, return basicOptimizationImageLoader
    // For lazy loading, return lazyLoadingImageLoader
    return basicOptimizationImageLoader; // Change to lazyLoadingImageLoader if needed
  }
};