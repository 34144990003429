export const environment = {
  production: false,
  local: false,
  debugMode: true,
  apiUrl: "https://traveler.be.dev.atease.io",
  stripe: {
    publicKey: "", // TODO: Move to BE Serving this,
  },
  idmeSettings: {
    baseURL: "https://groups.id.me/",
    clientId: "1c067c6b07a214a5ad7cc1542004d7bd",
    redirectURL: "https://traveler.fe.dev.atease.io/registration/callback",
    responseType: "code",
    scopes: "government,military,nurse,responder"
  },
  url: "",
  hostURL: "https://host.fe.dev.atease.io",
  apikDecryptionSecret: "wpqVGwXXZzfJsXU0KQabhWtYdqfValhi"
};
