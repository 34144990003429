<div class="menu">
  <!-- ************************************************************************ -->
  <!-- *************************** LOGO *************************************** -->
  <!-- ************************************************************************ -->
  <div class="logoContainer__header">
    <app-logo class="menu-item logo" [customWidth]="125"></app-logo>
  </div>
  <!-- ************************************************************************ -->
  <!-- *************************** SEARCH BAR ********************************* -->
  <!-- ************************************************************************ -->
  <div class="searchBarContainer__header">
    <div [ngClass]="{ overrideForLanding: showSpecialCSSForLanding }" >
      <app-properties-search
              #searchBar
              [wideMode]="searchBarWideMode"
              [hideBoxShadow]="!showSearchBoxShadow"
      ></app-properties-search>
    </div>
  </div>
  <!-- ************************************************************************ -->
  <!-- *************************** AUTH & REDIRECT **************************** -->
  <!-- ************************************************************************ -->
  <div class="authContainer__header">
    <a href="{{ hostURL }}" class="menu-item host-link">
      Host Our Heroes
    </a>
    <app-toggle-switch
            class="toggle" [menuItems]="menuItems" [submenuItems]="submenuItems"
    ></app-toggle-switch>
  </div>
</div>
