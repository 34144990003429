import { HttpClient, HttpErrorResponse, HttpParams, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse, Upload } from "../shared/models/response-model";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { Property } from "../app.models";
import {SearchModel, SearchFilters, SearchParams} from "../shared/models/search-model";

@Injectable({
  providedIn: "root",
})

export class ApiService {
  apiUrl: string = environment.apiUrl;
  private access_token: string;
  public searchState: SearchModel;

  private onSearchButtonGuestClickSource = new Subject<void>();
  onSeachGuestClick$ = this.onSearchButtonGuestClickSource.asObservable();

  private searchStateSubject = new Subject<any>();
  searchState$ = this.searchStateSubject.asObservable();

  private onSearchCheckInClick = new Subject<void>();
  onSearchCheckInClick$ = this.onSearchCheckInClick.asObservable();

  private onSearchLocationClick = new Subject<void>();
  onSearchLocationClick$ = this.onSearchLocationClick.asObservable();

  private handleHttpError(error: HttpErrorResponse): Observable<ApiResponse<any>> {
    const errMessage = error?.statusText || error?.error?.message || error?.error?.error || "Unknown Error";
    this.dialog.snackBarError(errMessage);

    // Return an observable with an ApiResponse indicating failure
    return of({
      status: error.status,
      data: null,
      isVerified: false,
      message: error.error,
      error: true,
      email: null,
      enabled: null,
      statusText: null,
      authenticateIDMETokenResults: null,
      statusCode: null,
    });
  }

  constructor(private http: HttpClient, private dialog: CustomDialogService) {}

  private checkoutStateSubject = new Subject<any>();
  private searchQuery = 'searchQuery';
  checkoutState: any;
  setCheckoutState(checkoutModel: any) {
    this.checkoutState = checkoutModel;
    localStorage.setItem(this.searchQuery, JSON.stringify(checkoutModel));
    this.checkoutStateSubject.next(this.checkoutState);
  }

  getCheckoutState(): any {
    return this.checkoutState;
  }

  getCall<T>(url: string): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(this.apiUrl + url, { withCredentials: true })
        .pipe(
            map((response) => {
              if (response.status === 401) {
                return new ApiResponse();
              }
              return response;
            })
        );
  }

  getKeyCall<T>(url: string): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(this.apiUrl + url).pipe(
        map((response) => {
          if (response.status === 401) {
            //this.router.navigate(['/un-authorized']);
            return new ApiResponse();
          }
          return response;
        })
    );
  }

  postCall<T>(url: string, data: any): Observable<ApiResponse<T>> {
    return this.http
      .post<ApiResponse<T>>(this.apiUrl + url, data, { withCredentials: true })
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  private generateOptions(
      protectedRequest: boolean = true,
      params: any = null
  ): { headers: HttpHeaders; withCredentials?: boolean; params?: HttpParams } {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const options: { headers: HttpHeaders; withCredentials?: boolean; params?: HttpParams } = {
      headers,
      withCredentials: !!protectedRequest,
    };

    if (params) {
      options.params = new HttpParams();

      Object.keys(params).forEach((key) => {
        options.params = options.params?.set(key, params[key]);
      });
    }

    return options;
  }

  postCallNoAuth<T>(url: string, body: any, protectedRequest: boolean = true): Observable<ApiResponse<T>> {
    const options = this.generateOptions(protectedRequest);
    return this.http.post<ApiResponse<T>>(this.apiUrl + url, body, options).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  putCall<T>(url: string, data: any): Observable<ApiResponse<T>> {
    return this.http
      .put<ApiResponse<T>>(this.apiUrl + url, data, { withCredentials: true })
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  putCallForImageUploads(route: string, image: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', image);
    const headers = new HttpHeaders({});

    return this.http
      .put<any>(`${environment.apiUrl}/${route}`, formData, { headers, withCredentials: true })
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  getCallById<T>(url: string): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(this.apiUrl + url, { withCredentials: true }).pipe(
      map((response) => {
        if (response.status === 401) {
          return new ApiResponse();
        }

        return response;
      })
    );
  }

  getPropertyById(id): Observable<Property> {
    return this.http.get<Property>(this.apiUrl + id);
  }

  getPropertyByIdv2(id, searchSpecifications): Observable<Property> {
    // Pass necessary Filter Data
    let params = new HttpParams();

      if (searchSpecifications.userSearchSettings.dates.checkin) {
          params = params.append('checkin', searchSpecifications.userSearchSettings.dates.checkin);
      }
      if (searchSpecifications.userSearchSettings.dates.checkout) {
          params = params.append('checkout', searchSpecifications.userSearchSettings.dates.checkout);
      }
    //console.log(this.apiUrl + id, params);
    return this.http.get<Property>(this.apiUrl + id, { params });
  }

  DeleteCallById<T>(url: string): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(this.apiUrl + url).pipe(
      map((response) => {
        if (response.status === 401) {
          //this.router.navigate(['/un-authorized']);
          return new ApiResponse();
        }

        return response;
      })
    );
  }

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
  }
  upload(data: FormData): Observable<any> {
    const initialState: Upload = { state: "PENDING", progress: 0 };
    const calculateState = (upload: Upload, event: any): Upload => {
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
          state: "IN_PROGRESS",
        };
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: "DONE",
        };
      }
      return upload;
    };
    return this.http.post(environment.apiUrl + "upload", data);
    //.pipe(scan(calculateState,initialState))
  }

  setAccessToken(token: string): void {
    this.access_token = token;
  }

  /**
   * Fetch properties for property-search component
   **/
  getCallWithBodyData<T>(url: string, options: any): Observable<ApiResponse<T>> {
    let params = new HttpParams();
    params = params.set("filters", JSON.stringify(options.body));
    options.params = params;
    options.headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the Content-Type header
      'Accept': '*/*'
    });
    //console.log("Sent: ", url, options);
    return this.http.request<ApiResponse<T>>('GET', url, options).pipe(
        map((response: any) => {
          if (response.status === 401) {
            return new ApiResponse();
          }
          return response;
        })
    );
  }

  getProperties(filteredSearch: boolean = false): Observable<any> {
    const
        searchModel = this.getSearchState(),
        searchParams: SearchParams = searchModel.userSearchSettings,
        totalGuestCount = searchModel.filters.occupancy.adults +
            searchModel.filters.occupancy.childTwelvePlus +
            searchModel.filters.occupancy.childUnderEleven;

    let queryString = "search?";
    queryString += `checkin=${searchParams.dates.checkin}&`;
    queryString += `checkout=${searchParams.dates.checkout}&`;
    queryString += `state=${searchParams.address.state}&`;
    queryString += `stateCode=${searchParams.address.stateCode}&`;
    queryString += `city=${searchParams.address.city}&`;
    //queryString += `postalCode=${searchParams.address.postalCode}&`;
    queryString += `country=${searchParams.address.country}&`;
    queryString += `totalGuests=${totalGuestCount}`;
    queryString = encodeURI(queryString);

    //? Should we be forming request body data in a GET route with query params?
    // https://stackoverflow.com/questions/978061/http-get-with-request-body
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let options = { headers, body: searchModel.filters };

    const requestURL = `${this.apiUrl}/${queryString}`;

    return this.getCallWithBodyData(requestURL, options);
  }

  /**
   * SearchModel service methods
   **/
  getSearchState(): SearchModel {
    const
        searchModelFromStorage = localStorage.getItem("searchQuery"),
        searchModelExists = !!searchModelFromStorage;

    this.searchState = searchModelExists ? JSON.parse(searchModelFromStorage) : new SearchModel();
    return this.searchState;
  }

  setSearchState(searchModel: SearchModel) {
    this.searchState = searchModel;
    //? Needed to make a shallow copy of search model instance, or it breaks -Benji
    localStorage.setItem("searchQuery", JSON.stringify({ ...searchModel }));
    this.searchStateSubject.next(this.searchState);
  }

  resetSearchState(): SearchModel {
    localStorage.removeItem("searchQuery");

    this.searchStateSubject.next(this.searchState);
    return this.searchState;
  }

  initializeBlindSearch(): SearchModel {
    const searchModel = new SearchModel();
    localStorage.setItem("searchQuery", JSON.stringify({ ...searchModel }));
    return searchModel;
  }

  /**
   * Storage
   */
  public SetBookingConfirmationStatus(bookingConfirmation: any) {
    localStorage.setItem("bookingConfirmation", JSON.stringify(bookingConfirmation));
  }
  public GetBookingConfirmationStatus(): any {
    const bookingConfirmationResponse = localStorage.getItem("bookingConfirmation");
    return bookingConfirmationResponse ? JSON.parse(bookingConfirmationResponse) : null;
  }

  triggerSearchButtonGuestClick() {
    this.onSearchButtonGuestClickSource.next();
  }
  triggerSeachCheckInClick() {
    this.onSearchCheckInClick.next();
  }
  triggerSearchLocationClick() {
    this.onSearchLocationClick.next();
  }

  getClientIP<T>(): Observable<ApiResponse<T>> {
    return this.http
        .get<ApiResponse<T>>('https://api.ipify.org?format=json')
        .pipe(
          map((response) => {
            if (response.status === 401) {
              return new ApiResponse();
            }
          return response;
        })
    );
  }
}
