import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-social-icons",
  templateUrl: "./social-icons.component.html",
  styleUrls: ["./social-icons.component.scss"],
})
export class SocialIconsComponent implements OnInit {
  @Input() iconSize: string = "32px";
  @Input() iconColor: string = "black";

  email: string = "info@pcsatease.com";
  phoneNumber: string = "+15124340109";
  facebook: string = "https://www.facebook.com/ATEASERentals/";
  twitter: string = "";
  instagram: string = "";
  linkedin: string = "";

  constructor() {}

  ngOnInit() {}
}
