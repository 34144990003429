import { Component, AfterViewInit, ViewChild, ElementRef, Input } from "@angular/core";
import { MapsApiService } from "src/app/services/maps-api.service";
import { GPSCoords } from "src/app/shared/models/property-model";

@Component({
  selector: "app-google-js-map",
  templateUrl: "./google-js-map.component.html",
  styleUrls: ["./google-js-map.component.scss"],
})
export class GoogleJsMapComponent implements AfterViewInit {
  @Input() gps: GPSCoords | null = null;
  @ViewChild("mapContainer") mapContainer!: ElementRef;

  constructor(private mapsApiService: MapsApiService) {}

  ngAfterViewInit(): void {
    this.createMap();
  }

  async createMap(): Promise<void> {
    try {
      if (!this.mapContainer) {
        throw new Error("Map container element not set");
      }

      if (!this.gps) {
        throw new Error("Valid lat/lng GPS coords are required");
      }

      // console.log(this.gps);

      await this.mapsApiService.getInteractiveMap(this.mapContainer.nativeElement, this.gps);
    } catch (error) {
      console.error("Error creating map:", error);
    }
  }
}
