import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { DialogRef, DIALOG_DATA } from "@angular/cdk/dialog";

export interface DialogData {
  title: string;
  message: string;
}

export class CustomDialogModel {
  constructor(public title: string, public message: string) { }
}

@Component({
  selector: "app-custom-dialog",
  templateUrl: "./custom-dialog.component.html",
  styleUrls: ["./custom-dialog.component.scss"],
})
export class CustomDialogComponent implements OnInit {
  @ViewChild("modal") modalEle: ElementRef<HTMLInputElement>;
  title: string = "";
  message: string = "";
  yesButtonText: string = "";
  noButtonText: string = "";

  constructor(private dialogRef: DialogRef<CustomDialogComponent>, @Inject(DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
    }
  }

  ngAfterViewInit(): void {
    if (this.modalEle) {
      this.modalEle.nativeElement.focus();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
