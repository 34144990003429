import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import {SwiperContainer} from "swiper/swiper-element";
import {SwiperOptions} from "swiper/types";

@Directive({
    selector: '[appSwiper]'
})

export class SwiperDirective implements AfterViewInit { // TODO: Expand On Options Here: https://swiperjs.com/element
    @Input() config?: SwiperOptions;

    constructor(private el: ElementRef<SwiperContainer>) {}

    ngAfterViewInit(): void {
        Object.assign(this.el.nativeElement, this.config);
        this.el.nativeElement.initialize();
    }
}
