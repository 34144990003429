<main>
  <header>
    <app-header-menu></app-header-menu>
  </header>

  <!-- landing, about etc.. should render here -->
  <router-outlet></router-outlet>

  <footer>
    <app-footer [whiteTheme]="false"></app-footer>
  </footer>

  <div *ngIf="showBackToTop" class="back-to-top-arrow" (click)="scrollToTop()">
    <!-- UP ARROW -->
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="48" height="48" viewBox="0 -960 960 960">
      <path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z" />
    </svg>
  </div>
</main>
