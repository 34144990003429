<div #modal class="modal-container" disableAutoFocus="true" aria-hidden="true">
  <div class="modal-header">
    <app-logo-no-swoosh disableAutoFocus="true" class="logo header-item" [customWidth]="80" [customColor]="'darkGrey'"></app-logo-no-swoosh>

    <a (click)="close()" class="btn-close header-item" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 -960 960 960">
        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
      </svg>
    </a>
  </div>

  <div class="modal-dialog">
    <div class="center">
      <h2>{{ title }}</h2>
    </div>

    <!-- Conditionally render modal-body -->
    <div class="center modal-body" *ngIf="message">
      <span>{{ message }}</span>
    </div>

    <div class="button-container">
      <button (click)="close()" class="btn">Close</button>
    </div>
  </div>
</div>
