import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { PagesComponent } from "./pages/pages.component";
import { PropertySearchFiltersComponent } from "./shared/property-search-filters/property-search-filters.component";
import { SearchResultsComponent } from "./pages/search-results/search-results.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
// import { LandingModule } from "./pages/landing/landing.module";
import { ROUTES } from "src/app/constants/routes.constants";
import {ForgetPasswordComponent} from "./pages/forget-password/forget-password/forget-password.component";
import {ChangePasswordComponent} from "./pages/change-password/change-password.component";
import {RegisterComponent} from "./pages/register/register.component";
import {AccountComponent} from "./pages/account/account.component";
import {PropertyComponent} from "./pages/property/property.component";
// import { AuthUnprotectedGuard } from "./shared/auth/UnProtectedAuth.guard";

export const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      // Unauthenticated Routes
      {
        path: "",
        loadChildren: () => import("./pages/landing/landing.module")
            .then((m) => m.LandingModule)
      },
      {
        path: ROUTES.REGISTER,
        component: RegisterComponent
      },
      {
        path: ROUTES.PROPERTY_RESULTS,
        component: SearchResultsComponent
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        component: ForgetPasswordComponent
      },
      {
        path: ROUTES.UPDATE_PASSWORD,
        component: ChangePasswordComponent
      },
      {
        path: ROUTES.PROPERTY_BY_ID,
        component: PropertyComponent
      },
      // Authenticated Routes
      {
        path: ROUTES.ACCOUNT,
        loadChildren: () => import("./pages/account/account.module")
            .then((m) => m.AccountModule)
      },
      {
        path: ROUTES.PREP_BOOKING,
        loadChildren: () => import("./pages/property-booking/property-booking.module")
            .then((m) => m.PropertyBookingModule)
      },
      // Static Pages
      {
        path: ROUTES.OUR_STORY,
        loadChildren: () => import("./pages/our-story/our-story.module")
            .then((m) => m.OurStoryModule),
      },
      {
        path: ROUTES.CONTACT,
        loadChildren: () => import("./pages/contact/contact.module")
            .then((m) => m.ContactModule)
      },
      {
        path: ROUTES.INVESTOR_RELATIONS,
        loadChildren: () => import("./pages/investor-relations/investor-relations.component.module")
            .then((m) => m.InvestorRelationsComponentModule)
      },
      {
        path: ROUTES.PRESS,
        loadChildren: () => import("./pages/press/press.component.module")
            .then((m) => m.PressComponentModule)
      },
      {
        path: ROUTES.PRIVACY,
        loadChildren: () => import("./pages/privacy-policy/privacy-policy.module")
            .then((m) => m.PrivacyPolicyModule)
      },
      {
        path: ROUTES.TERMS_AND_CONDITIONS,
        loadChildren: () => import("./pages/terms-and-conditions/terms-and-conditions.component.module")
            .then((m) => m.TermsAndConditionsComponentModule)
      },
      /*{path: ROUTES.HOW_IT_WORKS, loadChildren: () => import().then(m => m.HowItWorksComponentModule)}*/
      { path: "**", redirectTo: "" }, // Redirect any other routes to the root path
    ],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      initialNavigation: "enabledBlocking", // don't allow navigation until a page fully loads
      // useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
