import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {ApiResponse} from "../shared/models/response-model";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {CustomDialogService} from "./custom-dialog.service";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private apiService: ApiService,
        public snackBar: MatSnackBar,
        private router: Router,
        private http: HttpClient,
        private dialog: CustomDialogService,
        private storage: StorageService,
    ) {}

    private loginSuccessSubject = new Subject<void>();

    loginSuccess$ = this.loginSuccessSubject.asObservable();

    loginSuccess() {
        this.loginSuccessSubject.next();
    }

    verifyAccount(params: any) {
        return this.http
            .post<ApiResponse<any>>(
                environment.apiUrl + `/auth/verifyAccount`,
                params
            )
            .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
    }

    private handleHttpError(error: HttpErrorResponse): Observable<ApiResponse<any>> {
        const errMessage = error?.statusText || error?.error?.message || error?.error?.error || "Unknown Error";
        this.dialog.snackBarError(errMessage);

        // Return an observable with an ApiResponse indicating failure
        return of({
            status: error.status,
            data: null,
            isVerified: false,
            message: error.error,
            error: true,
            email: null,
            enabled: null,
            statusText: null,
            authenticateIDMETokenResults: null,
            statusCode: null,
        });
    }

    // changePassword
    changePassword(resetToken: string, newPassword: string) {
        return this.apiService
            .postCallNoAuth<any>(`/auth/changePassword?resetToken=${resetToken}`, { password: newPassword }, false)
            .pipe(
                catchError((error: HttpErrorResponse): any => {
                    const snackbarMessage = error?.error?.data || "Unknown error";
                    let dialogRef = this.snackBar.open(snackbarMessage, "×", {
                        panelClass: "error",
                        horizontalPosition: "right",
                        verticalPosition: "top",
                        duration: 6000,
                    });
                    return dialogRef
                        .afterDismissed()
                        .subscribe(() => this.router.navigate(["/forgot"]));
                }),
                map((response: any) => {
                    console.log("Response for resetPassword: ", response)
                    return response;
                })
            )
    }

    // verifyResetToken
    verifyResetToken(resetToken: string) {
        return this.apiService
            .getCall<any>(`/auth/verifyResetTokenData?resetToken=${resetToken}`)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.error(error);

                    const snackbarMessage = error?.error?.statusText || "Unknown error";
                    this.snackBar.open(snackbarMessage, "×", {
                        panelClass: "error",
                        horizontalPosition: "right",
                        verticalPosition: "top",
                        duration: 3000,
                    });
                    this.storage.clearAll();
                    window.location.href = "/";
                    return [];
                }),
                map(response => {
                    console.log("Response for resetPassword: ", response)
                    return response;
                })
            )
    }
}
