import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-custom-radio",
  templateUrl: "./custom-radio.component.html",
  styleUrls: ["./custom-radio.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomRadioComponent,
      multi: true,
    },
  ],
})
export class CustomRadioComponent implements ControlValueAccessor {
  @Input() name: string = "";
  @Input() label: string = "";
  @Input() value: any;
  @Input() fontSize: number = 16;

  modelValue: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.modelValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valueChanged(newValue: any): void {
    this.modelValue = newValue;
    this.onChange(newValue);
    this.onTouched();
  }
}
