<div class="p-3">
  <div class="theme-container">
    <div class="my-3" style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <mat-card [style.max-width.px]="500">
            <div class="text-center" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 1rem;">
                <app-logo disabled="true"></app-logo>
            </div>
            <div style="margin-top: 1rem; text-align: center;">
                <h1 class="primary-app-color text-muted mt-1">Sign In</h1>
                <h3 class="text-muted ws-nowrap fw-500 p-2">Fill out the information below</h3>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit(loginForm.value)">
              <!-- Login Fields -->
              <mat-form-field appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;">
                  <mat-icon matPrefix class="mr-1 text-muted">account_circle</mat-icon>
                  <mat-label>ID.Me Email</mat-label>
                  <input matInput cdkFocusInitial placeholder="ID.Me Email" formControlName="username" required>
                  <mat-error *ngIf="loginForm.controls['username'].errors?.['required']">User Name is required</mat-error>
                  <mat-error *ngIf="loginForm.controls['username'].hasError('minlength')">username  isn't long enough, minimum of 6 characters</mat-error>
                  <mat-error *ngIf="loginForm.controls['username'].hasError('username')">Invalid username address</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 90%; padding: 0 1rem 0rem 1rem;">
                  <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
                  <mat-label>Atease Password</mat-label>
                  <input matInput placeholder="Atease Password" formControlName="password" required [type]="hide ? 'password' : 'text'">
                  <mat-error *ngIf="loginForm.controls['password'].errors?.['required']">Password is required</mat-error>
                  <mat-error *ngIf="loginForm.controls['password'].hasError('minlength')">Password isn't long enough, minimum of 6 characters</mat-error>
                  <button mat-icon-button matSuffix (click)="hide = !hide" type="button" class="text-muted">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
              </mat-form-field>
              <!-- Helper Navigation Actions -->
              <mat-card-actions style="justify-content: end; align-items: center;">
                <button type="button" mat-button  mat-dialog-close (click)="navigateToForgotPassword()">
                  <span class="mx-1  Primary-color">Forgot your Password</span>
                </button>
              </mat-card-actions>
              <div class="text-center mt-2" style="margin: 1rem 0;">
                  <button color="accent" class="uppercase mat-button-custom" type="submit">
                     Login
                  </button>
              </div>
              <div class="mt-3" style="display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
                  <h3 class="text-muted ws-nowrap fw-500 p-2" style="padding-bottom: 0; margin-bottom: 0;">Don't have an account?</h3>
                  <mat-card-actions>
                    <button type="button" mat-button (click)="openSignUp()">
                      <span class="mx-1 Primary-color">Sign Up</span>
                    </button>
                  </mat-card-actions>
              </div>
          </form>
        </mat-card>
    </div>
  </div>
</div>
