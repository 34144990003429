import { DatePipe } from "@angular/common";

const DATE_SEARCH_FORMAT = "yyyy-MM-dd";

export const DEFAULT_BLIND_SEARCH_LOCATION: Location = { // TODO: SET FROM INFISICAL
  city: "Round Rock",
  state: "Texas",
  stateCode: "TX",
  postalCode: "78681",
  country: "USA",
};

export const DEFAULT_BLIND_SEARCH_FILTERS: SearchFilters = { // TODO: SET FROM INFISICAL
  occupancy: {
    adults: 1,
    childTwelvePlus: 0,
    childUnderEleven: 0
  },
  typeOfTravel: {
    government: false,
    vacation: true,
  },
  typeOfGovernmentTravel: {
    pcs: false,
    tdy: false,
    additionalDetail: {
      forPCS: {
        travelWithChoices: '',
        tle: false,
        useMealAllowance: false,
        useBAH: false,
        travelingWithFamily: false,
        travelingWithSpouse: false,
        travelingWithOthers: false
      },
      forTDY: {
        useLodgingAllowance: false,
        useMIEAllowance: false,
        isGroupTravel: false
      }
    }
  }
};

export interface RadioOption {
  label: string;
  value: string | number;
}

export interface FormItem {
  label?: string;
  formControlName: string;
  type: string;
  radioOptions?: RadioOption[];
  tooltip?: string;
}

export const TooltipMessages: any = {
  pcs: "Permanent Change of Station: Select if relocated to a new duty station long-term.",
  tdy: "Temporary Duty Travel: Temporary assignment away from permanent duty station, typically short-term (2 to 189" +
      " days). Used by various US military branches and federal agencies.",
  bah: "Basic Allowance for Housing (BAH) helps cover housing expenses and can offset costs. For any remaining days" +
      " not covered by TLE, BAH can assists with  the remaining days of a booking. This strategy can optimize" +
      " lodging expenses during your transition and reduce your expenses.",
  tle:"Temporary Lodging Expense (TLE) partially covers lodging and meal expenses during a PCS stay, with a maximum of 14 days allowed. By maximizing TLE for the initial stay, you can reduce out-of-pocket expenses.",
  group: "Traveling with other on TDY/TAD.",
  perDiem:"Per diem covers lodging, meals, and incidentals. GSA sets rates for continental US travel, DOD for non-foreign areas, and State Department for foreign travel. Visit travel.dod.mil and aoprals.state.gov for details.",
  mealsAndIncidentals: "The M&IE allowance covers food expenses for travelers. It's crucial to know what's reimbursable. Check the regulations for details..",
  family:"Member, spouse and children traveling",
  spouse:"Member and spouse only.",
  others:"Dependent or dependents traveling without member ",
};

export const StepperButtons: FormItem[] = [
  { label: "Adults", formControlName: "adults", type: "stepper" },
  { label: "Children 12-21", formControlName: "children", type: "stepper" },
  { label: "Infant - 11", formControlName: "infants", type: "stepper" },
 /* { label: "Dogs", formControlName: "dogs", type: "stepper" },
  { label: "Cats", formControlName: "cats", type: "stepper" },*/
];

export const TravelWithChoices: RadioOption[] = [
  { label: "Service member or dependent only", value: "Service member or dependent only" },
  { label: "Service member with two or more dependents", value: "Service member with two or more dependents" },
];

export const TDYFormData: FormItem[] = [
  {
    label: "Lodging Allowance",
    formControlName: "lodgingAllowance",
    type: "checkbox",
    tooltip: TooltipMessages.perDiem,
  },
  {
    label: "M&IE Allowance",
    formControlName: "mealAllowance",
    type: "checkbox",
    tooltip: TooltipMessages.mealsAndIncidentals,
  },
  {
    label: "Group Travel",
    formControlName: "others",
    type: "checkbox",
    tooltip: TooltipMessages.group,
  },
];

export const TravelGuests: FormItem[] = [
  {
    label: "Traveling with Family",
    formControlName: "family",
    type: "checkbox",
    tooltip: TooltipMessages.family,
  },
  {
    label: "Traveling with Spouse",
    formControlName: "spouse",
    type: "checkbox",
    tooltip: TooltipMessages.spouse,
  },
  {
    label: "Traveling with Others",
    formControlName: "others",
    type: "checkbox",
    tooltip: TooltipMessages.others,
  },
];

export const Allowances: FormItem[] = [
  {
    label: "TLE",
    formControlName: "lodgingAllowance",
    type: "checkbox",
    tooltip: TooltipMessages.tle,
  },
  {
    label: "Use Meal Allowance",
    formControlName: "mealAllowance",
    type: "checkbox",
    tooltip: TooltipMessages.mealsAndIncidentals,
  },
  {
    label: "Use BAH",
    formControlName: "usingBAH",
    type: "checkbox",
    tooltip: TooltipMessages.bah,
  },
];

/**
 * SearchModel class interfaces
 */
export interface Location {
  city: string;
  state: string;
  stateCode: string;
  postalCode: string;
  country: string;
}

export interface SearchFilters {
  occupancy: {
    adults: number;
    childTwelvePlus: number;
    childUnderEleven: number;
  },
  typeOfTravel: {
    government: boolean;
    vacation: boolean;
  },
  typeOfGovernmentTravel: {
    pcs: boolean;
    tdy: boolean;
    additionalDetail: {
      forPCS: {
        travelWithChoices: string;
        tle: boolean;
        useMealAllowance: boolean;
        useBAH: boolean;
        travelingWithFamily: boolean;
        travelingWithSpouse: boolean;
        travelingWithOthers: boolean;
      },
      forTDY: {
        useLodgingAllowance: boolean;
        useMIEAllowance: boolean;
        isGroupTravel: boolean;
      }
    }
  }
}

export interface SearchMetaData {
  clientIP: string | null,
  userBrowserSettings: {
    browserType: string | null;
    browserVersion: string | null;
    browserLocale: string;
    browserTimezone: string;
  };
  currentDate: Date | null;
  originalRoute: string;
  cookieVersion: number;
}

export interface BlindSearchSettings {
  dates: {
    checkin: string;
    checkout: string;
  },
  location: Location | null;
  filters: SearchFilters | null;
}

export interface SearchParams {
  dates: {
    checkin: string;
    checkout: string;
  },
  address: {
    city: string;
    state: string;
    stateCode: string;
    postalCode: string;
    country: string;
  },
  filters: string;
}

/**
 * SearchModel Class Default Data
 */
export class SearchModel {
  // Step 0: Define Object Structure
  defaultMeta: SearchMetaData = {
    clientIP: null,
    userBrowserSettings: {
      browserType: "",
      browserVersion: "",
      browserLocale: "",
      browserTimezone: "",
    },
    currentDate: null,
    originalRoute: "",
    cookieVersion: 0
  };
  blindSearchSettings: BlindSearchSettings = {
    dates: {
      checkin: null,
      checkout: null
    },
    location: null,
    filters: null
  };
  userSearchSettings: SearchParams = {
    dates: {
      checkin: "",
      checkout: ""
    },
    address: {
      city: "",
      state: "",
      stateCode: "",
      postalCode: "",
      country: ""
    },
    filters: "{}"
  };
  filters: SearchFilters = DEFAULT_BLIND_SEARCH_FILTERS;

  constructor() {
    this.defaultMeta = this.setDefaultMeta();
    this.blindSearchSettings = this.getBlindSearchValues(this.defaultMeta);
    this.userSearchSettings = this.initUserSearchSettings();
  }

  /**
   * Default Meta Helper Functions
   * */
  private setDefaultMeta() {
    const
        userBrowserLocale = this.getBrowserLocale(),
        userTimezone = this.setDefaultTimezoneByUsersBrowser(),
        browserData = this.scrapeBrowserInformation(),
        currentDate = new Date();

    return {
      clientIP: null,
      userBrowserSettings: {
        browserType: browserData.browserType,
        browserVersion: browserData.browserVersion,
        browserTimezone: userTimezone,
        browserLocale: userBrowserLocale,
      },
      currentDate,
      originalRoute: "/",
      cookieVersion: 0
    };
  };
  private scrapeBrowserInformation() {  // Determine the browser type and version
    const userAgent = navigator.userAgent;
    let browserType = 'unknown';
    let browserVersion = 'unknown';

    if (/Firefox/.test(userAgent)) {
      browserType = 'Firefox';
      let uMatch = userAgent.match(/Firefox\/(.*)$/);
      if (uMatch && uMatch.length > 1) {
        browserVersion = uMatch[1];
      }
    } else if (/Chrome/.test(userAgent)) {
      browserType = 'Chrome';
      let uMatch = userAgent.match(/Chrome\/(.*)$/);
      if (uMatch && uMatch.length > 1) {
        browserVersion = uMatch[1];
      }
    } else if (/Safari/.test(userAgent)) {
      browserType = 'Safari';
      let uMatch = userAgent.match(/Version\/(.*)/);
      if (uMatch && uMatch.length > 1) {
        browserVersion = uMatch[1];
      }
    } else if (/Opera Mini/.test(userAgent)) {
      browserType = 'Opera Mini';
      let uMatch = userAgent.match(/Opera Mini\/(.*)$/);
      if (uMatch && uMatch.length > 1) {
        browserVersion = uMatch[1];
      }
    } else if (/Opera/.test(userAgent)) {
      browserType = 'Opera';
      let uMatch = userAgent.match(/Opera\/(.*)$/);
      if (uMatch && uMatch.length > 1) {
        browserVersion = uMatch[1];
      }
    } else if (/MSIE/.test(userAgent) || /Trident/.test(userAgent)) {
      browserType = 'Internet Explorer';
      let uMatch = userAgent.match(/MSIE (\d+\.\d+)/);
      if (uMatch && uMatch.length > 1) {
        browserVersion = uMatch[1];
      }
    }

    return { browserType, browserVersion };
  }
  private initUserSearchSettings(): SearchParams {
    return {
      dates: {
        checkin: "",
        checkout: ""
      },
      address: {
        city: "",
        state: "",
        stateCode: "",
        postalCode: "",
        country: ""
      },
      filters: "{}"
    }
  }
  private getBrowserLocale(): string {
    // Check if navigator.languages is available and has at least one entry
    if (navigator.languages && navigator.languages.length > 0) {
      return navigator.languages[0];
    } else {
      // Fallback to navigator.language if navigator.languages is not available
      return navigator.language;
    }
  }
  private setDefaultTimezoneByUsersBrowser(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Blind Search Functions
   * */
  private getBlindSearchValues(defaultMeta: SearchMetaData) {
    const
        currentTS = defaultMeta.currentDate,
        defaultCheckin = new Date(currentTS),
        defaultCheckout = new Date(currentTS),
        datePipeWithDefaultTz = new DatePipe(defaultMeta.userBrowserSettings.browserLocale, defaultMeta.userBrowserSettings.browserTimezone);

    // Set a Range of Default Data for Blind Search Requests: Round Rock, Tx | 1 Adult | CurrentMonth+1 - 7 days out
    defaultCheckin.setMonth(defaultCheckin.getMonth() + 1); // TODO: Set From Infisical
    defaultCheckout.setMonth(defaultCheckout.getMonth() + 1); // TODO: Set From Infisical
    defaultCheckout.setDate(defaultCheckout.getDate() + 3); // TODO: Set From Infisical

    return {
      dates: {
        checkin: datePipeWithDefaultTz.transform(defaultCheckin, DATE_SEARCH_FORMAT, "UTC"),
        checkout: datePipeWithDefaultTz.transform(defaultCheckout, DATE_SEARCH_FORMAT, "UTC"),
      },
      location: DEFAULT_BLIND_SEARCH_LOCATION,
      filters: DEFAULT_BLIND_SEARCH_FILTERS
    }
  };
}
