import { APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
import { DatePipe, CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
/*import { StripeModule } from "stripe-angular";*/
import { GoogleMap, MapCircle } from "@angular/google-maps";

import { ApiService } from "./services/api.service";
import { MapsApiService } from "./services/maps-api.service";
import { ApiKeysService } from "./services/api-keys.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { SharedModule } from "./shared/shared.module";

// Helper components
import { ContactsComponent } from "./theme/components/contacts/contacts.component";
import { HeaderMenuComponent } from "./theme/components/header-menu/header-menu.component";
import { LangComponent } from "./theme/components/lang/lang.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { SocialIconsComponent } from "./theme/components/social-icons/social-icons.component";
import { FooterComponent } from "./theme/components/footer/footer.component";
import { imageLoaderProvider } from './image-loader.provider';
import {FlexLayoutModule} from "@angular/flex-layout";

import {SwiperDirective} from 'src/app/theme/directives/swiper.directive';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function initializeApp(apiKeysService: ApiKeysService, mapsApiService: MapsApiService) {
  return async (): Promise<void> => {
    return apiKeysService.fetchApiKeys().then((data) => {
      if (data.googleMapsApi) {
        mapsApiService.setGoogleApiKey(data.googleMapsApi);
        mapsApiService.loadGoogleMapsScript(data.googleMapsApi);
      }
    });
  };
}

@NgModule({
  declarations: [
    // Components
    AppComponent,
    PagesComponent,
    HeaderMenuComponent,
    ContactsComponent,
    FooterComponent,
    LangComponent,
    NotFoundComponent,
    SocialIconsComponent,
    // Directives
    SwiperDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    /*StripeModule,*/
    FlexLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AppRoutingModule,
  ],
    exports: [
        SwiperDirective,
        // We probably should not include SharedModule in the exports array, as SharedModule is not intended to be re-exported from AppModule -Benji
        // SharedModule
    ],
  providers: [
    DatePipe,
    ApiService,
    CookieService,
    MapsApiService,
    GoogleMap,
    ApiKeysService,
    imageLoaderProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ApiKeysService, MapsApiService],
      multi: true,
    },
    // { provide: OverlayContainer, useClass: CustomOverlayContainer },
    // { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
