<div style="padding: 15px;">
    <div class="theme-container">
      <div>
          <mat-card style="width: 100%; margin: 0 auto; max-width: 500px;">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">
              <h3 class="uppercase">Personal Info</h3>
           </div>
            <form [formGroup]="registerForm" (ngSubmit)="onRegisterFormSubmit(registerForm.value)">
                <!-- Personal Information Fields -->
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;">
                    <mat-icon matPrefix class="mr-1 text-muted">person</mat-icon>
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="firstName" required
                           [readonly]="environment.production"
                    >
                    <mat-error
                            *ngIf="registerForm.controls['firstName'].errors?.['required']">
                        First Name is required
                    </mat-error>
                    <mat-error
                            *ngIf="registerForm.controls['firstName'].hasError('minlength')">
                        First Name isn't long enough, minimum of 6 characters
                    </mat-error>
                </mat-form-field>
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;">
                    <mat-icon matPrefix class="mr-1 text-muted">person</mat-icon>
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" formControlName="lastName" required
                           [readonly]="environment.production"
                    >
                    <mat-error *ngIf="registerForm.controls['lastName'].errors?.['required']">Last  Name is required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;">
                    <mat-icon matPrefix class="mr-1 text-muted">email</mat-icon>
                    <mat-label>Email</mat-label>
                    <input style="padding: 0 1rem;"
                            matInput placeholder="Email" formControlName="email" required
                           [readonly]="environment.production"
                    >
                    <mat-error *ngIf="registerForm.controls['email'].errors?.['required']">Email is required</mat-error>
                    <mat-error *ngIf="registerForm.controls['email'].hasError('invalidEmail')">Invalid email address</mat-error>
                </mat-form-field>
                <!-- Group: Military Conditional Fields -->
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;"
                                 *ngIf="registerForm.controls['group'].getRawValue() === 'military' || registerForm.controls['group'].getRawValue() === 'government'"
                >
                    <mat-label>
                        Agency or Department
                    </mat-label>
                    <mat-icon matPrefix class="mr-1 text-muted">group</mat-icon>
                    <mat-select placeholder="Select Agency or department" formControlName="department"
                                [disabled]="registerForm.controls['group'].getRawValue() === 'military'"
                    >
                        <mat-option *ngFor="let department of agencyAndDepartmentList"
                                    [value]="department.id"
                        >
                            {{department.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.controls['department'].errors?.['required']">Department or Agency is required</mat-error>
                </mat-form-field>
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;"
                                 *ngIf="registerForm.controls['group'].getRawValue() === 'military'">
                    <mat-label *ngIf="registerForm.controls['group'].getRawValue() === 'military'">Branch</mat-label>
                    <mat-icon matPrefix class="mr-1 text-muted">group</mat-icon>
                    <mat-select placeholder="Select Military Branch" formControlName="branch">
                        <mat-option *ngFor="let branch of branchList" [value]="branch" (onSelectionChange)="onBranchListChange($event)">
                            {{branch.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.controls['branch'].errors?.['required']">Branch is required</mat-error>
                </mat-form-field>
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;"
                                 *ngIf="registerForm.controls['group'].getRawValue() === 'military'"
                >
                    <mat-label>Pay Grade</mat-label>
                    <mat-icon matPrefix class="mr-1 text-muted">group</mat-icon>
                    <mat-select placeholder="Select Pay Grade" formControlName="paygrade">
                        <mat-option *ngFor="let paygrade of paygradeList" [value]="paygrade.id">
                            {{paygrade.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.controls['paygrade'].errors?.['required']">Pay Grade is required</mat-error>
                </mat-form-field>
                <!-- Password Fields -->
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;">
                  <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
                  <mat-label>Password</mat-label>
                  <input matInput placeholder="Password" formControlName="password" type="password" minlength="6" required [type]="hide ? 'password' : 'text'">
                  <mat-error *ngIf="registerForm.controls['password'].errors?.['required']">Password is required</mat-error>
                  <mat-error *ngIf="registerForm.controls['password'].hasError('minlength')">Password isn't long enough, minimum of 6 characters</mat-error>
                  <button mat-icon-button matSuffix (click)="hide = !hide" type="button" class="text-muted">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field  appearance="outline" style="width: 90%; padding: 0 1rem 1rem 1rem;">
                  <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
                  <mat-label>Confirm Password</mat-label>
                  <input matInput placeholder="Confirm Password" formControlName="confirmPassword" type="password" required [type]="hide ? 'password' : 'text'">
                  <mat-error *ngIf="registerForm.controls['confirmPassword'].errors?.['required']">Confirm Password is required</mat-error>
                  <mat-error *ngIf="registerForm.controls['confirmPassword'].hasError('mismatchedPasswords')">Passwords do not match</mat-error>
                  <button mat-icon-button matSuffix (click)="hide = !hide" type="button" class="text-muted">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <div class="text-center" style="margin: 0 0 1rem 0;">
                    <button mat-raised-button  class="uppercase" type="submit">
                       Finish Registration
                    </button>
                </div>
            </form>
          </mat-card>
      </div>
    </div>
  </div>
