import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Meta, Title } from "@angular/platform-browser";
import { MapsApiService } from "src/app/services/maps-api.service";
import { ApiService } from "src/app/services/api.service";
import { CustomDialogService } from "src/app/services/custom-dialog.service";

import { ROUTES } from "src/app/constants/routes.constants";
import { GPSCoords, PropertyAmenityQuickList, PropertyItemData } from "src/app/shared/models/property-model";
import { SearchHelpers } from "src/app/services/search.helpers";
import {HeaderService} from "../../services/header.service";
import {SearchService} from "../../services/search.service";
import {SearchModel} from "../../shared/models/search-model";

@Component({
  selector: "app-search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
})
export class SearchResultsComponent implements AfterViewInit {
  showMobileSearch: boolean = true;
  propertiesNotFound: boolean = false;
  loadingProperties: boolean = true;
  headerMessage: string = "Looking for properties";
  mapURL: string = "assets/images/atease-logo-map-icon-400px.jpg";
  gps: GPSCoords | null = null;

  // Search bar
  allowSearchFormToDisplay: boolean = false;
  searchBarWideMode: boolean = false;
  showSearchBoxShadow: boolean = false;

  properties: any[] = [];
  city: string | null = null;
  stateCode: string | null = null;
  selectedView: string = "map-view";

  headerButtonsLeft: { label: string; function: string; type: string }[] = [
    // TODO Post-MVP: Uncomment these sort buttons later
    // { label: "Sort By", function: "???", type: "button" },
    // { label: "Type Of Place", function: "???", type: "button" },
    // { label: "Price", function: "???", type: "button" },
    // { label: "More Filters", function: "moreFilters", type: "anchor" },
  ];

  headerLinksRight: { label: string; function: string; type: string; icon: string; iconSize: number }[] = [
    { label: "Grid View", function: "toggleView", type: "anchor", icon: "grid-view", iconSize: 20 },
    { label: "Map View", function: "toggleView", type: "anchor", icon: "map-view", iconSize: 30 },
  ];

  handleSortButtonClick(functionName: string, event) {
    //? not sure what each of these functions should do yet? How will the sorting work exactly? -Benji
    event.preventDefault();
    if (typeof this[functionName] === "function") {
      this[functionName]();
    } else {
      console.dir(`${functionName} clicked`);
    }
  }

  toggleView(icon: string, event: Event) {
    event.preventDefault();
    this.selectedView = icon;
  }

  toggleSearch(showSearch: boolean) {
    this.allowSearchFormToDisplay = showSearch;
  }

  searchModel: SearchModel;
  constructor(
    private mapsApiService: MapsApiService,
    private searchHelpers: SearchHelpers,
    private apiService: ApiService,
    private dialog: CustomDialogService,
    private http: HttpClient,
    private meta: Meta,
    private title: Title, //? maybe add later?
    private cdr: ChangeDetectorRef,
    private headerService: HeaderService,
    private searchService: SearchService
  ) {
    this.meta.addTags([
      {
        name: "description",
        content:
          "Welcome to Atease Properties, your trusted companion for military veterans and families in search of lodging during reassignments. Explore our curated selection of vacation rentals, short-term stays, and holiday homes, designed to provide comfort and convenience during times of transition. Find peace of mind with Atease as you embark on your next journey.",
      },
      { name: "author", content: "Atease" },
      {
        name: "keywords",
        content:
          "pcs, property search, properties near base, military relocation, base housing, housing, vacation rentals, short-term rentals, holiday rentals, condo rentals, apartment, monthly rentals, weekly rentals",
      },
    ]);
    this.searchService.currentPageResults$.subscribe((data: any) => {
      this.loadingProperties = true;
      this.searchModel = this.apiService.getSearchState();

      const { city, stateCode } = this.searchModel.userSearchSettings.address;
      this.headerMessage = city.length === 0 ? 'Stays From All Over!' : `Stays in ${city}, ${stateCode}`;

      if(city.length > 0 && stateCode.length > 0) {
        this.city = city;
        this.stateCode = stateCode;
      }

      this.properties = data.content && data.content.length > 0 ? this.searchHelpers.parsePropertySearchResponse(data.content) : [];

      // TODO: come up with better way to get general GPS coords
      if (data.content && data.content.length > 0) {
        const firstProperty = data.content[0];
        const lat = parseFloat(firstProperty.propertyDetail.coordinates.obfuscatedLat);
        const lng = parseFloat(firstProperty.propertyDetail.coordinates.obfuscatedLong);
        this.gps = { lat, lng };
      }
      this.loadingProperties = false;
    })
  }

  ngOnInit(): void {
    let searchModel = this.apiService.getSearchState();
    searchModel = this.searchService.setOriginatingRoute(searchModel, ROUTES.PROPERTY_RESULTS);
    this.apiService.setSearchState(searchModel);
    this.headerService.setCurrentPage("/results");
  }

  ngAfterViewInit() {
    if (!this.properties.length) {
      this.loadData();
    } else {
      this.loadingProperties = false;
    }
  }

  loadData() {
    this.apiService.getProperties(true).subscribe({
      next: (data) => {
        if (data?.content && data?.initialQuery) {
          this.properties = this.searchHelpers.parsePropertySearchResponse(data.content);
          // TODO: come up with better way to get general GPS coords
          if (data.content.length) {
            const firstProperty = data.content[0];
            const lat = parseFloat(firstProperty.propertyDetail.coordinates.obfuscatedLat);
            const lng = parseFloat(firstProperty.propertyDetail.coordinates.obfuscatedLong);
            this.gps = { lat, lng };
          }
          // this.mapURL = "/assets/images/others/google-static-maps-example.png"; //! USE THIS TO AVOID API CALLS
          // this.mapURL = this.mapsApiService.googleStaticMapsURL({ lat: 30.2711286, lng: -97.7436995 });
          // this.mapURL = this.mapsApiService.googleStaticMapsURL({ city, state }); => MSG FROM DKG: If you need these values, use the SearchModel.userSearchSettings object.
        }
      },
      error: (err) => {
        console.error(err);
        const errMessage = err?.error?.statusText || err?.error?.message || err?.error?.error || "Unknown Error";
        this.dialog.snackBarError(errMessage);
        this.loadingProperties = false;
      },
      complete: () => {
        this.loadingProperties = false;
      },
    });
  }
}
