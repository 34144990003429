<div class="item">
  <mat-icon [color]="iconColor" class="mat-icon-{{ iconSize }}">call</mat-icon>
  <span class="px-1">+92 308 9027 818</span>
</div>
<div *ngIf="dividers" class="v-divider"></div>
<div class="item">
  <mat-icon [color]="iconColor" class="mat-icon-{{ iconSize }}">location_on</mat-icon>
  <span class="px-1">2903 Avenue Z, Brooklyn, NY, USA</span>
</div>
<div *ngIf="dividers" class="v-divider"></div>
<div class="item">
  <mat-icon [color]="iconColor" class="mat-icon-{{ iconSize }}">mail</mat-icon>
  <span class="px-1">info@atease.com</span>
</div>
