import { Component, OnInit, ViewChild, Inject, PLATFORM_ID, HostListener } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { HouseSpinnerComponent } from "../theme/components/house-spinner/house-spinner.component";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit {
  @ViewChild("sidenav") sidenav: any;
  @ViewChild(HouseSpinnerComponent, { static: false }) preloader: HouseSpinnerComponent | null = null;

  isBrowser: boolean;
  showBackToTop: boolean = false;

  constructor(private apiService: ApiService, private router: Router, @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId); //? Do we neeed this? -Benji
  }

  ngOnInit() {
    //
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // Check scroll position
    if (window.scrollY > 300) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }

  ngAfterViewInit() {
    // TODO: add a regular spinner here that goes away when page loads
  }
}
