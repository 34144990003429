import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {ROUTES} from "../constants/routes.constants";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public userProfile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userProfileComplete: boolean = false;
  constructor(private router:Router) {
    const storedUserData = this.getData();
    const storedUserProfile = this.getUserProfileFromStorage();
    if (storedUserData) {
      this.userProfile.next(storedUserData);
    }
    if(storedUserProfile) {
      this.userProfileComplete = storedUserProfile.account_setup_completed;
    }
  }

  addLogin(user:any){
    localStorage.setItem("user",JSON.stringify(user));
  }
  removeLogin(){
    localStorage.clear();
    this.userProfile.next(null);
    this.router.navigate(['/account']);
  }
  getData(){ // Deprecate Later
    return JSON.parse(localStorage.getItem("user"));
  }
  getUserData(){
    return JSON.parse(localStorage.getItem("user")) || null;
  }

  setUserProfileStatus(status: boolean) {
    localStorage.setItem("userProfile",JSON.stringify({account_setup_completed: status}));
    this.userProfileComplete = status;
  }
  getUserProfileFromStorage() {
    return JSON.parse(localStorage.getItem("userProfile")) || null;
  }
  getUserProfileStatus() {
    return this.userProfileComplete;
  }

  /**
   * DKG State Helpers
   * */
  setStateBasedMainMenuRoutes(menuItems: any[], authenticated: boolean) {
    if(authenticated) {
      menuItems.push({ label: "Profile", route: ROUTES.PROFILE });
      menuItems.push({ label: "Logout", route: ROUTES.LOGOUT });
    } else {
      menuItems.push({ label: "Sign up", route: ROUTES.SIGN_UP });
      menuItems.push({ label: "Sign in", route: ROUTES.SIGN_IN });
    }
    // Global Navi
    return menuItems;
  }

  setStateBasedSubMenuRoutes(submenuItems: any[], authenticated: boolean) {
    if(authenticated) {

    } else {

    }
    // Global Navi
    submenuItems.push({ label: "Our Story", route: ROUTES.OUR_STORY });
    // { label: "FAQ", route: ROUTES.FAQ }
    return submenuItems;
  }

  public clearAll(): void {
    //TODO: Finish This
    /*localStorage.removeItem("registrant");
    localStorage.removeItem("accountProfile");
    localStorage.removeItem("propertyOnboardingId");*/
  }
}
